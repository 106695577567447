import { useCallback, useEffect, useState } from 'react'
import bus from '../utils/EventBus.js';
import Event from '../utils/EventNames.js';
import { useIsMounted } from '../utils/ReactUtils'
import MaterialReactTable from 'material-react-table';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';

import DivideIcon from '../icons/Divide'
import OpenParenIcon from '../icons/OpenParen'
import CloseParenIcon from '../icons/CloseParen'
import PiIcon from '../icons/Pi'
import ExponentIcon from '../icons/Exponent'

import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, checkStrictRequiredError, checkNumberError, checkYearError, checkPhoneError, checkDateError, checkEmailError, checkColumnError, validateColumns, AddRecordModal, stringDate, addRow, deleteRow, handleEditClick, handleSaveRowEdits, handleCancelEdit, setRowPhone, setRowSelect, setRowAutocomplete, handleTextChange, handleSelectChange, handleCheckboxChange } from '../form/Form'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import Category from '../form/Panel.js'
import { useNavigate } from 'react-router-dom';

export default function CreateConversion(props = {}) {
    let params = useParams()
    let navigate = useNavigate();
    const id = params.ExpressionId

  
    const [unitsType, setUnitsType] = useState("metric")
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    
    const [validationErrors, setValidationErrors] = useState({});
   
    
    const [expressionId, setExpressionId] = useState(id ? id : "0")
    const [expression, setExpression] = useState({
      //TODO set empty Expression
    })
    const [testValues, setTestValues] = useState({
      input1: 1,
      input2: 0,
      input3: 0,
      output: 'Click "Calculate"'
    })
    const [fieldErrors, setFieldErrors] = useState({})    

    const getExpression = async() => {
      // TODO fetch data

      const sample = {
        name: '',
        output: '',
        input1: '',
        useInput2: false,
        input2: '',
        useInput3: false,
        input3: '',
        display: '',
        compute: ''
      }
      setExpression(sample)
      setLoading(false)
      props.stopLoading()
    }

    const appendToExpression = (endText, startText='') => {
      //console.log(text)
      let newExp = startText + expression.display + endText
      //console.log(newExp)
      expression.display = newExp;
      setExpression({...expression}, {"display": newExp})
    }

    const displayToCompute = (expression, setExpression) => {
      //convert displayed expression to computable expression
      console.log("display: " + expression.display)
      let compute = expression.display
      //replace expression.input1 with "input1" etc.
      compute = compute.replaceAll("[" + expression.input1 + "]", "input1")
      compute = compute.replaceAll("[" + expression.input2 + "]", "input2")
      compute = compute.replaceAll("[" + expression.input3 + "]", "input3")
      //replace ÷ with /]
      compute = compute.replaceAll("÷", "/")
      //replace × with *
      compute = compute.replaceAll("×", "*")
      //replace ^ with **
      compute = compute.replaceAll("^", "**")
      //replace π with pi
      compute = compute.replaceAll("π", "pi")
      //output
      console.log("compute: " + compute)
      expression.compute = compute
      setExpression({...expression}, {"compute": compute})
    }

    const calculate = (expression, setExpression, testValues, setTestValues) => {
      // calculate test values
      if (!expression.display){
        console.log("No expression defined")
        bus.emit(Event.ALERT, { "text": "No expression defined", "style": "warn" })
        return "No expression defined"
      }
      displayToCompute(expression, setExpression)
      try {
        let input1 = testValues.input1
        let input2 = testValues.input2
        let input3 = testValues.input3
        let pi = 3.14159265 //TODO how many digits?
        let output = eval(expression.compute)
        output = output.toFixed(4) //TODO improve for small and large values
        console.log("output: " + output)
        testValues.output = output
        setTestValues({...testValues}, {"output": output})
        return output
      }
      catch(e){
        console.log(e)
        console.log("Invalid expression or inputs")
        bus.emit(Event.ALERT, { "text": "Invalid expression or inputs", "style": "warn" })
        return ("Invalid expression or inputs")
      }
    }

    const saveForm = async() => {
      console.log("save form")
      props.startLoading()
      let errors=[]
      //TODO check errors
      //TODO save data
    }

    const setError = (value, id, errorChecker=checkRequiredError, errorObject=fieldErrors, errorSetter=setFieldErrors) => {
      let error = errorChecker(value)
      errorObject[id]=error
      errorSetter({...errorObject}, {id: error})
    }

    const setDirty = () => {
      props.setDirty()
    }

    const setClean = () => {
      props.setClean()
    }

    useEffect(() => {
      getExpression()
    }, [])

    if(loading){
      return (
        <div className="App"></div>
      )
    }

    if(props.saveClicked && !saving){
      console.log("saveClicked")
      setSaving(true)
      saveForm()
      setTimeout(() => {
        setSaving(false)
      }, 2000)
    }

    return (
      <div>
        <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
          <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
          <Grid xs={12} md={12}>
            <Paper sx={{ mt: 0, minHeight: 100, backgroundColor: '#ffffff', py: 2}}>
              <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={12}>
                  <Typography className='tis-form-title'>Expression Name</Typography>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField className='tis-form-field'
                    id="name"
                    defaultValue={expression.name}
                    onBlur={(event) => {
                      handleTextChange(event, expression, setExpression, setDirty)
                    }}
                    label="Name"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} md={6}></Grid>
              </Grid>
              </Paper>
            </Grid>
            
            <Grid xs={12} md={12}><hr/></Grid>
            <Grid xs={12} md={12}>
            <Paper sx={{ mt: 0, minHeight: 200, backgroundColor: '#ffffff', py: 2}}>
              <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={12}>
                  <Typography className='tis-form-title'>Value Definitions</Typography>
                </Grid>
                {/* TODO validate alpha characters */}
                <Grid xs={12} md={6}>
                  <TextField className='tis-form-field'
                    id="output"
                    defaultValue={expression.output}
                    onBlur={(event) => handleTextChange(event, expression, setExpression, setDirty)}
                    label="Output"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} md={6}></Grid>
                <Grid xs={12} md={6}>
                  <TextField className='tis-form-field'
                    id="input1"
                    defaultValue={expression.input1}
                    onBlur={(event) => handleTextChange(event, expression, setExpression, setDirty)}
                    label="Input 1"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <FormGroup className='tis-form-field' id="useInput2">
                    <FormControlLabel control={
                      <Checkbox 
                        disabled={expression.useInput3}
                        checked={expression.useInput2} 
                        onChange={() => handleCheckboxChange("useInput2", expression, setExpression, setDirty)}
                        />} 
                        label="Use 2 Inputs" />
                  </FormGroup>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField className='tis-form-field'
                    hidden={!expression.useInput2}
                    id="input2"
                    defaultValue={expression.input2}
                    onBlur={(event) => handleTextChange(event, expression, setExpression, setDirty)}
                    label="Input 2"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} md={6}>
                <FormGroup className='tis-form-field' id="useInput3" hidden={!expression.useInput2}>
                    <FormControlLabel control={
                      <Checkbox 
                        checked={expression.useInput3} 
                        onChange={() => handleCheckboxChange("useInput3", expression, setExpression, setDirty)}
                        />} 
                        label="Use 3 Inputs" />
                  </FormGroup>
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField className='tis-form-field'
                    hidden={!expression.useInput3}
                    id="input3"
                    defaultValue={expression.input3}
                    onBlur={(event) => handleTextChange(event, expression, setExpression, setDirty)}
                    label="Input 3"
                    fullWidth
                  />
                </Grid>
              </Grid>
              </Paper>
            </Grid>

            <Grid xs={12} md={12}><hr/></Grid>
            <Grid xs={12} md={12}>
              <Paper sx={{ mt: 0, minHeight: 200, backgroundColor: '#ffffff', py: 2}}>
                <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                  <Grid xs={12} md={12}>
                    <Typography className='tis-form-title'>Expression Builder</Typography>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Button aria-label="add" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression("+")}><AddIcon/></Button>
                    <Button aria-label="subtract" variant="outlined" color="primary" sx={{ mx: .25 }} onClick={()=>appendToExpression("-")}><RemoveIcon/></Button>
                    <Button aria-label="multiply" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression("×")}><CloseIcon/></Button>
                    <Button aria-label="divide" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression("÷")}><DivideIcon height="24px"/></Button>
                    <Button aria-label="openParen" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression("(")}><OpenParenIcon height="24px"/></Button>
                    <Button aria-label="closeParen" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression(")")}><CloseParenIcon height="24px"/></Button>
                    <Button aria-label="exponent" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression(")^()", "(")}><ExponentIcon height="24px"/></Button>
                    <Button aria-label="pi" variant="outlined" sx={{ mx: .25, my: .5}} onClick={()=>appendToExpression("π")}><PiIcon height="24px"/></Button>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Button aria-label="input1" variant="contained" color="primary" sx={{ mx: .5, my: .5}} onClick={()=>appendToExpression("[" + expression.input1 + "]")} disabled={!expression.input1}>{expression.input1 || "input 1"}</Button>
                    <Button aria-label="input2" variant="contained" color="secondary" sx={{ mx: .5, my: .5}} onClick={()=>appendToExpression("[" + expression.input2 + "]")} disabled={!expression.input2}>{expression.input2 || "input 2"}</Button>
                    <Button aria-label="input3" variant="contained" color="secondary" sx={{ mx: .5, my: .5}} onClick={()=>appendToExpression("[" + expression.input3 + "]")} disabled={!expression.input3}>{expression.input3 || "input 3"}</Button>
                  </Grid>
                  <Grid xs={12} md={12}><hr/></Grid>
                  <Grid xs={12} md={12}>
                    <Typography className="tis-form-text">{expression.output || "output"} =</Typography>
                  </Grid>
                  <Grid xs={12} md={12}>
                    <TextField className='tis-form-field'
                      id="display"
                      variant="outlined"
                      value={expression.display}
                      onChange={(event) => handleTextChange(event, expression, setExpression, setDirty)}
                      placeholder="Type or use above buttons"
                      fullWidth
                      multiline
                      minRows={2}
                      //focused={true}
                    />
                  </Grid>
                  </Grid>
                </Paper>
            </Grid>
            <Grid xs={12} md={12}><hr/></Grid>
            <Grid xs={12} md={12}>
              <Paper sx={{ mt: 0, minHeight: 200, backgroundColor: '#ffffff', py: 2}}>
                <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                  <Grid xs={12} md={12}>
                    <Typography className='tis-form-title'>Test Expression</Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                  <TextField className='tis-form-field'
                    id="input1"
                    defaultValue={testValues.input1}
                    onBlur={(event) => handleTextChange(event, testValues, setTestValues)}
                    label="Input 1"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{expression.input1}</InputAdornment>
                    }}
                    type="number"
                  />
                  </Grid>
                  <Grid xs={12} md={4}>
                  <Button aria-label="calculate" variant="contained" color="primary" sx={{ mx: .5, my: .5}} endIcon={<ArrowForwardIcon/>} onClick={()=>calculate(expression, setExpression, testValues, setTestValues)}>Calculate</Button>
                  </Grid>
                  <Grid xs={12} md={4}>
                  <TextField className='tis-form-field'
                    id="output"
                    value={testValues.output}
                    //onBlur={(event) => handleTextChange(event, testValues, setTestValues)}
                    label="Output"
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{expression.output}</InputAdornment>
                    }} 
                  />
                  </Grid>
                    <Grid xs={12} md={4} hidden={!expression.useInput2}>
                    <TextField className='tis-form-field'
                      id="input2"
                      defaultValue={testValues.input2}
                      onBlur={(event) => handleTextChange(event, testValues, setTestValues)}
                      label="Input 2"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{expression.input2}</InputAdornment>
                      }} 
                      type="number"
                    />
                  </Grid><Grid hidden={!expression.useInput2} xs={0} md={8}></Grid>
                  <Grid hidden={!expression.useInput3} xs={12} md={4}>
                    <TextField className='tis-form-field'
                      id="input3"
                      defaultValue={testValues.input3}
                      onBlur={(event) => handleTextChange(event, testValues, setTestValues)}
                      label="Input 3"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{expression.input3}</InputAdornment>
                      }} 
                      type="number"
                    />
                  </Grid><Grid hidden={!expression.useInput3} xs={0} md={8}></Grid>
                  </Grid>
                </Paper>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
}