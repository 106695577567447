import { useCallback, useEffect, useState, useContext } from 'react'
import { UserContext } from '../App'
import bus from '../utils/EventBus.js';
import Event from '../utils/EventNames.js';
import { useIsMounted } from '../utils/ReactUtils'
import MaterialReactTable from 'material-react-table';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ScaleIcon from '@mui/icons-material/Scale';
import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, checkEmptyArrayError, checkStrictRequiredError, checkNumberError, checkYearError, checkPhoneError, checkDateError, checkEmailError, checkColumnError, validateColumns, AddRecordModal, stringDate, addRow, deleteRow, handleEditClick, handleSaveRowEdits, handleCancelEdit, setRowPhone, setRowSelect, setRowAutocomplete, handleFieldChange, handleTextChange, handleSelectChange, handleCheckboxChange } from '../form/Form'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient'
import Category from '../form/Panel.js'
import { readFile, uploadFile, downloadFile } from '../form/File'
import { useNavigate } from 'react-router-dom';
// import MUIRichTextEditor from 'mui-rte'
// import { createTheme, ThemeProvider } from '@mui/material/styles'
// import { EditorState, convertToRaw } from 'draft-js'
import countryOptions from '../staticData/Countries'
import operatingUnits from '../staticData/OperatingUnits'
import AuditInfoBox from '../user/AuditInfoBox';


// const myTheme = createTheme({
//   // mui-rte: Set up your custom MUI theme here
// })

export default function EditSpecification(props = {}) {
    let params = useParams()
    let navigate = useNavigate();
    const user = useContext(UserContext)
    const id = params.specificationId

    const units = {
      "metric": {
        "volume": "L",
        "weight": "kg",
        "density": "kg/L",
        "lowDensity": "mg/L",
        "temperature": "°C"
      },
      "imperial": {
        "volume": "gal",
        "weight": "lb",
        "density": "lb/gal",
        "temperature": "°F"
      }
    }

    let unitsType=props.unitsType
    const [currentUnitsType, setCurrentUnitsType] = useState("metric")
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [showCreatePanel, setShowCreatePanel] = useState({});
    const [tempRow, setTempRow] = useState({})
    const [validationErrors, setValidationErrors] = useState({});
    const getCommonEditTextFieldProps = useCallback(
      (cell) => {
        return {
          error: !!validationErrors[cell.id],
          helperText: validationErrors[cell.id],
          variant: "outlined",
          onBlur: (event) => { checkColumnError(cell.id, event.target.value, validationErrors, setValidationErrors)
          },
        };
      },
      [validationErrors],
    );
    const [specificationId, setSpecificationId] = useState(id ? id : "0")
    const [specification, setSpecification] = useState({
      id: "",
      name: "",
      target: "",
      min: "",
      max: "",
      formulaStates: "",
      uom: "",
      includeOnMI: false,
      includeOnPI: false,
      operatingUnits: [],
      value: ""
    })
    const [fieldErrors, setFieldErrors] = useState({})
    let tempInstruction = useState("")
    
    const convertAllUnits = (unitsType) => {
      // convert units on global unit change
      let volumeFields = ["specificationVolume", "scaledMmiVolume", "syrupVolume", "beverageVolume", "specificationSyrupYield", "hfcsYieldByVolume", "hfssBeverageVolume", "hfssSyrupVolume", "hfcsCalcBevVolume", "commercialWater", "volumeOfBev"]
      let weightFields = ["beverageWeight", "totalBeverageBaseWeight", "syrupWeight", "specificationYield", "hfcsYieldByWeight", "hfcsWetWeight77Brix", "waterWetWeight77Brix", "hfcsWetWeight71Brix", "waterWetWeight71Brix", "hfssBeverageWeight", "hfssSyrupWeight", "hfssBeverageWaterWeight", "hfssSyrupWaterWeight", "hfssDsb", "hfcsCalcBevWeight", "totalWeightOfBeverage", "weightOfSyrupAdded", "weightOfWaterAdded", "totalWeightMasterIngr", "totalWeightCommIngr", "totalWaterInBev", "totalWeightBevRelated", "totalStdBevWtUnit", ]
      let densityFields = ["specificationSyrupApparentDensity", "specificationDensity", "hfcsSyrupApparentDensity", "hfcsDensity", "hfssBeverageDensity", "hfssSyrupDensity", "beverageApparentDensity", "hfcsBevApparentDensity", "apparentDensityOfSyrup", "bevWtPerVol"]
      let temperatureFields = ["productCategoryTemperature"]
      let p = 5 //precision, number of sig figs

      let value = null
      // convert volume fields
      if (unitsType=="metric"){
        console.log("converting to metric...")
        
        for (let id of volumeFields) {
          //console.log("id: ", id)
          //console.log("typeof specification[id]: ", typeof specification[id])
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])*3.78541178).toPrecision(p) //gal to L
            //console.log("value: ", value)
            handleFieldChange(id, value, specification, setSpecification)
            //console.log(specification[id])
          }
        }
        for (let id of weightFields) {
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])/2.20462262).toPrecision(p) //lb to kg
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
        for (let id of densityFields) {
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])/8.345404).toPrecision(p) //lb/gal to kg/L
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
        for (let id of temperatureFields) {
          if (typeof specification[id]!="undefined"){
            value = ((parseFloat(specification[id])-32)*(5/9)).toPrecision(p) //F to C
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
      }
      else if (unitsType=="imperial"){
        for (let id of volumeFields) {
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])/3.78541178).toPrecision(p) //gal to L
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
        for (let id of weightFields) {
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])*2.20462262).toPrecision(p) //lb to kg
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
        for (let id of densityFields) {
          if (typeof specification[id]!="undefined"){
            value = (parseFloat(specification[id])*8.345404).toPrecision(p) //lb/gal to kg/L
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
        for (let id of temperatureFields) {
          if (typeof specification[id]!="undefined"){
            value = ((parseFloat(specification[id])*1.8) + 32).toPrecision(p) //C to F
            handleFieldChange(id, value, specification, setSpecification)
          }
        }
      }
      else {
        console.log("invalid unitsType: ", unitsType)
        return unitsType
      }
      setDirty()
      console.log("converted all units to ", unitsType)
      return unitsType
    }

    const getSpecification = async() => {
      console.log("getSpecification")
      if (id=="new"){
        console.log("new specification")
        setLoading(false)
        props.stopLoading()
        return
      }

      let textFields = [
        "id", "specificationName", "specificationId", "countryOfProduction", "countryOfSale", "formulaStates", "carbonationVolume", "parNo", "scaledVolume", "blendingInstruction"
      ]

      let options = {
        headers: {
          'x-api-key': ApiGateway.apiKey,
          'Content-Type': 'application/json',
          'Authorization': user.idToken
        }
      }

      
      let basePath = `${ApiGateway.specification}/${id}`
      
      let response = await axios.get(basePath, options)
      console.log(response)
      if(isSuccess(response)) {
        let data = response.data[0]
        let i=0
        let state = {}
        for (i = 0; i < textFields.length; i++){
          let field = textFields[i]
          if (data[field] || data[field] === 0){
            let text = data[field]
            state[field] = text
          }
        }
        console.log(state)
        setSpecification({...specification, ...state})
        console.log(specification)
        //TODO make sure units are correctly converted
        setLoading(false)
        props.stopLoading()
      }
      else {
        console.log(response)
        setLoading(false)
        props.stopLoading()
      }
    }

    const saveForm = async() => {
      console.log("save form")
      props.startLoading()
      let errors=[]
      // check errors
      if (checkStrictRequiredError(specification.specificationName)){
        errors.push("Valid Specification Name is required")
        setError(specification.specificationName, "specificationName", checkStrictRequiredError)
      }
      //TODO other fields

      // raise alerts
      if (errors.length > 0){
        console.log(errors.join("\n"))
        console.log("Errors ", errors.join("\n"))
        bus.emit(Event.ALERT, { "text": errors.join("\n"), "style": "warn" })
        props.stopLoading()
        return("Fix errors")
      }      

      //save data
      let c = {
        "id": id,
        "specificationName": specification.specificationName,
        "formulaId": specification.formulaId,
        "countryOfProduction": specification.countryOfProduction,
        "countryOfSale": specification.countryOfSale,
        "formulaStates": specification.formulaStates, 
        "carbonationVolume": specification.carbonationVolume,
        "parNo": specification.parNo,
        "scaledVolume": specification.scaledVolume,
        "blendingInstruction": specification.blendingInstruction,
      }

      //call service
      console.log('posting specification: ', c)
      let headers = {
        'x-api-key': ApiGateway.apiKey,
        'Content-Type': 'application/json',
        'Authorization': user.idToken
      }
      let response = await axios.post(`${ApiGateway.specification}/`, c, {headers: headers})
      console.log('specification save response: ', response)
      
      if (response.status==200 && !response.data.errorMessage){
        // bus.emit(Event.ALERT, { "text": "Saved Successfully", "style": "success", "duration": 2000 })
        props.stopLoading()
        setClean()
        return response
      } else {
        let text = "Not Saved\n"+ response.statusText
        // bus.emit(Event.ALERT, { "text": text, "style": "error" })
        props.stopLoading()
        return response
      }
      
    }

    const setError = (value, id, errorChecker=checkRequiredError, errorObject=fieldErrors, errorSetter=setFieldErrors) => {
      let error = errorChecker(value)
      errorObject[id]=error
      errorSetter({...errorObject}, {id: error})
    }

    const setDirty = () => {
      props.setDirty()
    }

    const setClean = () => {
      props.setClean()
    }

    useEffect(() => {
      //getSpecification()
      setLoading(false)
      props.stopLoading()
    }, [])

    if(loading){
      return (
        <div className="App"></div>
      )
    }

    if(props.saveClicked && !saving){
      console.log("saveClicked")
      setSaving(true)
      saveForm()
      setTimeout(() => {
        setSaving(false)
      }, 2000)
    }

    if (currentUnitsType!=unitsType){
      setCurrentUnitsType(convertAllUnits(unitsType))
    }

    return (
      <div className='App'>
        <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
          <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
            <Grid xs={12} md={12}>
              <Category category="Detail" title="Detail" hideCollapse={true} defaultExpanded={true}>
                <Box sx={{ mt: 0, minHeight: 200}}>
                  <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        id="specificationName"
                        defaultValue={specification.specificationName}
                        onBlur={(event)=> {
                          handleTextChange(event, specification, setSpecification)
                          //setError(specification.specificationName, "specificationName")
                        }}
                        label="Specification Name"
                        //error={!!fieldErrors.specificationName}
                        //helperText={fieldErrors.specificationName ? "This field is required" : false}
                        fullWidth
                        //required
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        id="target"
                        value={specification.target}
                        onChange={(event) => handleTextChange(event, specification, setSpecification)}
                        label="Target Value"
                        fullWidth
                        // InputProps={{
                        //   endAdornment: <InputAdornment position="end">{units[unitsType]["volume"]}&nbsp;&nbsp;<ScaleIcon fontSize="small"/></InputAdornment>
                        // }} 
                        type="number"
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        id="minTolerance"
                        value={specification.minTolerance}
                        onChange={(event) => handleTextChange(event, specification, setSpecification)}
                        label="Minimum Tolerance"
                        fullWidth
                        // InputProps={{
                        //   endAdornment: <InputAdornment position="end">{units[unitsType]["volume"]}&nbsp;&nbsp;<ScaleIcon fontSize="small"/></InputAdornment>
                        // }} 
                        type="number"
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        id="maxTolerance"
                        value={specification.maxTolerance}
                        onChange={(event) => handleTextChange(event, specification, setSpecification)}
                        label="Maximum Tolerance"
                        fullWidth
                        // InputProps={{
                        //   endAdornment: <InputAdornment position="end">{units[unitsType]["volume"]}&nbsp;&nbsp;<ScaleIcon fontSize="small"/></InputAdornment>
                        // }} 
                        type="number"
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        id="uom"
                        defaultValue={specification.uom}
                        onBlur={(event)=> {
                          handleTextChange(event, specification, setSpecification)
                        }}
                        label="UOM"
                        //helperText={fieldErrors.specificationName ? "This field is required" : false}
                        fullWidth
                        //required
                      />
                    </Grid>
                    <Grid xs={12} md={6}></Grid>
                    <Grid xs={12} md={6}>
                      <FormGroup className='tis-form-field' id="includeOnMI">
                      <FormControlLabel control={
                        <Checkbox 
                          checked={specification.includeOnMI} 
                          onChange={() => handleCheckboxChange("includeOnMI", specification, setSpecification)}
                          />} 
                          label="Include on MI" />
                      </FormGroup>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <FormGroup className='tis-form-field' id="includeOnPI">
                      <FormControlLabel control={
                        <Checkbox 
                          checked={specification.includeOnPI} 
                          onChange={() => handleCheckboxChange("includeOnPI", specification, setSpecification)}
                          />} 
                          label="Include on PI" />
                      </FormGroup>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Autocomplete className='tis-form-field'
                        multiple
                        id="operatingUnit"
                        options={operatingUnits}
                        value={specification.operatingUnit}
                        openOnFocus
                        onChange={(_event, values) => {
                          handleFieldChange("operatingUnit", values, specification, setSpecification)
                          //setError(values, "operatingUnit", checkEmptyArrayError)
                        }}
                        //onClose={()=>setError(specification.operatingUnit, "operatingUnit", checkEmptyArrayError)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //required
                            label="Operating Unit"
                            //error={fieldErrors.operatingUnit}
                            //helperText={(fieldErrors.operatingUnit ? "This field is required" : false)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                    <FormControl className='tis-form-field' fullWidth //error={!!fieldErrors.formulaStates}
                    >
                        <InputLabel id="value">Value</InputLabel>
                        <Select
                          id="formulaStates"
                          value={specification.formulaStates}
                          onChange={(event) => handleFieldChange("formulaStates", event.target.value, specification, setSpecification)}
                          // onClose={()=>setError(specification.formulaStates, "formulaStates", checkStrictRequiredError)}
                        >
                          <MenuItem value="">‎</MenuItem>
                          <MenuItem value={"Always"}>Always</MenuItem>
                          <MenuItem value={"Never"}>Never</MenuItem>
                          <MenuItem value={"Ask"}>Ask</MenuItem>
                        </Select>
                        {/* <FormHelperText hidden={!fieldErrors.formulaStates} >This field is required</FormHelperText> */}
                      </FormControl>
                    </Grid>
                    </Grid>
                  </Box>
                </Category>

              <Grid xs={12} md={12}><hr/></Grid>

              <Category category="History" title="History" hideCollapse={true} defaultExpanded={false}>
                <Box sx={{ mt: 0, minHeight: 200}}>
                  <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        label="Created By" 
                        defaultValue={specification.createdBy} 
                        onBlur={(event) => handleTextChange(event, specification, setSpecification)}
                        id="createdBy"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        label="Created Date" 
                        defaultValue={specification.createdDate} 
                        onBlur={(event) => handleTextChange(event, specification, setSpecification)}
                        id="createdDate"
                        fullWidth
                        disabled
                        //type="datetime-local"
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        label="Last Modified By"
                        defaultValue={specification.lastModifiedBy} 
                        onBlur={(event) => handleTextChange(event, specification, setSpecification)}
                        id="lastModifiedBy"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField className='tis-form-field'
                        label="Last Modified Date"
                        defaultValue={specification.lastModifiedDate} 
                        onBlur={(event) => handleTextChange(event, specification, setSpecification)}
                        id="lastModifiedDate"
                        fullWidth
                        disabled
                        //type="datetime-local"
                      />
                    </Grid>
                    <AuditInfoBox item={specification} />
                  </Grid>
                </Box>
              </Category>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
}