// import { memo } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import CommercializationForm from './CommercializationForm.js'
import LoadingButton from '@mui/lab/LoadingButton'

export function CommercializationFormDialog (props = {}) {  

  return (
    <Dialog sx={{ maxHeight: 'calc(100% - 50px)', top: '14px' }} maxWidth='xl' open={props.open} TransitionProps = {{ onEntered: () => props.onOpen() }}>
      <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Formula Commercialization</DialogTitle> 
      <DialogContent>
        <CommercializationForm formulaId={props.formulaId} />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <LoadingButton loading={props.isLoading} onClick={props.onClose}>Close</LoadingButton>
        <LoadingButton loading={props.isLoading} color="primary" onClick={props.onSave} variant="contained">Save</LoadingButton>
      </DialogActions>
    </Dialog>    
  )
}
