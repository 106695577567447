import React, { useEffect, useState, useContext, memo } from 'react'
import { UserContext } from '../App.js'
import { MenuItem, Paper } from '@mui/material'
import Autocomplete from '../form/StatefulAutocomplete'
import TextField from '../form/StatefulTextField'
import Select from '../form/StatefulSelect'
import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, checkStrictRequiredError, handleFieldChange } from '../form/Form.js'
import { ApiGateway } from '../config/config.js'
import axios from '../utils/AxiosClient.js'
import ListFormulaIngredients from '../formulaIngredient/ListFormulaIngredients.js'
import ListOutputDocuments from '../outputDocument/ListOutputDocuments'
import ListCosd from '../cosd/ListCosd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AuditInfoBox from '../user/AuditInfoBox'
import DebugButton from '../utils/DebugButton'
import { getState } from '../utils/State'
import bus from '../utils/EventBus.js'

export default memo((props = {}) => {
  const user = useContext(UserContext)
  
  const [countryOptions, setCountryOptions] = useState([])
  const [id, setId] = useState()
  
  const packageTypes = [
    "PET", "Can", "Glass", "Can & Glass"
  ]

  const loadCountries = async() => {
    await axios.get(`${ApiGateway.region}/countries?sorting=country_name`, { headers: { Authorization: user.idToken }}, setCountryOptions)
  }

  // const getCosdData = async() => {
  //   setIsLoadingCosd(true)
  //   if(props.context?.item?.id) {
  //     await axios.get(`${ApiGateway.formula}/${props.context.item?.formula?.id}/commercializations/${props.context.item?.id}/cosd`, { headers: { Authorization: user.idToken }}, setCosdData)
  //   }
  //   setIsLoadingCosd(false)
  // }

  // const setFormulaIngredientsData = (values = []) => {
  //   handleFieldChange("ingredients", values, formContext.item, props.onChangeItem)
  // }

  // const getFormulaIngredientsData = async() => {
  //   if(props.context?.item?.id && !props.context.item.ingredients?.length) {
  //     await axios.get(`${ApiGateway.formula}/${props.context.item?.formula?.id}/commercializations/${props.context.item?.id}/ingredients`, { headers: { Authorization: user.idToken }}, setFormulaIngredientsData)     
  //   }
  // }  


  const updateId = (event) => {
    console.log('updating id: ', event?.target?.value)
    setId(event?.target?.value)
  }

  useEffect(() => {
    loadCountries()
    setId(getState('CommercializationForm.id'))
    bus.on('onChange_CommercializationForm_id', updateId)
    return (() => {
      bus.removeListener('onChange_CommercializationForm_id', updateId)
    })
    // setId(id)
  }, [])

  
  return (
    <Paper sx={{ backgroundColor: 'transparent', p: '0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '95%', margin: '1em auto' }}>
      <TextField id="id" scope="CommercializationForm" type="hidden" /> 
      {/* type="hidden" /> */}
      <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
        <Grid xs={12} md={6}>
          {countryOptions && <Autocomplete multiple id="countriesOfProduction" scope="CommercializationForm" label="Countries of Production" options={countryOptions} validator={checkStrictRequiredError} />}
        </Grid>

        <Grid xs={12} md={6}>
          <Select id="formulaStates" scope="CommercializationForm" label="Formula State(s)" required validator={checkStrictRequiredError}>
            <MenuItem value="">‎</MenuItem>
            <MenuItem value={"Syrup"}>Syrup</MenuItem>
            <MenuItem value={"Beverage"}>Beverage</MenuItem>
            <MenuItem value={"Syrup & Beverage"}>Syrup & Beverage</MenuItem>
          </Select>
        </Grid>
        <Grid xs={12} md={6}>
          <Select id="status" scope="CommercializationForm" label="Status" required validator={checkStrictRequiredError}>
            <MenuItem value="">‎</MenuItem>
            <MenuItem value={"Draft"}>Draft</MenuItem>
            <MenuItem value={"Issued"}>Issued</MenuItem>
            <MenuItem value={"Obsolete"}>Obsolete</MenuItem>
          </Select>
        </Grid>

        <Grid xs={12} md={6}>
          <Select id="endUse" scope="CommercializationForm" label="End Use" required validator={checkStrictRequiredError}>
            <MenuItem value="">‎</MenuItem>
            <MenuItem value={"Pre Mix"}>Pre Mix</MenuItem>
            <MenuItem value={"Post Mix"}>Post Mix</MenuItem>
            <MenuItem value={"Ready To Drink"}>Ready To Drink</MenuItem>
          </Select>
         </Grid>
        <Grid xs={12} md={6}>
          <Autocomplete freeSolo scope="CommercializationForm" label="Package Type" id="packageType" options={packageTypes} validator={checkStrictRequiredError} />
        </Grid>



        {/* <Grid xs={12} sx={{ mt: 0, pt: 0, mb: '2em' }}>
          <FormControl className='tis-form-field' required error={!!fieldErrors.blendingInstructions}>
            <InputLabel sx={{ position: 'relative', top: '2px', fontSize: '14px', width: '150px' }} id="blendingInstructions">Blending Instructions</InputLabel>
            <ReactQuill
              theme="snow"
              formats={[
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video'
              ]}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  ['link', 'image', 'video'],
                  ['clean']
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                }
              }}
              // defaultValue={"<h1>heading!</h1><p>paragraph</p>"}
              defaultValue={props.item?.blendingInstructions}
              onChange={(_value, _delta, _source, editor) => { 
                // console.log(editor.getHTML())
                handleFieldChange("blendingInstructions", editor.getHTML(), props.item, props.onChangeItem) 
              }}
              style={{ height: 200 }}
            />
          </FormControl>
        </Grid> */}
        <Grid xs={12}>
          <ListCosd label="COSD" disabled={!id} formulaId={props.formulaId} commercializationId={id} />
        </Grid>
        <Grid xs={12}>
          <ListFormulaIngredients label="Commercialized Ingredients" scope="CommercializationForm" disabled={!id} formulaId={props.formulaId} commercializationId={id} showScaledVolume={true} />
        </Grid>
                  
        <Grid xs={12}>
          <ListOutputDocuments refresh={props.refresh} label="Generated Documents" disabled={!id} formulaId={props.formulaId} commercializationId={id} />
        </Grid>
        <Grid xs={12}>
          <TextField id="guidelines" scope="CommercializationForm" label="Guidelines" validator={checkRequiredError} multiline rows={2} />
        </Grid>

        <AuditInfoBox item={getState('CommercializationForm')} />
        <DebugButton name="Commercialization" log={() => getState('CommercializationForm') } />
      </Grid>
    </Paper>
  )
})