import { default as React, useState, useEffect } from 'react'
import { default as bus } from '../utils/EventBus'

import Event from '../utils/EventNames.js';
import AnimatedAlert from '../layout/AnimatedAlert'
import queryString from 'query-string'
import { useIsMounted } from '../utils/ReactUtils'
import LoadingIndicator from './LoadingIndicator'
// const LoadingGif = require('../img/loading.gif')

export default function Page (props = {}) {
  // console.log('Page constructed')
  const [message, setMessage] = useState()
  const isMounted = useIsMounted()

  useEffect(() => {
    // console.log('Page.useEffect called')
    bus.on(Event.ALERT, showMessage)
    return () => { bus.off(Event.ALERT, showMessage) }
  }, [])

  const showMessage = (message) => {
    // console.log('Page.showMessage, message: ', message)
    setMessage(message)
  }

  // Override component did mount so we can emit events on our App's bus
  useEffect(() => {
    if(isMounted()) {

      let {location} = props
      let alert = null
      if(location) {
        let q = queryString.parse(location.search)
  
        if(q.messageText) {
          alert = {
            text: q.messageText,
            type: q.messageType || 'info',
            duration: q.messageDuration || 0
          };
        }
        if(!alert) {
          if (location.state) {
            alert = location.state.message
          }
        }
      }
      if(!alert && props.history) {
        let historyLocation = props.history.location
        if(historyLocation && historyLocation.state) {
          alert = historyLocation.state.message
        }
      }
  
      if(alert) {
        bus.emit(Event.ALERT, alert)
      }
    }
  }, [isMounted, props])

  // const onContentInitialized = (c) => {
  //   setContent(c || {})
  //   if(commandBar) {
  //     onInit()
  //   }
  // }

  // const onInit = () => {
  //   if(!isInitialized) {
  //     commandBar.setTarget(content)
  //     setIsInitialized(true)
  //   }
  // }

  // const onCommandBarInitialized = (commandBar) => {
  //   setCommandBar(commandBar || {})
  //   if(content) {
  //     onInit()
  //   }
  // }

  return (
    <div className={'tis-page ' + (props.pageClassName || "")}>
      {message && <AnimatedAlert message={message} onComplete={() => {
        // console.log('Page.AnimatedAlert.onCompleted called for message: ', message)
        setMessage(undefined)
      }}/>}
      {(props.breadcrumb || props.commandBar) &&
        <div className="nav-container tis-breadcrumb-container">
          <nav className={`navbar navbar-default tis-breadcrumb navbar-fixed-top${props.fullscreen ? " fullscreen" : ""}`}>
            {
            (props.showBreadcrumb === undefined || props.showBreadcrumb) && props.breadcrumb &&
              <h1 className="pull-left">{props.breadcrumb}</h1>
            }
            {(props.showCommandBar === undefined || props.showCommandBar) && props.commandBar &&
            
              <div className="tis-command-bar">
                {props.commandBar}
              </div>
            }
          </nav>
        </div>
      }

      <div className={"page-content" + ((props.breadcrumb || props.commandBar) ? '-adjusted' : '')}>
      { props.children }
      </div>
      <LoadingIndicator isLoading={props.isLoading} />
    </div>
  )
}
