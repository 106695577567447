import { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Box, MenuItem, Paper } from '@mui/material'
import Autocomplete from '../form/StatefulAutocomplete'
import Select from '../form/StatefulSelect'
import TextField from '../form/StatefulTextField'
import Category from '../form/Panel'
import { ApiGateway } from '../config/config'
import { UserContext } from '../App'
import axios, { isSuccess } from '../utils/AxiosClient'
import bus from '../utils/EventBus'
import Event from '../utils/EventNames'
import MESSAGE_TYPE from '../layout/AnimatedAlert'
import { getState } from '../utils/State'
import DebugButton from '../utils/DebugButton'
// const SavingGif = require('../img/saving.gif')

export default function FormulaIngredientForm(props = {}) {
    // console.log('FormulaIngredientForm props.item: ', props.item)
    const user = useContext(UserContext)
    // const [validationErrors, setValidationErrors] = useState({})
    const [ingredientOptions, setIngredientOptions] = useState()
    
    const getIngredientOptions = async() => {
      if(props.onChangeItem) props.onChangeItem(props.item)
      let response = await axios.get(`${ApiGateway.ingredient}?sort=name`, {headers: {Authorization: user.idToken}})
      if(isSuccess(response)) {
        console.log('ingredients: ', response?.data)
        const ingredientOptions = response?.data?.data
        ingredientOptions.unshift({
          ingredient: {
            name: '‎'
          },
          alias: '‎'
        })
        let selectedIngredient
        if(props.item?.ingredient?.id) {
          selectedIngredient = ingredientOptions ? ingredientOptions.find(o => o.value === props.item?.ingredient?.id) : undefined
        }
        setIngredientOptions(ingredientOptions)
        // setIngredient(selectedIngredient ?? {name: ''})
        // setIngredientLabel(selectedIngredient?.name)
      } else {
        bus.emit(Event.ALERT, { text: 'Unable to load ingredient.  Try refreshing the page...', style: MESSAGE_TYPE.WARNING })
        console.log(response)
      }
    }

    useEffect(() => {
      if(props.mode === 'add') {

        (async() =>{
          await getIngredientOptions()
        })()
      }
    }, [])

    // const onChangeIngredient =  (_event, ingredient) => {
    //   if(ingredient) {
    //     ingredient.name = ingredient.name ?? ''
    //   }
    //   setIngredient(ingredient) 
    //   let newModel = Object.assign({}, props.item, ingredient)
    //   newModel.ingredient = ingredient
    //   if(props.onChangeItem) {
    //     props.onChangeItem(newModel)
    //   }
    // }

    return (
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
        <Grid xs={12} md={12}>            
          <Box sx={{ mt: 0, minHeight: 200}}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }} >
              <Grid xs={12} md={6}>
                <TextField id="id" scope="FormulaIngredientForm" type="hidden"/>
                <Autocomplete
                  id="ingredient"
                  name="ingredient"
                  label="Ingredient"
                  disabled={props.mode === 'edit'}
                  options={ingredientOptions}
                  // loading={ingredientOptionsLoading}
                  scope="FormulaIngredientForm"
                  getOptionLabel={(option) => {
                    let label = ''
                    if(option?.name) {
                      let suffix = ''
                      if(option.sCode && option.sCode.length > 0) {
                        suffix = ` (${option.sCode})`
                      }
                      label = `${option.name}${suffix}`
                    }
                    return label
                  }}
                  // onChange={onChangeIngredient}
                  // inputValue={ingredientLabel}
                  // onInputChange={(_event, ingredientLabel) => {
                  //   setIngredientLabel(ingredientLabel) 
                  // }}
                  // renderInput={(params) => <TextField {...params} label="Ingredient" /> }
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="alias" scope="FormulaIngredientForm" label="Alias" />
              </Grid>

              <Grid xs={12} md={6}>
                <TextField id="weightKg" type="weight" scope="FormulaIngredientForm" label="Weight (Kg)" />
              </Grid>    
              <Grid xs={12} md={6}>
                <Select id="formOfIngredient" scope="FormulaIngredientForm" label="Form of Ingredient">
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Powder">Powder</MenuItem>
                  <MenuItem value="Liquid">Liquid</MenuItem>
                  {/* <MenuItem value="Leaves">Leaves</MenuItem> */}
                </Select>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="concentrationWw" scope="FormulaIngredientForm" label="Concentration (w/w)" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="sapMaterialNumber" scope="FormulaIngredientForm" label="SAP Material #" />
              </Grid>

              <Grid xs={12} md={6}>
                <TextField id="sapDescription" scope="FormulaIngredientForm" label="SAP Description" multiline rows={8} />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="ingredientCheckTest" scope="FormulaIngredientForm" label="Ingredient Check Test" multiline rows={8} />
              </Grid>
              <Category category="juiceAttr" title="Juice Attributes" hideCollapse={true} defaultExpanded={false}>
                <Grid container rowSpacing={2} columnSpacing={{ md: 4 }} xs={12} md={12}>
                  <Grid xs={12} md={6}>
                    <TextField id="refractometerBrix" scope="FormulaIngredientForm" label="Refractometer Brix" />
                  </Grid>                

                  <Grid xs={12} md={6}>
                    <TextField id="percentSolubleSolids" scope="FormulaIngredientForm" label="Percent Soluble Solids" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="densityGMl" scope="FormulaIngredientForm" label="Density (g/ml)" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="percentAcid" scope="FormulaIngredientForm" label="% Acid w/w as Citric" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField id="pH" scope="FormulaIngredientForm" label="pH" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField id="pulp20Mesh" scope="FormulaIngredientForm" label="20 Mesh Pulp" />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField id="essencePpm" scope="FormulaIngredientForm" label="Essence (ppm)" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField id="sulfitePpm" scope="FormulaIngredientForm" label="Sulfite (ppm)" />
                  </Grid>                

                  <Grid xs={12} md={6}>
                    <TextField id="appearance" scope="FormulaIngredientForm" label="Appearance" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="odor" scope="FormulaIngredientForm" label="Odor" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="partStorageConditionAndShelfLife" scope="FormulaIngredientForm" label="Part Storage Condition and Shelf Life" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="strength" scope="FormulaIngredientForm" label="Strength" />
                  </Grid> 

                  <Grid xs={12} md={6}>
                    <TextField id="vitaminCMg100Ml" scope="FormulaIngredientForm" label="Vitamin C (mg/100Ml)" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField id="nleaSsJuiceBrix" scope="FormulaIngredientForm" label="NLEA SS Juice Brix" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField id="oilRecoveryRateVv" scope="FormulaIngredientForm" label="Oil Recovery Rate (v/v)" />
                  </Grid>
                </Grid>
              </Category>
            </Grid>
          </Box>
        </Grid>
        <DebugButton name="Formula Ingredient" log={() => getState('FormulaIngredientForm') } />
      </Paper>
    )
}