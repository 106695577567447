import { numberSerializer } from '../utils/Serializer'
import ProductFormulaDetailMapper from './ProductFormulaDetailMapper'
import ProductCategoryInfoMapper from './ProductCategoryInfoMapper'


export default class FormulaMapper {

    productFormulaDetailMapper = new ProductFormulaDetailMapper()
    productCategoryInfoMapper = new ProductCategoryInfoMapper()

    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            // commercializations: item.commercializations ?? [],
            // formulaIngredients: item.formulaIngredients ?? [],
            ...this.productFormulaDetailMapper.toFormModel(item),
            ...this.productCategoryInfoMapper.toFormModel(item)
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            // commercializations: formModel.commercializations,
            // formulaIngredients: formModel.formulaIngredients,
            ...this.productFormulaDetailMapper.toObject(formModel),
            ...this.productCategoryInfoMapper.toObject(formModel)
        }
    }
}