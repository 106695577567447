import Grid from '@mui/material/Unstable_Grid2'
import DynaMapper from '../mapper/DynaMapper'
import { MenuItem } from '@mui/material'
import TextField from '../form/StatefulTextField'
import TextField3 from '../form/StatefulTextFieldWithTolerances'
import Select from '../form/StatefulSelect'
import { useEffect, useReducer } from 'react'
import DebugButton from '../utils/DebugButton'
import { useIsMounted } from '../utils/ReactUtils'
import { getState } from '../utils/State'


export const camelize = (str) => {
    return str.toLowerCase().replace(/%/g, 'percent ').replace(/@/g, 'at ').replace(/[^0-9a-zA-Z\s]/g, ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '');
}

// const getStyles = (value, options = {}) => {
//     return {
//         "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
//             borderRadius: options.min ? '4px 0 0 4px' : options.max ? '0 4px 4px 0' : options.target ? '0' : '4px',
//             borderLeft: options.max ? ((value && !options.target) ? '.33em solid #77CC99' : '') : value ? '.33em solid #77CC99' : '', 
//             borderRight: options.min ? ((value && !options.target) ? '.33em solid #77CC99': '') : value ? '.33em solid #77CC99' : '' 
//         },
//         "& .MuiInputBase-root fieldset": {
//             backgroundColor: value ? 'rgba(200, 255, 236, .4)': ''
//         }
//     }
// }

export const DynaField = (props = {}) => {
    // let label = props.field?.label
    // // let uom = props.field.uom
    // let id = props.field?.id
    // // let unitsSet = units[props.context?.unitsType]
    // let type = props.field?.type ?? 'text'

    let {
        type = 'text',
        label,
        id, 
        format
    } = props.field ?? {}

    // let inputProps = {}
    // if (uom === "$VOL$" || uom === "$WGT$" || uom === "$DEN$" || uom === "$TEM$"){
    //     inputProps = {
    //         endAdornment: <InputAdornment position="end">{unitsSet[uom]}&nbsp;&nbsp;<ScaleIcon fontSize="small" /></InputAdornment>
    //     }
    // }
    // else if (uom) {
    //     inputProps = {
    //         endAdornment: <InputAdornment position="end">{uom}</InputAdornment>
    //     }
    // }

    const {scope} = props

    let toReturn 
    if (type && type==="yesNo"){
        toReturn = (
            <Grid xs={12} md={6}>
                <Select id={id} label={label} scope={scope} type={type}>{/* onChange={(event) => handleSelectChange({id}, event, props.item, props.onChangeItem)}*/}
                    <MenuItem value="">‎</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </Select>
            </Grid>
        )
    } else {
        toReturn = (
            <Grid xs={12} md={6}>
                {props.field.includeTolerances && 
                    <TextField3 id={id} label={label} scope={scope} type={type} format={format} />
                }
                {!props.field.includeTolerances && 
                    <TextField id={id} label={label} scope={scope} type={type} format={format} />
                }
            </Grid>
        )
    }

    // if (props.field.includeTolerances){
    //     // console.log('has tolerances: ', id)
    //     toReturn = (
    //         <Grid xs={12} md={6}>
    //             <FormGroup row={true}>
    //                 <TextField id={`${id}Min`} label="Min" context={props.context} /> 
    //                 <TextField id={id} label={label} context={props.context} type={type} />
    //                 <TextField id={`${id}Max`} label="Max" context={props.context} />
    //             </FormGroup>
    //         </Grid>
    //     )
    // }

    return (
        toReturn
    )
}

export const DynaForm = (props = {}) => {
    const { fields, scope} = props
    // const [, forceUpdate] = useReducer(x => x + 1, 0)
    const isMounted = useIsMounted()

    useEffect(() => {
        if(isMounted) {
            // forceUpdate()
        }
    }, [])
    return (
        <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
            {
                fields.map((field, i) => {
                    return <DynaField key={i} field={field} scope={scope}/>
                })
            }
            <DebugButton name="Specs" log={() => getState(scope)}/>
        </Grid>
    )
}

// export const getConvertibleFields = (fieldsList) => {
//     let volumeFields = []
//     let weightFields = []
//     let densityFields = []
//     let temperatureFields = []

//     for (let item of fieldsList){
//         if (item.includeTolerances){
//             if (item.uom === "$VOL$"){
//                 volumeFields.push(`${item.id}Target`, `${item.id}Max`, `${item.id}Min`)
//             }
//             else if (item.uom === "$WGT$"){
//                 weightFields.push(`${item.id}Target`, `${item.id}Max`, `${item.id}Min`)
//             }
//             else if (item.uom === "$DEN$"){
//                 densityFields.push(`${item.id}Target`, `${item.id}Max`, `${item.id}Min`)
//             }
//             else if (item.uom === "$TEM$"){
//                 temperatureFields.push(`${item.id}Target`, `${item.id}Max`, `${item.id}Min`)
//             }
//         } else {
//             if (item.uom === "$VOL$"){
//                 volumeFields.push(item.id)
//             }
//             else if (item.uom === "$WGT$"){
//                 weightFields.push(item.id)
//             }
//             else if (item.uom === "$DEN$"){
//                 densityFields.push(item.id)
//             }
//             else if (item.uom === "$TEM$"){
//                 temperatureFields.push(item.id)
//             }
//         }
//     }

//     return {
//         "volumeFields": volumeFields,
//         "weightFields": weightFields,
//         "densityFields": densityFields,
//         "temperatureFields": temperatureFields
//     }
// }

// export const syrupConvFields = getConvertibleFields(SyrupFields)

// export const bevConvFields = getConvertibleFields(BeverageFields)