import React from 'react'
import { Paper } from '@mui/material'
import Autocomplete from '../form/StatefulAutocomplete'
import TextField from '../form/StatefulTextField'
import TextField3 from '../form/StatefulTextFieldWithTolerances'
import Grid from '@mui/material/Unstable_Grid2'
import AuditInfoBox from '../user/AuditInfoBox'
import { getState } from '../utils/State'

const packageTypes = [
  "PET", "Can", "Glass", "Can & Glass"
]

export default function CharacteristicForm(props = {}) {
  // console.log('CharacteristicForm props.item: ', props.item)
  const { scope } = props
  return (
    <Paper sx={{ p: 0, border: 'none', boxShadow: 'none' }}>
      <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
        <TextField id="id" scope={scope} type="hidden" />
        <Grid xs={12} md={6}>
          <Autocomplete id="packageType" label="Package Type" options={packageTypes} freeSolo scope={scope} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField3 id="carbonation" label="Carbonation" scope={scope} type="volume" /> 
        </Grid>
        <Grid xs={12} md={6}>
          <TextField id="nitrogenPsi" label="Nitrogen" scope={scope} />{/*type="psi"*/}
        </Grid>
        <AuditInfoBox item={getState('CharacteristicForm')} />
      </Grid>
    </Paper>
  )
}