import { Typography } from '@mui/material'
import SuccessIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'


export default function InlineMessage(props = {}) {

    const getIcon = () => {
        let type = (props.type ?? 'info').toLowerCase()
        let icon
        switch (type) {
            case 'success':
                icon = <SuccessIcon/>
                break
            case 'warn':
            case 'warning':
                icon = <WarningIcon/>
                break
            case 'error':
                icon = <ErrorIcon/>
                break
            case 'info':
            default:
                icon = <InfoIcon/>

        }
        return icon
    }

    return (
        <Typography className={`tis-inline-message tis-inline-message-${props.type ?? "info"}`}>{props.children}{getIcon()}</Typography>
    )
}