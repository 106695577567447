import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import LoadingIndicator from '../layout/LoadingIndicator'

export default function ConfirmDialog(props={}) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose} /* hideDialog */
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
          <LoadingIndicator isLoading = {props.isLoading} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.onClickNo}>{props.noLabel ? props.noLabel : "No"}</Button>
          <Button variant="contained" onClick={props.onClickYes}>{props.yesLabel ? props.yesLabel : "Yes"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}