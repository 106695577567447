import Layout from './layout/Layout'
import './css/adminlte.css'
import './css/App.css'
import { Buffer } from 'buffer'
import { Azure, ApiGateway } from './config/config'
import ConfirmDialog from './form/ConfirmDialog'
import { createContext, useState, useEffect } from 'react'
import { AzureAuthenticator } from 'tis-azure-auth'
import Axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { getToken, logout } from './auth/auth'
// const LoadingGif = require('./img/loading.gif')

export const UserContext = createContext(null)
export const AppModeContext = createContext(null)

function App(props = {}) {
  const [accessToken, setAccessToken] = useState()
  const [user, setUser] = useState()
  const [mode, setMode] = useState(window.location.href.indexOf('localhost') > -1 ? 'debug' : '')
  const [sessionTimeoutWarning, setSessionTimeoutWarning] = useState()
  const [sessionTimeout, setSessionTimeout] = useState()
  const [isExtendSessionDialogVisible, setIsExtendSessionDialogVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // console.log('onLoad, accessToken: ', accessToken)
  // const auth = new AzureAuthenticator(Azure)

  const parseJwt = (token) => {
    if(!token) {
      throw new Error("Trying to parse a jwt token, but no token was provided.")
    }
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
  }

  const hideDialog = () => {
    setIsExtendSessionDialogVisible(false)
    return false
  }

  const extendSession = async () => {
    clearTimeout(sessionTimeoutWarning)
    clearTimeout(sessionTimeout)
    setIsExtendSessionDialogVisible(false)
    await login()
  }
  
  const login = async () => {
    // const authResponse = await auth.authenticate()
    const authResponse = await getToken()
    // console.log('auth response: ', authResponse)
    const axiosConfig = {
      baseURL: ApiGateway.domain,
      headers: {
        'x-api-key': ApiGateway.apiKey,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authResponse?.idToken}`
      }
    }
    // console.log('App.login, axiosConfig: ', axiosConfig)
    const axiosInstance = await Axios.create(axiosConfig)    
    // console.log('axiosInstance: ', axiosInstance)
    
    const jwt = parseJwt(`Bearer ${authResponse?.idToken}`)
    if(jwt) {
      let ttl = jwt.exp - jwt.iat
      let warningGracePeriod = 5 * 60 * 1000
      let ms = (ttl * 1000) - warningGracePeriod
      // ms = 3000
      // warningGracePeriod = 3000
      // console.log("ms: ", ms)
      clearTimeout(sessionTimeoutWarning)
      setSessionTimeoutWarning(setTimeout(() => {
        setIsExtendSessionDialogVisible(true)
        setSessionTimeout(setTimeout(logout, warningGracePeriod))
      }, ms))
      setAccessToken(authResponse?.accessToken)
   
      if(!user) {
        // console.log('no user in the session, looking with using idToken: ', authResponse?.idToken)

        let userResponse = {}
        // console.log('jwt: ', jwt)
        if(jwt.koId) {
          userResponse = await axiosInstance.get(`${ApiGateway.employee}/status/active/${jwt.koId}`)
        } else if(jwt.mail) {
          userResponse = await axiosInstance.get(`${ApiGateway.employee}/status/active/email/${encodeURIComponent(jwt.mail)}`)
        }
        // console.log('userResponse: ', userResponse)
        if(userResponse.data) {
          userResponse.data.idToken = `Bearer ${authResponse?.idToken}`
          setUser(userResponse.data)
          setIsLoading(false)
        }
      }
    }
  }

  const logout = async () => {
    setIsExtendSessionDialogVisible(false)
    // console.log('need to reenable the logout feature...')
    setAccessToken(null)
    setUser(null)
    logout()
  }



  useEffect(() => {
    if (!accessToken) {
      (async () => {
        await login()
      })()
    }
  }, [accessToken])

  return (
    <>
      {accessToken && user && 
        <AppModeContext.Provider value={mode}>
          <UserContext.Provider value={user}>
            <Layout />
          </UserContext.Provider>
        </AppModeContext.Provider>
      }
      {isLoading && <div className="loading">
        <div className='loading-inner'>
          {/* <img src={LoadingGif} alt="Loading"/> Loading ... */}
          <CircularProgress color="inherit" />
        </div>
      </div>}
      {isExtendSessionDialogVisible && <ConfirmDialog title="Session Expiring Soon" text="Would you like to extend your session and keep working?" open={isExtendSessionDialogVisible}  onClickYes={extendSession} onClickNo={logout} yesLabel="Keep Working" noLabel="Log Out"/>}
    </>
  )
}

export default App
