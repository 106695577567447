import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import CharacteristicForm from './CharacteristicForm'
import LoadingButton from '@mui/lab/LoadingButton'

export function CharacteristicFormDialog (props = {}) {  
    return (
      <Dialog sx={{ maxHeight: 'calc(100% - 2em)' }} fullWidth maxWidth='lg' scroll='paper' open={props.open}>
        <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Characteristic</DialogTitle> 
        <DialogContent>
          <CharacteristicForm scope={props.scope} />
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <LoadingButton loading={props.isLoading} onClick={props.onClose}>Cancel</LoadingButton>
          <LoadingButton loading={props.isLoading} color="primary" onClick={props.onSave} variant="contained">Save</LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
