import { useCallback, useEffect, useRef } from "react";

export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}

export function findInput (node) {
  if(node) {
      if(node.current) {
          node = node.current
      }
      // console.log('node: ', node)
      if (node.hasOwnProperty('_value') || node._value || node.hasOwnProperty('value') || node.value || node.hasOwnProperty('checked')) {
          return node
      } else if (node.node) {
          return findInput(node.node)
      } else {
          try {
              let inputs = node.querySelectorAll('input')
              return inputs[0]
          } catch (e) {
              console.log(e)
          }
          return node
      }
  }
}

export function getValue (ref, options = {}) {
  options = {deserialize: false, ...options}
  let value
  if(ref) {
      let inputRef = findInput(ref.current)
      if(inputRef?.type === 'checkbox') {
          return inputRef._value ?? inputRef.checked
      } else {
          value = inputRef?._value ?? inputRef?.value
      }
      // if(options.deserialize) {
          try {
              value = JSON.parse(value)
          } catch (e) { 
              //ok
          }
      // }
  }
  return value
}

export function setValue (ref, value, options = {}) {
  options = {internalOnly: false, serialize: false, ...options}
  let inputRef = findInput(ref?.current)
  // if(options.serialize) {
      value = JSON.stringify(value)
  // }
  if(inputRef) {
      if(inputRef.type === 'checkbox') {
          inputRef._value = value
      } else {
          inputRef.value = inputRef._value = value
      }
  }
  console.log('setValue: ref: ', ref, 'value: ', value)
}

export function isEmptyObject(value) {
    if (value == null) {
      // null or undefined
      return false;
    }
  
    if (typeof value !== 'object') {
      // boolean, number, string, function, etc.
      return false;
    }
  
    const proto = Object.getPrototypeOf(value);
  
    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto !== null && proto !== Object.prototype) {
      return false;
    }
  
    const isEmpty = (obj) => {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
            return false;
            }
        }
        return true
    }
    return isEmpty(value)
}

export function isEmpty(value) {
    return value === undefined || value === null || ('' + value).trim() === '' || (Array.isArray(value) && !value.length) || isEmptyObject(value)
}