import { useContext, useEffect, useReducer, useState, memo } from 'react'
import { Science as ScienceIcon } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'
import { useNavigate, useParams } from 'react-router-dom'
import FormulaForm from './FormulaForm'
import { Typography } from '@mui/material'
import { checkRequiredError, checkEmptyArrayError, checkStrictRequiredError } from '../form/Form'
import { syrupConvFields, bevConvFields, camelize } from './DynaForm'
import axios, { isSuccess } from '../utils/AxiosClient'
import bus from '../utils/EventBus'
import Event from '../utils/EventNames'
import { ApiGateway } from '../config/config'
import { UserContext } from '../App'
import { dispatch, getState, setState } from '../utils/State'
import { useIsMounted } from '../utils/ReactUtils'
// import CharacteristicMapper from '../mapper/CharacteristicMapper'
import FormulaMapper from '../mapper/FormulaMapper'
import FactorsAndStandardsMapper from '../mapper/FactorsAndStandardsMapper'
import DynaMapper from '../mapper/DynaMapper'
import { BeverageFields, SyrupFields } from '../staticData/SyrupAndBeverageData'


const FormulaFormPage = memo((props = {}) => {
  
  let params = useParams()
  let navigate = useNavigate()
  const user = useContext(UserContext)
  
  
  // const [, forceUpdate] = useReducer(x => x + 1, 0)
  
  // const [id, setId] = useState(params.formulaId)
  const id = params.formulaId
  const [isDirty, setIsDirty] = useState(false) 
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({})
  // const [formulaIngredients, setFormulaIngredients] = useState()
  // const [commercializations, setCommercializations] = useState()
  // const [characteristics, setCharacteristics] = useState()
  // const [ingredientChecks, setIngredientChecks] = useState()
  // const isMounted = useIsMounted()
  
  // const characteristicMapper = new CharacteristicMapper()
  const formulaMapper = new FormulaMapper()
  const factorsAndStandardsMapper = new FactorsAndStandardsMapper()

  // const onChangeCommercializations = (commercializations) => {
  //  console.log('incoming commercializations: ', commercializations) 
  //   setIsDirty(true)
  //   setCommercializations(commercializations)
  // }

  // const onChangeFormulaIngredients = (formulaIngredients) => {
  //   setIsDirty(true)
  //   setFormulaIngredients(formulaIngredients)
  // }

  // const onChangeFactorsAndStandards = (factorsAndStandards) => {
  //   setIsDirty(true)
  //   setFactorsAndStandards(factorsAndStandards)
  // }

  const setError = (value, id, errorChecker = checkRequiredError, errorObject = fieldErrors, errorSetter = setFieldErrors) => {
    if(errorChecker && typeof errorChecker === 'function') {
      let error = errorChecker(value)
      errorObject[id] = error
      errorSetter({ ...errorObject }, { id: error })
    }
  }
  
  // const onChangeProductCategoryInfo = useCallback((id, value) => {
  //   setIsDirty(true)
  //   eval(`set${id.charAt(0).toUpperCase()}${id.slice(1)}(value)`)
    
  // })
  
  useEffect(() => {
    (async () => {
      await getFormula()
      getSpecifications()
      // getFormulaIngredients()
      getFactorsAndStandards()
      // getIngredientChecks()
    })()
  }, [])

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const getFormula = async () => {
    setIsLoading(true)
    let response = await axios.get(`${ApiGateway.formula}/${id}`, { headers: { Authorization: user.idToken } })
    let model = formulaMapper.toFormModel(response?.data?.data)
    setState('FormulaForm', model)
    dispatch('FormulaForm', model)

    setIsDirty(false)
    setIsLoading(false)
  }

  // const getCommercializations = async () => {
  //   axios.get(`${ApiGateway.formula}/${id}/commercializations`, { headers: { Authorization: user.idToken } }, setCommercializations)
  // }

  // const setCommercializations = (values = []) => {
  //   console.log('setCommercializations, values: ', values)
  //   handleFieldChange('commercializations', values, formula, setter)
  // }

  // const getCommercializations = async() => {
  //   await axios.get(`${ApiGateway.formula}/${id}/commercializations`, { headers: { Authorization: user.idToken } }, setCommercializations)
  // }

  const getSpecifications = async() => {
    const setSpecs = (data) => {
      let syrupSpecData = {}
      let bevSpecData = {}
      for (let item of data) {
        item.fieldId = `${item.formulaState}_${camelize(item.name)}`
        if (item.formulaState?.toLowerCase().includes("syrup")){
          syrupSpecData[item.fieldId] = item.value.target
          if (item.value.min || item.value.max){
            syrupSpecData[`${item.fieldId}Min`] = item.value.min
            syrupSpecData[`${item.fieldId}Max`] = item.value.max 
          }
        }
        if (item.formulaState?.toLowerCase().includes("beverage")){
          bevSpecData[item.fieldId] = item.value.target
          if (item.value.min || item.value.max){
            bevSpecData[`${item.fieldId}Min`] = item.value.min
            bevSpecData[`${item.fieldId}Max`] = item.value.max 
          }
        }
      }

      // console.log('syrupSpecData: ', syrupSpecData)

      let mapper = new DynaMapper()
      let syrupFields = SyrupFields.reduce((obj, field) => {
          if(field.includeTolerances) {
              obj[`${field.id}Min`] = ''
              obj[`${field.id}Max`] = ''
          } 
          obj[field.id] = ''
          return obj
      }, {})
      let syrup = mapper.toFormModel(syrupFields, syrupSpecData)
      setState('syrup', syrup, {scope: 'SpecificationsForm'})
      dispatch('SpecificationsForm.syrup', syrup)
      
      let bevFields = BeverageFields.reduce((obj, field) => {
        if(field.includeTolerances) {
            obj[`${field.id}Min`] = ''
            obj[`${field.id}Max`] = ''
        } 
        obj[field.id] = ''
        return obj
      }, {})
      let beverage = mapper.toFormModel(bevFields, bevSpecData)
      setState('beverage', beverage, {scope: 'SpecificationsForm'})
      dispatch('SpecificationsForm.beverage', beverage)
      
    }
    await axios.get(`${ApiGateway.formula}/${id}/specifications`, { headers: { Authorization: user.idToken } }, setSpecs)
  }
 
  // const getFormulaIngredients = async () => {
  //   await axios.get(`${ApiGateway.formula}/${id}/ingredients/master?sort=alias`, { headers: { Authorization: user.idToken } }, setFormulaIngredients)
  // }

  // const getIngredientChecks = async () => {
  //   // const _setIngredientChecks = (_ingredientChecks) => {
  //   //   console.log('ingredientChecks: ', _ingredientChecks)
  //   //   setIngredientChecks(_ingredientChecks)
  //   // }
  //   await axios.get(`${ApiGateway.formula}/${id}/ingredientCheck`, { headers: { Authorization: user.idToken } }, setIngredientChecks)
  // }

  const getFactorsAndStandards = async() => {
    const response = await axios.get(`${ApiGateway.formula}/${id}/factorsAndStandards`, { headers: { Authorization: user.idToken } })
    
    if(isSuccess(response)) {
      const fs = response?.data?.data?.reduce((fs, item) => {
        fs[item.name] = item
        return fs
      }, {})
      let model = factorsAndStandardsMapper.toFormModel(fs)
      setState('FactorsAndStandardsForm', model)
      dispatch('FactorsAndStandardsForm', model)
    }
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else { navigate('/formulas/') }
  }

  const save = async () => {
    setIsLoading(true)

    let errors = []
    // check errors
    // if (checkStrictRequiredError(getValue(refs.formulaName))) {
    //   errors.push("Valid Formula Name is required")
    //   setError(getValue(refs.formulaName), "formulaName", checkStrictRequiredError)
    // }
    // if (checkStrictRequiredError(getValue(refs.bbn))) {
    //   errors.push("Valid BBN/L-Number is required")
    //   setError(getValue(refs.bbn), "bbn", checkStrictRequiredError)
    // }
    // if (checkStrictRequiredError(getValue(refs.operatingUnit))) {
    //   errors.push("An Operating Unit is required")
    //   setError(getValue(refs.operatingUnit), "operatingUnit", checkStrictRequiredError)
    // }
    // if (checkStrictRequiredError(formula?.recordType)) {
    //   errors.push("Valid Record Type is required")
    //   setError(formula?.recordType, "recordType", checkStrictRequiredError)
    // }
    // if (checkEmptyArrayError(getValue(refs.countriesOfProduction))) {
    //   errors.push("Valid Countries of Production is required")
    //   setError(getValue(refs.countriesOfProduction), "countriesOfProduction", checkEmptyArrayError)
    // }
    // if (checkStrictRequiredError(getValue(refs.formulaStates))) {
    //   errors.push("Valid Formula State(s) is required")
    //   setError(getValue(refs.formulaStates), "formulaStates", checkStrictRequiredError)
    // }

    // raise alerts
    if (errors.length > 0) {
      bus.emit(Event.ALERT, { text: errors.join("\n"), style: 'warn' })
      setIsLoading(false)
      return ("Fix errors")
    }

    const item = formulaMapper.toObject(getState('FormulaForm'))
    let response = await axios.post(ApiGateway.formula, item, { headers: { Authorization: user.idToken } })

    if (isSuccess(response)) {
      bus.emit(Event.ALERT, { "text": "Saved Successfully", "style": "success", "duration": 2000 })
      setIsLoading(false)
      if(props.setIsDirty) {
        props.setIsDirty(false)
      }
      return response
    } else {
      let text = "Not Saved\n" + response.statusText
      bus.emit(Event.ALERT, { "text": text, "style": "error" })
      setIsLoading(false)
      return response
    }
  }

  const breadcrumb = <span><ScienceIcon className="icon"/>Formula<Typography className="tis-form-text"> * - Required</Typography></span>
  const commandBar = <FormViewCommandBar onSave={save} onCancel={confirmCancel} />
  return (
    <>
        <Page isLoading={isLoading} { ...props} breadcrumb={breadcrumb} commandBar={ commandBar }>
          <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} onClose={hideDialog} onClickYes={() => { navigate('/formulas/') }} yesLabel="Discard" noLabel="Stay on Page"/>
          <FormulaForm id={id} /> 
        </Page>
    </>
  )
})
export default FormulaFormPage