import Menu from './Menu'
import { Science as ScienceIcon } from '@mui/icons-material'
import CalculateIcon from '@mui/icons-material/Calculate';
import IngredientIcon from '@mui/icons-material/LocalDrink';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import MenuItem from './MenuItem'

export default function SidebarMenu (props = {}) {
  window.scrollTo(0, 0)
  
  return (
    // Build the app menu using common tis-react
    <Menu>
      <MenuItem href="/formulas" title="Formulas" icon={<ScienceIcon/>}/>
      <MenuItem href="/ingredients" title="Ingredients" icon={<IngredientIcon/>}/>
      <MenuItem href="/conversions" title="Conversions" icon={<CalculateIcon/>}/>
      {/* <MenuItem href="/formulas/1/commercializations/1/edit/outputdocuments/1" title="Output Document" icon={<StoreIcon/>}/> 
      <MenuItem href="/specification/edit/1" title="Specifications" icon={<SquareFootIcon/>}/>  */}
      {/* <MenuItem href="#" title="BU Assessments" icon={<FactCheck/>}> */}
        {/* <MenuItem href="/bu-assessments/self-assessments" title="Self Assessments" icon={<FactCheck/>}/>
        <MenuItem href="/bu-assessments/quality-and-food-safety-assessments" title="Quality &amp; FS Assessments" icon={<FactCheck/>}/>
        <MenuItem href="/bu-assessments/environmental-and-safety-assessments" title="Env. &amp; Safety Assessments" icon={<FactCheck/>}/> */}
      {/* </MenuItem> */}
    </Menu>
  )
}