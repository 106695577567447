import CosdMapper from '../mapper/CosdMapper'
import { CosdFormDialog } from './CosdFormDialog'
import { ApiGateway } from '../config/config'
import ListView from '../layout/ListView.js'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListCosd(props = {}) {
    const columns = [
      { accessorFn: row => row.countryOfSale?.name, header: 'Country of Sale' },
      { accessorKey: 'productName', header: 'Product Name' },
      { accessorKey: 'carbonationVolume', header: 'Carbonation Volume' }
    ]

    return (      
      <ListView 
        label = {props.label ?? "Countries of Sale"}
        disabled = {props.disabled}
        itemLabelFn = {cosd => `COSD ${cosd.countryOfSale?.name}`}
        // getItemFn = { ({item, dialogMode}) => {
        //   item.commercialization = { id: props.context?.item?.id }
        //   if(dialogMode === 'add') {
        //     delete item.id
        //   }
        //   return item
        // }}
        columns = {columns}
        scope = "CosdForm"
        dataMapper = { CosdMapper }
        getData = { async ({ requestConfig }) => {
          const response = await axios.get(`${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/cosd`, requestConfig)
          return { isSuccess: isSuccess(response), data: response.data?.data }
        }}
        onSave = { async ({item, requestConfig}) => {
          item.formula = { id: props.formulaId }
          item.commercialization = { id: props.commercializationId }
          const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/cosd`, item, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}
        onDelete= { async ({item, requestConfig}) => {
          const response = await axios.delete(`${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/cosd/${item.id}`, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}

        dialog = { <CosdFormDialog /> }
      />
    )
}