import { FormGroup } from '@mui/material'
import StatefulTextField from './StatefulTextField'
import { getState } from '../utils/State'
import { isEmpty } from '../utils/ReactUtils'

/**
 * Stateful TextField component with tolerances
 * 
 * @returns 
 */
export default function StatefulTextFieldWithTolerances(props = {}) {

    let {
        id,
        minId = `${props.id}Min`,
        maxId = `${props.id}Max`,
        minLabel = 'Min',
        maxLabel = 'Max',
        label,
        type,
        scope,
        includeTolerances,
        fullWidth = false,
        ...otherProps
    } = props

    const getStylesMin = (value) => {
        const hasValue = !isEmpty(value)
        const targetValue = getState(`${scope}.${id}`)
        return {
            display: type === 'hidden' ? 'none' : '',
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: '4px 0 0 4px',
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: (hasValue && !targetValue) ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            // "& .MuiInputBase-root": {
            //     fontWeight: _hasValue ? 700 : 400
            // },
            width: '25%', 
            px: '2px 0 2px 2px'
        }
    }

    const getStylesTarget = (value) => {
        const hasValue = !isEmpty(value)
        return {
            display: type === 'hidden' ? 'none' : '',
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: 0,
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            // "& .MuiInputBase-root": {
            //     fontWeight: _hasValue ? 700 : 400
            // },
            width: '50%', 
            px: '2px 0'
        }
    }

    const getStylesMax = (value) => {
        const hasValue = !isEmpty(value)
        const targetValue = getState(`${scope}.${id}`)
        return {
            display: type === 'hidden' ? 'none' : '',
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: '4px 0 0 4px',
                borderLeft: (hasValue && !targetValue) ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            // "& .MuiInputBase-root": {
            //     fontWeight: hasValue ? 700 : 400
            // },
            width: '25%', 
            px: '2px 2px 2px 0'
        }
    }

    return (
        <FormGroup row={true}>
            <StatefulTextField 
                id = { minId }
                label = { minLabel }
                type = { type }
                scope = { scope }
                getStyles = { getStylesMin }
                fullWidth = { false }
                { ...otherProps }
            />
            <StatefulTextField 
                id = { id }
                label = { label }
                type = { type }
                scope = { scope }
                getStyles = { getStylesTarget }
                fullWidth = { false }
                { ...otherProps }
            />
            <StatefulTextField 
                id = { maxId }
                label = { maxLabel }
                type = { type }
                scope = { scope }
                getStyles = { getStylesMax }
                fullWidth = { false }
                { ...otherProps }
            />
        </FormGroup>
    )
}