import { numberSerializer } from '../utils/Serializer'

export default class FormulaIngredientMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            ingredient: {
                id: numberSerializer.toString(item.ingredient?.id),
                name: item.ingredient?.name
            },
            formula: {
                id: numberSerializer.toString(item.formula?.id)
            },
            alias: item.alias ?? '',
            weightKg: numberSerializer.toString(item.weightKg),
            formOfIngredient: item.formOfIngredient ?? '',
            status: item.status ?? '',
            ccnaId: item.ccnaId ?? '',
            sapMaterialNumber: item.sapMaterialNumber ?? '',
            sapDescription: item.sapDescription ?? '',
            concentrationWw: numberSerializer.toString(item.concentrationWw),
            ingredientCheckTest: item.ingredientCheckTest ?? '',
            refractometerBrix: numberSerializer.toString(item.refractometerBrix),
            percentSolubleSolids: numberSerializer.toString(item.percentSolubleSolids),
            densityGMl: numberSerializer.toString(item.densityGMl),
            percentAcid: numberSerializer.toString(item.percentAcid), 
            pH: numberSerializer.toString(item.pH),
            pulp20Mesh: numberSerializer.toString(item.pulp20Mesh),
            essencePpm: numberSerializer.toString(item.essencePpm),
            sulfitePpm: numberSerializer.toString(item.sulfitePpm),
            appearance: item.appearance ?? '',
            odor: item.odor ?? '',
            partStorageConditionAndShelfLife: item.partStorageConditionAndShelfLife ?? '',
            strength: numberSerializer.toString(item.strength),
            vitaminCMg100Ml: numberSerializer.toString(item.vitaminCMg100Ml),
            nleaSsJuiceBrix: numberSerializer.toString(item.nleaSsJuiceBrix),
            oilRecoveryRateVv: numberSerializer.toString(item.oilRecoveryRateVv)
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            ingredient: {
                id: numberSerializer.toObject(formModel.ingredient?.id)
            },
            name: formModel.name,
            alias: formModel.alias,
            weightKg: numberSerializer.toObject(formModel.weightKg),
            formOfIngredient: formModel.formOfIngredient,
            status: formModel.status,
            ccnaId: formModel.ccnaId,
            sapMaterialNumber: formModel.sapMaterialNumber,
            sapDescription: formModel.sapDescription,
            concentrationWw: numberSerializer.toObject(formModel.concentrationWw),
            ingredientCheckTest: formModel.ingredientCheckTest,
            refractometerBrix: numberSerializer.toObject(formModel.refractometerBrix),
            percentSolubleSolids: numberSerializer.toObject(formModel.percentSolubleSolids),
            densityGMl: numberSerializer.toObject(formModel.densityGMl),
            percentAcid: numberSerializer.toObject(formModel.percentAcid),
            pH: numberSerializer.toObject(formModel.pH),
            pulp20Mesh: numberSerializer.toObject(formModel.pulp20Mesh),
            essencePpm: numberSerializer.toObject(formModel.essencePpm),
            sulfitePpm: numberSerializer.toObject(formModel.sulfitePpm),
            appearance: formModel.appearance,
            odor: formModel.odor,
            partStorageConditionAndShelfLife: formModel.partStorageConditionAndShelfLife, 
            strength: numberSerializer.toObject(formModel.strength),
            vitaminCMg100Ml: numberSerializer.toObject(formModel.vitaminCMg100Ml),
            nleaSsJuiceBrix: numberSerializer.toObject(formModel.nleaSsJuiceBrix),
            oilRecoveryRateVv: numberSerializer.toObject(formModel.oilRecoveryRateVv)
        }
    }
}