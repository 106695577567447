import { default as React } from 'react'
import { Science as ScienceIcon } from '@mui/icons-material'
import ListFormulas from './ListFormulas'
import Page from '../layout/Page'

export default function ListFormulasPage (props = {}) {
  const breadcrumb = <span><ScienceIcon/> Formulas</span>

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={false}>
      <ListFormulas />
    </Page>
  )
}
