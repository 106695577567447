import { useCallback, useEffect, useState } from 'react'
import bus from '../utils/EventBus.js';
import Event from '../utils/EventNames.js';
import { useIsMounted } from '../utils/ReactUtils'
import MaterialReactTable from 'material-react-table';
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, checkStrictRequiredError, checkNumberError, checkYearError, checkPhoneError, checkDateError, checkEmailError, checkColumnError, validateColumns, AddRecordModal, stringDate, addRow, deleteRow, handleEditClick, handleSaveRowEdits, handleCancelEdit, setRowPhone, setRowSelect, setRowAutocomplete, handleTextChange, handleSelectChange } from '../form/Form'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import Category from '../form/Panel.js'

export default function ListConversions(props = {}) {
    let params = useParams()
    let navigate = useNavigate();
    const id = params.conversionId
    
    const columnsData = [
      { 
        accessorKey: 'name', 
        header: 'Name', 
        size: 80, 
        editable: false
      },
      { 
        accessorKey: 'output', 
        header: 'Output', 
        size: 40, 
        editable: false
      },
      { 
        accessorKey: 'input1', 
        header: 'Input 1', 
        size: 40, 
        editable: false
      },
      { 
        accessorKey: 'input2', 
        header: 'Input 2', 
        size: 40, 
        editable: false
      },
      { 
        accessorKey: 'input3', 
        header: 'Input 3', 
        size: 40, 
        editable: false
      },
      { 
        accessorKey: 'display', 
        header: 'Expression', 
        size: 80, 
        editable: false
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        columnDefType: 'display', 
        size: 50,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '0rem' }}>
          <Tooltip arrow placement="left" title="Edit">
            <Link to={ `/formulas/edit/${row.original.id}` }>
            <IconButton>
              <EditIcon />
            </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Delete">
            <IconButton color="error" 
              // onClick={()=>confirmDeleteAssessment(row.original.id)}
              >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          </Box>
        ),
      },
    ] //TODO set columns
    const [rowData, setRowData] = useState([]) //TODO fetch existing conversions

    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)

    const [ingredient, setIngredient] = useState({
      //TODO set empty ingredient
    })
    const [fieldErrors, setFieldErrors] = useState({})    

    const getData = async() => {
      // TODO fetch data
      setLoading(false)
      props.stopLoading()
    }

    useEffect(() => {
      getData()
    }, [])

    if(loading){
      return (
        <div className="App"></div>
      )
    }

    return (
      <div className='App'>
      {/* <ConfirmDialog title={dialogTitle} text="The record will be permanently deleted" open={confirmDialog} hideDialog={hideDialog} handleConfirm={()=>deleteAssessment(assessmentToDelete)} yesLabel="Delete" noLabel="Keep"/> */}
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
          <Grid xs={12} md={12}>
            <Box>
            <MaterialReactTable
              columns={columnsData}
              data={rowData}
              enableColumnActions={false}
              enableColumnFilters={props.showFilters}
              initialState={{ showColumnFilters: true }}
              enablePagination={true}
              enableSorting={true}
              enableBottomToolbar={true}
              enableEditing={false}
              muiTableContainerProps={{ sx: { minHeight: 300, maxHeight: 400} }}
              renderTopToolbar={false}
            />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
    )
}