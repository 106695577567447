import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../App'
import { MenuItem, Paper } from '@mui/material'
import Select from '../form/StatefulSelect'
import TextField from '../form/StatefulTextField'
import Grid from '@mui/material/Unstable_Grid2'
import { ApiGateway } from '../config/config'
import axios from '../utils/AxiosClient'
import LoadingIndicator from '../layout/LoadingIndicator'
import DebugButton from '../utils/DebugButton'
import {getState} from '../utils/State'

export default function CosdForm(props = {}) {
  const user = useContext(UserContext)
  const [countryOptions, setCountryOptions] = useState()
  const loadCountries = async() => {
    const setter = (data) => {
      data.unshift({id: '', name: `‎`})
      setCountryOptions(data.map(c => <MenuItem key={c.id} value={'' + c.id}>{c.name}</MenuItem> ))
    }
    await axios.get(`${ApiGateway.region}/countries?sorting=country_name`, { headers: { Authorization: user.idToken }}, setter)
  }

  useEffect(() => {
    loadCountries()
  }, [])

  return (
    <Paper sx={{ p: 0, border: 'none', boxShadow: 'none' }}>
      <Grid container rowSpacing={2}>
        <TextField id="id" scope="CosdForm" type="hidden" />
        <Grid xs={12}>
          <TextField id="productName" scope="CosdForm" label="Product Name" />
        </Grid>
        <Grid xs={12}>
          <Select id="countryOfSale" scope="CosdForm" label="Country of Sale">
            {countryOptions}
          </Select>
        </Grid>
        <Grid xs={12}>
          <TextField id="carbonationVolume" scope="CosdForm" label="Carbonation Volume" type="volume" />
        </Grid>
        <DebugButton name="COSD" log={() => getState('CosdForm')}/>
      </Grid>
      <LoadingIndicator isLoading={props.isSaving} />
    </Paper>
  )
}