import React, { useEffect, useState } from 'react'
import {Autocomplete as MuiAutocomplete, TextField } from '@mui/material'

import { useIsMounted, isEmpty } from '../utils/ReactUtils'
import { getState, setState } from '../utils/State'
import bus from '../utils/EventBus'
/**
 * Stateful Autocomplete component, with standard default settings
 * 
 * @param {} props 
  *      - refs: Pass in the props.refs object to attach to the DOM. The props.refs object should have a key -> ref pair for each field in the form.
 *
 * @returns 
 */
export default function StatefulAutocomplete(props = {}) {
    let initialState = getState(props.scope ? `${props.scope}.${props.id}` : props.id)
    const [sx, setSx] = useState({})
    const [shrink, setShrink] = useState(false)
    const [value, setValue] = useState(initialState ?? [])
    
    const isMounted = useIsMounted()
    
    const defaultIsOptionEqualToValue = (option, value) => {
        let isEqual = false
        if(option?.hasOwnProperty('id')) {
            if(!value) {
                isEqual = option.id === undefined || option.id === null || option.id === ''
            } else {
                isEqual = option.id == value.id
            }
        } else {
            isEqual = option == value
        }
        // console.log('isEqual: ', isEqual, option, value)
        return isEqual
    }

    const defaultRenderInput = (params) => (
        <TextField
            {...params}
            onChange={defaultOnChange}
            required={required}
            InputLabelProps = {{ shrink }}
            label={label}
            // error={fieldErrors?.[id]}
            // helperText={fieldErrors?.[id] ? 'This field is required' : false}
        />
    )

    const defaultGetOptionLabel = (option) => {
        let label = ''
        if(option) {
            if(option.label) {
                label = option.label
            } else if (option.name) {
                label = option.name
            } else {
                label = typeof(option) === 'object' ? '' : '' + option
            }
        }
        return label
    }

    const { 
        scope,
        onError,// = props.context.onError,
        fieldErrors,// = props.context.fieldErrors,
        className = 'tis-form-field', 
        openOnFocus = true,    
        isOptionEqualToValue = defaultIsOptionEqualToValue,
        onChange,
        onBlur,
        getOptionLabel = defaultGetOptionLabel,
        renderInput = defaultRenderInput,
        defaultValue,
        id, label, options, required, validator, ...otherProps} = props  
    
    const owner = scope ? `${scope}.${id}` : id
        
    useEffect(() => {
        let eventName = scope ? `onChange_${scope}_${id}` : `onChange_${id}`

        if(isMounted) {
            // console.log(`value ${scope}.${id}: `, value)

            let initialValue = defaultValue ?? getState(owner)
            if(initialValue) {
                defaultOnChange({target: {value: initialValue}}, initialValue)
            } 

            bus.on(eventName, defaultOnChange)
        }
        return () => {
            bus.removeListener(eventName, defaultOnChange)
        }
    }, [])

    function defaultOnChange(event, value) {
        // console.log(`StatefulAutocomplete.defaultOnChange ${owner}, incoming event: `, event, 'value: ', value)
        if(event.owner !== owner) {
            if(!value) {
                value = event?.target?.value
            }
            // console.log(`StatefulAutocomplete.defaultOnChange ${owner}, value: `, value)
            setValue(value)
            setState(id, value, {owner, scope, bubble: event.bubble ?? true})
            setStyles(value)
            setShrink(!isEmpty(value))
            if(onError) {
                onError(value, id, validator)
            }
            if(onChange) {
                onChange(event, value)
            }
        }
    }

    const defaultOnBlur = (event) =>  {
        setState(id, value, {owner, scope, bubble: event.bubble ?? true})
        setStyles(value)
        setShrink(!isEmpty(value))
        if(onError) {
            console.log('onblur, onError', id)
            onError(value, id, validator)
        }
        if(onBlur) {
            onBlur(event)
        }
    }

    const setStyles = (value, options = {}) => {
        // console.log(`value ${scope}.${id}: `, value)
        const hasValue = value?.hasOwnProperty('id') ? !isEmpty(value.id) : !isEmpty(value)
        setSx({
            "& .MuiInputBase-root": { 
                borderRadius: options.min ? '4px 0 0 4px' : options.max ? '0 4px 4px 0' : options.target ? '0' : '4px',
                borderLeft: options.max ? ((hasValue && !options.target) ? '.33em solid #77CC99' : '') : hasValue ? '.33em solid #77CC99' : '', 
                borderRight: options.min ? ((hasValue && !options.target) ? '.33em solid #77CC99': '') : hasValue ? '.33em solid #77CC99' : '',
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': 'rgba(0,0,0,0)',
                // fontWeight: hasValue ? 700 : 400,
                color: 'rgba(0, 0, 0, 0.9)'
            }
        })
    }

    return (
        <MuiAutocomplete 
            className={className}
            id={id}
            value={value}
            onChange = { defaultOnChange }

            options={options ?? []}
            isOptionEqualToValue={isOptionEqualToValue}
            openOnFocus={openOnFocus}

            onBlur = { defaultOnBlur }
            renderInput={renderInput}
            getOptionLabel={getOptionLabel}
            sx={sx}
            {...otherProps}
        />
    )
}