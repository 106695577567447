import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../App'
import { Button, Paper } from '@mui/material'
import TextField from '../form/StatefulTextField'
import Autocomplete from '../form/StatefulAutocomplete'
import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, handleFieldChange } from '../form/Form'
import { ApiGateway } from '../config/config'
import axios from '../utils/AxiosClient'
import AuditInfoBox from '../user/AuditInfoBox'
import { isEmpty } from '../utils/ReactUtils'
import { getState, setState } from '../utils/State'


export default function IngredientCheckForm(props = {}) {
  const user = useContext(UserContext)
  const [ingredientOptions, setIngredientOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { scope } = props
  console.log('scope ==> ', scope)
  const loadIngredientOptions = async(formulaId) => {
    setIsLoading(true)
    console.log("formulaId: ", formulaId)
    // const _setIngredientOptions = (data) => {
    //   setIngredientOptions(data?.map(fi => fi.ingredient))
    // }
    await axios.get(`${ApiGateway.formula}/${formulaId}/ingredients/master`, { headers: { Authorization: user.idToken }}, setIngredientOptions)
    setIsLoading(false)
  }

  useEffect(() => {
    // console.log('formulaIngredients: ', props.formulaIngredients)
    loadIngredientOptions(props.formulaId)
    // const ingredientOptions = props.formulaIngredients?.map(fi => fi.ingredient)
    console.log('ingredientOptions: ', ingredientOptions)
    setIngredientOptions(ingredientOptions)
  }, [])

  return (
    <Paper sx={{ backgroundColor: 'transparent', p: '0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '95%', margin: '1em auto' }}>
      <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
        <Grid xs={12} md={6}>
          <TextField id="ingredientCheckName" label="Ingredient Check" scope={scope} />
        </Grid>
        <Grid xs={12} md={6}>
          <Autocomplete
              multiple
              id="ingredientIds"
              label="Ingredients to Include"
              getOptionLabel={(option) => `${option.ingredient?.name}${option.ingredient?.alias && option.ingredient?.alias !== option.ingredient?.name ? ` (${option.ingredient?.alias})` : ''}`}
              options={ingredientOptions}
              isOptionEqualToValue={(option, value) => {
                return option.ingredient?.id === value.ingredient?.id
              }}
              scope={scope}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Button
            variant="contained"
            onClick={()=>{
              const ingredientIds = getState('ingredientIds', scope)
              console.log(ingredientIds)
              let w = 0
              for (let ing of ingredientIds){
                w = w + Number(ing?.weightKg)
              }
              console.log(w)
              setState('oneUnitKgPlusAnyPart', w, {scope})
              //@TODO: set value
              // props.context?.item?.setValue(props.context?.refs?.oneUnitKgPlusAnyPart, w)
            }}
            // disabled={isEmpty(getState('ingredientIds', scope))}
          >Calculate Weight from Ingredients</Button>
        </Grid>
        <Grid xs={12} md={6}>
          <TextField id="oneUnitKgPlusAnyPart" label="Weight of Ingredient" type='weight' scope={scope} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField id="stdConc" label="Standard Concentration" scope={scope} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField id="factorOverride" label="Override of Factor" scope={scope} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField id="weightOverride" label="Override of Weight" scope={scope} type='weight' />
        </Grid>
        <AuditInfoBox item={getState(scope)} />
      </Grid>
    </Paper>
  )
}