import { useContext, useState } from 'react'
import OutputDocumentMapper from '../mapper/OutputDocumentMapper'
import { UserContext } from '../App'

import ListView from '../layout/ListView'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient'
import { OutputDocumentFormDialog } from './OutputDocumentFormDialog'

export default function ListOutputDocuments(props = {}) {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  // const [outputDocuments, setOutputDocuments] = useState()
  
  // const [documentListRefresh, setDocumentListRefresh] = useState(0)

  // const getData = 

  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true)

  //     let url = `${ApiGateway.documents}/generated`

  //     let response = await axios.get(url, { headers: { Authorization: user.idToken } })
  //     if (isSuccess(response)) {
  //       let payload = response.data
  //       let data = payload?.results
  //       console.log('loaded outputDocuments: ', data)
  //       setOutputDocuments(data)
  //     } else {
  //       console.log('Error loading generated outputDocuments: ', response)
  //     }
  //     setIsLoading(false)
  //   })()
  // }, [props.refresh, documentListRefresh, user.idToken])
  

  const columns = [
    { accessorKey: 'template', header: 'Document Template', size: 50 },
    { accessorKey: 'name', header: 'Document Id', size: 450 },
    { accessorKey: 'createdBy', header: 'Generated By', size: 50 }
  ]

  const onDownload = async ({item, requestConfig}) => {
    setIsLoading(true)
    let url = `${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/output-documents/url`
    const { template, name } = item
    const body = { template, key: name }
    let response = await axios.post(url, body, requestConfig)
    if (isSuccess(response)) {
      const anchor = document.createElement('a')
      anchor.href = response.data?.url
      // anchor.target = '_blank' // Open in a new tab
      anchor.rel = 'noopener noreferrer' // Security measure for opening new tabs
      anchor.download = name // Optional: specify a filename
      anchor.type="application/octet-stream"
      document.body.appendChild(anchor)
      setIsLoading(false)
      anchor.click()
      document.body.removeChild(anchor)
       
    } else {
      console.log('Error loading generated outputDocuments: ', response)
    }
    setIsLoading(false)
  }

  return (
    <ListView
      label={props.label}
      columns={columns}
      isLoading={isLoading}
      disabled={props.disabled}
      getData={ async ({ requestConfig }) => {
        const response = await axios.get(`${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/output-documents`, requestConfig)
        return { isSuccess: isSuccess(response), data: response.data?.data }
      }}
      onSave={ async ({item, requestConfig}) => {
        item.commercialization = { id: props.commercializationId }
        item.template = item.template.value
        setIsLoading(true)
        
        const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/output-documents`, item, requestConfig)
        // setDocumentListRefresh(documentListRefresh + 1)
        setIsLoading(false)
        return { isSuccess: isSuccess(response) }
      }}
      onDelete={ async ({item, requestConfig}) => {
        const response = await axios.delete(`${ApiGateway.outputDocument}/${item.id}`, requestConfig) 
        return { isSuccess: isSuccess(response) }
      }}
      addLabel="Add"

      scope = "OutputDocumentForm"
      dataMapper = { OutputDocumentMapper }

      showDownload={true}
      dialog={<OutputDocumentFormDialog/>}
      onDownload={onDownload}
      
    />
  )
}
