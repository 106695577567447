import bus from '../utils/EventBus'

export /*static*/ const state = {}

const traverse = (obj = {}, key = '', create = false, scope) => {
    console.log(`traversing using key ${key} on `, obj)
    const idx = key.indexOf('.')
    if(idx > -1) {
        let childKey = key.substring(0, idx)
        let child = obj[childKey]
        if(child) {
            return traverse(child, key.substring(idx + 1), create, childKey)
        } else {
            if(create) {
                obj[childKey] = {}
                return traverse(obj[childKey], key.substring(idx + 1), create, childKey)
            }
            return null
        }
    } else {
        return { obj, key, scope }
    }
}

export const dispatch = (scope, value) => {
    if(!value) {
        value = getState(scope)
    }
    Object.keys(value).forEach(key => {
        const eventName = `onChange_${scope ? scope + '_' : ''}${key}`
        const event = { target: { value: value[key]}}
        // console.log(`firing ${eventName}, event: `, event)
        bus.emit(eventName, event)
    })
}

export const getState = (path, scope) => {
    if(scope && ('' + scope).trim().length) {
        path = `${scope}.${path}`
    }
    if(path) {
        let result = traverse(state, path)
        return result ? result.obj[result.key] : null
    } else {
        return state
    }
}

export const setState = (path, value, options) => {
    console.log(`path: ${path}, scope: ${options?.scope}, value: ${value}`)
    options = { bubble: true, ...options }
    let { scope, bubble, silent=false, owner } = options
    if(scope && ('' + scope).trim().length) {
        path = `${scope}.${path}`
        if(!state[scope]) {
            state[scope] = {}
        }
    }
    let { obj, key: id, scope: pathScope } = traverse(state, path, true)

    let previousValue = obj[id]
    obj[id] = value
    scope = scope ?? pathScope
    if(!silent) {
        let event = { path, owner, bubble: false, target: { value, previousValue, scope, id } }
        // if(bubble) {
            const eventName = options.eventName ?? scope ? `onChange_${scope}_${id}` : `onChange_${id}`
            // console.log('emitting onChange event: ', eventName, event)
            bus.emit(eventName, event)
        // }
    }
}