export const apiKey = 'ELC418kI1l7LemEPImSbp5tcMndO8E9v1vNcwvLM'
export const stage = 'dev'
export const domain = `https://api.${stage}.tis.coke.com`
export const employee = `/employees`
export const formula = `/komix-service/formulas`
export const ingredient = `/komix-service/ingredients`
export const picasso = `/komix-service/picasso`
export const outputDocument = `/komix-service/output-documents`
export const region = `/regions`
export const azureClientId = 'b95143ee-2d47-4070-a6e7-d487658756c3'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d' 
