import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import CosdForm from './CosdForm'
import LoadingButton from '@mui/lab/LoadingButton'
import { dispatch } from '../utils/State'

export const CosdFormDialog = (props = {}) => {  
  return (      
    <Dialog fullWidth maxWidth='sm' open={props.open} TransitionProps = {{ onEntered: () => props.onOpen() }}>
      <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Country of Sale Details</DialogTitle>
      <DialogContent>
        <CosdForm />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <LoadingButton loading={props.isLoading} onClick={props.onClose}>Cancel</LoadingButton>
        <LoadingButton loading={props.isLoading} color="primary" onClick={props.onSave} variant="contained">Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
