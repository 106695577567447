import { booleanSerializer, numberSerializer } from '../utils/Serializer'

export default class IngredientMapper {
    toFormModel(ingredient) {
        return {
            id: numberSerializer.toString(ingredient.id) ?? '',
            name: ingredient.name ?? '',
            alias: ingredient.alias ?? '',
            sCode: ingredient.sCode ?? '',
            specNumber: ingredient.specNumber ?? '',
            formOfIngredient: ingredient.formOfIngredient ?? '',
            status: ingredient.status ?? '',
            ccnaId: ingredient.ccnaId ?? '',
            sapMaterialNumber: ingredient.sapMaterialNumber ?? '',
            sapDescription: ingredient.sapDescription ?? '',
            concentrationWw: numberSerializer.toString(ingredient.concentrationWw),
            ingredientCheckTest: ingredient.ingredientCheckTest ?? '',
            refractometerBrix: numberSerializer.toString(ingredient.refractometerBrix),
            percentSolubleSolids: numberSerializer.toString(ingredient.percentSolubleSolids),
            densityGMl: numberSerializer.toString(ingredient.densityGMl),
            percentAcid: numberSerializer.toString(ingredient.percentAcid), 
            pH: numberSerializer.toString(ingredient.pH),
            pulp20Mesh: numberSerializer.toString(ingredient.pulp20Mesh),
            essencePpm: numberSerializer.toString(ingredient.essencePpm),
            sulfitePpm: numberSerializer.toString(ingredient.sulfitePpm),
            appearance: ingredient.appearance ?? '',
            odor: ingredient.odor ?? '',
            partStorageConditionAndShelfLife: ingredient.partStorageConditionAndShelfLife ?? '',
            strength: numberSerializer.toString(ingredient.strength),
            vitaminCMg100Ml: numberSerializer.toString(ingredient.vitaminCMg100Ml),
            nleaSsJuiceBrix: numberSerializer.toString(ingredient.nleaSsJuiceBrix),
            oilRecoveryRateVv: numberSerializer.toString(ingredient.oilRecoveryRateVv),
            isWater: ingredient.isWater,
            isNutritiveSweetener: ingredient.isNutritiveSweetener,

            createdBy: ingredient.createdBy ?? '',
            createdDate: ingredient.createdDate ?? '',
            lastModifiedBy: ingredient.lastModifiedBy ?? '',
            lastModifiedDate: ingredient.lastModifiedDate ?? ''
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            name: formModel.name,
            alias: formModel.alias,
            sCode: formModel.sCode,
            specNumber: formModel.specNumber,
            formOfIngredient: formModel.formOfIngredient,
            status: formModel.status,
            ccnaId: formModel.ccnaId,
            sapMaterialNumber: formModel.sapMaterialNumber,
            sapDescription: formModel.sapDescription,
            concentrationWw: numberSerializer.toObject(formModel.concentrationWw),
            ingredientCheckTest: formModel.ingredientCheckTest,
            refractometerBrix: numberSerializer.toObject(formModel.refractometerBrix),
            percentSolubleSolids: numberSerializer.toObject(formModel.percentSolubleSolids),
            densityGMl: numberSerializer.toObject(formModel.densityGMl),
            percentAcid: numberSerializer.toObject(formModel.percentAcid),
            pH: numberSerializer.toObject(formModel.pH),
            pulp20Mesh: numberSerializer.toObject(formModel.pulp20Mesh),
            essencePpm: numberSerializer.toObject(formModel.essencePpm),
            sulfitePpm: numberSerializer.toObject(formModel.sulfitePpm),
            appearance: formModel.appearance,
            odor: formModel.odor,
            partStorageConditionAndShelfLife: formModel.partStorageConditionAndShelfLife, 
            strength: numberSerializer.toObject(formModel.strength),
            vitaminCMg100Ml: numberSerializer.toObject(formModel.vitaminCMg100Ml),
            nleaSsJuiceBrix: numberSerializer.toObject(formModel.nleaSsJuiceBrix),
            oilRecoveryRateVv: numberSerializer.toObject(formModel.oilRecoveryRateVv),
            isWater: booleanSerializer.toObject(formModel.isWater),
            isNutritiveSweetener: booleanSerializer.toObject(formModel.isNutritiveSweetener),
            
            createdBy: formModel.createdBy,
            createdDate: formModel.createdDate,
            lastModifiedBy: formModel.lastModifiedBy,
            lastModifiedDate: formModel.lastModifiedDate
        }
    }


}