import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import React, { Children, useRef, useState } from 'react'

export default function Panel(props = {}) {
    const myRef = useRef(null)
    const internalProps = { expandIcon: <ExpandMoreIcon/>, ...props}

    
    const [expanded, setExpanded] = useState(props.defaultExpanded ?? true)
    // const [initialized, setInitialized] = useState(props.defaultExpanded ?? true)
    const [icon, setIcon] = useState((props.defaultExpanded ?? true) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)

    const collapse = () => {
        setIcon(<ExpandMoreIcon/>)
        setExpanded(false)
        let pos = myRef.getBoundingClientRect()
        window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
    }

    const toggle = () => {
        // setInitialized(true)
        setIcon(expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
        if(!expanded) { //about to expand....

        }
        setExpanded(!expanded)
    }

    const defaultOnExpand = () => {
        if(props.onExpand) {
            props.onExpand()
        }
    }
 
    let title = <Typography className='tis-form-title'>{internalProps.title}</Typography>

    return (
        <Card className="clickable category" sx = {{width: '100%'}}>
            <CardHeader ref={myRef} title={title} onClick={toggle} action={icon}/>
            <Collapse in={expanded} timeout="auto" unmountOnExit={false} onEntered={defaultOnExpand}>
                <CardContent className="content">
                    {props.children}
                </CardContent>
            </Collapse>
        </Card>
    )
}