import { CreateFormulaFormDialog } from './CreateFormulaFormDialog'
import ListView from '../layout/ListViewOLD.js'
import FormulaFormModel from '../model/formula/FormulaFormModel'
import { ApiGateway } from '../config/config'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListFormulas() {
  let navigate = useNavigate()
  const columnsData = [
    { accessorKey: 'id', header: 'ID', size: 50 },
    { accessorKey: 'bbn', header: 'BBN', size: 160 },
    { accessorKey: 'formulaName', header: 'Name', size: 50},
    { accessorKey: 'status', header: 'Status',filterVariant: 'multi-select',filterSelectOptions: ["", "Informational Only", "Alpha", "Draft", "Completed", "Final", "Issued"], size: 160 },
    { accessorKey: 'operatingUnit', header: 'OU', size: 50 },
    { accessorFn: (row) => row.lastModifiedBy, header: 'Last Modified By', size: 50 },
    { accessorFn: (row) => moment(row.lastModifiedDate).format('MM/DD/yyyy'), header: 'Date Last Modified', size: 50 }
]

  return (
    <ListView 
        columns = {columnsData}
        getData = { async ({ requestConfig }) => {
          const response = await axios.get(ApiGateway.formula, requestConfig)
          return { isSuccess: isSuccess(response), data: response.data?.data }
        }}

        formModel = { FormulaFormModel }
        onEditDialogOpen = {formula => { navigate(`/formulas/edit/${formula.id}`) }}
        dialog = { <CreateFormulaFormDialog />}
        itemLabelFn = {formula => `${formula.formulaName} (${formula.bbn})`}

        onDelete= { async ({item, requestConfig}) => {
          const response = await axios.delete(`${ApiGateway.formula}/${item.id}`, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}
      />
  )
}