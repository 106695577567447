import { useContext, useEffect, useState, memo } from 'react'
import { UserContext } from '../App'
import axios from '../utils/AxiosClient'
import { Box, Paper } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ApiGateway } from '../config/config'
import Panel from '../form/Panel.js'
import ListCommercializations from '../commercialization/ListCommercializations.js'
import ListFormulaIngredients from '../formulaIngredient/ListFormulaIngredients.js'
import ListCharacteristics from '../characteristics/ListCharacteristics'
import ListIngredientChecks from '../ingredientCheck/ListIngredientChecks.js'
import TextField from '../form/StatefulTextField'
import { SyrupFields, BeverageFields } from '../staticData/SyrupAndBeverageData'
import { DynaForm } from './DynaForm'
import ProductFormulaDetailForm from './ProductFormulaDetailForm'
import ProductCategoryInfoForm from './ProductCategoryInfoForm'
import FactorsAndStandardsForm from './FactorsAndStandardsForm'
import SraInfoForm from './SraInfoForm'
import AuditInfoBox from '../user/AuditInfoBox'
import DebugButton from '../utils/DebugButton'
import bus from '../utils/EventBus'
import { getState } from '../utils/State.js'



const FormulaForm = memo((props = {}) => {
  const user = useContext(UserContext)
  const [countryOptions, setCountryOptions] = useState([])

  const [showSyrup, setShowSyrup] = useState(false)
  const [showBeverage, setShowBeverage] = useState(false)

  const loadCountries = async() => {
    await axios.get(`${ApiGateway.region}/countries?sorting=country_name`, { headers: { Authorization: user.idToken }}, setCountryOptions)
  }

  const onChangeFormulaStates = (event) => {
    const formulaStates = event?.target?.value
    setShowSyrup(formulaStates?.includes("Syrup"))
    setShowBeverage(formulaStates?.includes("Beverage"))
  }

  useEffect(() => {
    loadCountries()

    bus.on('onChange_FormulaForm_formulaStates', onChangeFormulaStates)
    return (() => {
      bus.removeListener('onChange_FormulaForm_formulaStates', onChangeFormulaStates)
    })
  }, [])

  // const saveSpecifications = async() => {
  //   let specs = []
  //   let spec = {}
  //   let specId = 0
  //   for (let syrupField of SyrupFields){
  //     if(syrupSpec[syrupField.fieldId] !== undefined){
  //       spec = {
  //         "id": specId,
  //         "formula": {
  //           "id": id
  //         },
  //         "recordType": "syrup",
  //         "name": syrupField.name,
  //         "fieldId": syrupField.fieldId,
  //         "value": {
  //           "target": syrupSpec[syrupField.fieldId],
  //           "min": syrupSpec[`${syrupField.fieldId}Min`],
  //           "max": syrupSpec[`${syrupField.fieldId}Max`],
  //           "uom": syrupField.uom
  //         }
  //       }
  //       specs.push(spec)
  //       specId = specId + 1
  //     }
  //   }
  //   for (let bevField of BeverageFields){
  //     if(bevSpec[bevField.fieldId] !== undefined){
  //       spec = {
  //         "id": specId,
  //         "formula": {
  //           "id": id
  //         },
  //         "recordType": "beverage",
  //         "specificationName": bevField.name,
  //         "fieldId": bevField.fieldId,
  //         "value": {
  //           "target": bevSpec[bevField.fieldId],
  //           "min": bevSpec[`${bevField.fieldId}Min`],
  //           "max": bevSpec[`${bevField.fieldId}Max`],
  //           "uom": bevField.uom
  //         }
  //       }
  //       specs.push(spec)
  //       specId = specId + 1
  //     }
  //   }
  //   return specs
  // }

  // const saveFactorsAndStandards = async() => {
  //   let data = []
  //   for (let key in fs){
  //     data.push(fs[key])
  //   }
  //   return axios.post(`${ApiGateway.formula}/${id}/factorsAndStandards`, data, { headers: { Authorization: user.idToken } })
  // }

  return (
    <>
      
        <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto' }}>
          <TextField id="id" scope="FormulaForm" type="hidden" />
          <Grid container>
            <Grid xs={12} md={12}>
              <Panel title="Product/Formula Detail" category="FormulaDetail" hideCollapse={true} defaultExpanded={true}>
                <ProductFormulaDetailForm countryOptions={countryOptions} scope="FormulaForm" />
              </Panel>
       
              <Panel title="Commercializations" hideCollapse={true} defaultExpanded={false}>
                <Box sx={{ mt: 0, minHeight: 200 }}>
                  <ListCommercializations formulaId={props.id} />
                </Box>
              </Panel>
    
              <Panel category="ProductCategoryInformation" title="Product Category Information" hideCollapse={true} defaultExpanded={false} >
                <ProductCategoryInfoForm scope="FormulaForm"/>
              </Panel>
   
              <Panel category="InformationFromSra" title="Information From SRA" hideCollapse={true} defaultExpanded={false}>
                <SraInfoForm scope="FormulaForm" />
              </Panel>

              {showSyrup && 
                <Panel title="Syrup Specifications" hideCollapse={true} defaultExpanded={false}> {/* onExpand={() => dispatch('SpecificationsForm.syrup')}> */}
                  <Box sx={{ mt: 0, minHeight: 200 }}>
                    <DynaForm fields={SyrupFields} scope="SpecificationsForm.syrup" />
                  </Box>
                </Panel>
              }

              {showBeverage && 
                <Panel title="Beverage Specifications" hideCollapse={true} defaultExpanded={false}> {/* onExpand={() => dispatch('SpecificationsForm.beverage')}> */}
                  <Box sx={{ mt: 0, minHeight: 200 }}>
                    <DynaForm fields={BeverageFields} scope="SpecificationsForm.beverage" />
                  </Box>
                </Panel>
              }
               
              <Panel title="Factors and Standards" hideCollapse={true} defaultExpanded={false}>
                <FactorsAndStandardsForm scope="FactorsAndStandardsForm" />
              </Panel>
            
              <Panel title="Master Ingredients" hideCollapse={true} defaultExpanded={false}>
                <Box sx={{ mt: 0, minHeight: 200 }}>
                  <ListFormulaIngredients formulaId={props.id} />
                </Box>
              </Panel>

              <Panel title="Characteristics" hideCollapse={true} defaultExpanded={false}>
                <Box sx={{ mt: 0, minHeight: 200 }}>
                  <ListCharacteristics scope="CharacteristicForm" formulaId={props.id} />
                </Box>
              </Panel>
            
              <Panel title="Ingredient Check" hideCollapse={true} defaultExpanded={false}>
                <Box sx={{ mt: 0, minHeight: 200 }}>
                  <ListIngredientChecks scope="IngredientCheckForm" formulaId={props.id} />
                </Box>
              </Panel> 
            </Grid>
            <AuditInfoBox item={getState('FormulaForm')} />
            <DebugButton name="Formula" log={() => getState('FormulaForm')}/>
          </Grid>
        </Paper>
    </>
  )
})

export default FormulaForm



{/* 
<Category title="Syrup Targets & HFCS Inputs" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={SyrupFields} category="SyrupInputs" formula={syrupSpec} props.onChangeItem={setSyrupSpec}  units={units} unitsType={props.unitsType} />
  </Box>
</Category>
<Category title="Syrup Target & HFCS Calculations" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={SyrupFields} category="SyrupCalculations" formula={syrupSpec} props.onChangeItem={setSyrupSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category>
<Category title="Syrup Brix Related" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={SyrupFields} category="SyrupBrixRelated" formula={syrupSpec} props.onChangeItem={setSyrupSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category>
<Category title="Syrup Specifications" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={SyrupFields} category="SyrupSpecifications" formula={syrupSpec} props.onChangeItem={setSyrupSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category>
<Category title="Picasso HFSS Syrup Attributes" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={SyrupFields} category="SyrupHFSS" formula={syrupSpec} props.onChangeItem={setSyrupSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category> 
<Category title="Beverage Targets & HFCS Inputs" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={BeverageFields} category="BeverageInputs" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category>
<Category title="Beverage Brix Related" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={BeverageFields} category="BeverageBrixRelated" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType} />
  </Box>
</Category>
<Category category="BeverageSpecifications" title="Beverage Specifications" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}><Grid xs={12} md={6}>
      <TextField className='tis-form-field'
        id="bestBefore"
        defaultValue={props.item?.bestBefore}
        onBlur={(event) => handleTextChange(event, props.item, props.onChangeItem)}
        label="Best Before"
        // type="date"
        fullWidth
      />
    </Grid></Grid>
    <CreateFields fields={BeverageFields} category="BeverageSpecifications" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType} />
  </Box>
</Category>
<Category category="BeverageTarget" title="Beverage Target" hideCollapse={true} defaultExpanded={false}>
<Box sx={{ mt: 0, minHeight: 200 }}>
  <CreateFields fields={BeverageFields} category="BeverageTarget" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType} />
</Box>
</Category>
<Category category="BeverageRelated" title="Beverage Related" hideCollapse={true} defaultExpanded={false}>
<Box sx={{ mt: 0, minHeight: 200 }}>
  <CreateFields fields={BeverageFields} category="BeverageRelated" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType} />
</Box>
</Category>
<Category category="BeverageHFSS" title="Picasso HFSS Beverage Attributes" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={BeverageFields} category="BeverageHFSS" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType} />
  </Box>
</Category>
<Category category="BeveragePrep" title="Beverage Prep Related" hideCollapse={true} defaultExpanded={false}>
  <Box sx={{ mt: 0, minHeight: 200 }}>
    <CreateFields fields={BeverageFields} category="BeveragePrep" formula={bevSpec} props.onChangeItem={setBevSpec}  units={units} unitsType={props.unitsType}/>
  </Box>
</Category>
*/}

{/* <Category category="BlendingType" title="Blending Type" hideCollapse={true} defaultExpanded={false}>
      <Box sx={{ mt: 0, minHeight: 200 }}>
      </Box>
    </Category> */}

    {/* <Category category="PartsInfo" title="Parts Info" hideCollapse={true} defaultExpanded={false}>
      <Box sx={{ mt: 0, minHeight: 200 }}>
      </Box>
    </Category> */}
{/* 
    <Category category="Checklists" title="Checklists" hideCollapse={true} defaultExpanded={false}>
      <Box sx={{ mt: 0, minHeight: 200 }}>
      </Box>
    </Category> */}
  {/* <Category category="NotesAndAttachments" title="Notes & Attachments" hideCollapse={true} defaultExpanded={false}>
    <Box sx={{ mt: 0, minHeight: 200 }}>
    </Box>
  </Category> */}