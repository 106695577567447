import React, { useEffect, useRef, useState } from 'react'
import { TextField as MuiTextField, InputAdornment, Tooltip } from '@mui/material'
import ScaleIcon from '@mui/icons-material/Scale'
import _convert from 'convert-units'
import units from '../utils/units'
import DecimalFormat from '@sei-atl/decimal-format'
import bus from '../utils/EventBus'
import { getState, setState } from '../utils/State'
import { useIsMounted, isEmpty } from '../utils/ReactUtils'

/**
 * Stateful TextField component
 * 
 * @returns 
 */
export default function StatefulTextField(props = {}) {
    const ref = useRef()
    const [shrink, setShrink] = useState()
    const [sx, setSx] = useState({})
    const [inputProps, setInputProps] = useState({})
    const isMounted = useIsMounted()

    const defaultGetStyles = (value) => {
        const hasValue = !isEmpty(value)
        return {
            display: type === 'hidden' ? 'none' : '',
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': '',
            },
            "& .MuiInputBase-root .MuiInputBase-input": {
                color: 'rgba(0, 0, 0, 0.9)'
            }
        }
    }

    let {
        onError,// = props.context?.onError,
        fieldErrors,// = props.context?.fieldErrors,
        onBlur,
        fullWidth = true,
        className = 'tis-form-field',
        scope,
        getStyles = defaultGetStyles,
        // unitsType,
        id, defaultValue, label, onChange, onFocus, type, validator, format, InputProps, InputLabelProps, ...otherProps
    } = props

    const owner = scope ? `${scope}.${id}` : id
    const baseEventName = scope ? `onChange_${scope}` : 'onChange'
    const df = new DecimalFormat(format)

    const defaultOnBlur = (event) => {
        
        if(isMeasurement()) {
            let unitsType = getState('unitsType')
            // console.log('firing onChangeMeasurement: unitsType: ', unitsType)
            _onChangeMeasurement(event, unitsType)
            // ref.current[`${unitsType}Value`] = event.target.value ?? ''
            // if(unitsType !== 'metric') {
            //     if(type === 'density') {
            //         let metricWeight = convert(ref.current[`${unitsType}Value`], units[unitsType]?.weight?.uom, units.metric.weight.uom)
            //         ref.current.metricValue = convert(metricWeight, units.metric.volume.uom, units[unitsType]?.volume?.uom)
            //     } else {
            //         ref.current.metricValue = convert(ref.current[`${unitsType}Value`], units[unitsType]?.[type]?.uom, units.metric[type]?.uom)
            //     }
            // }
            setState(id, ref.current.metricValue, { owner, scope })
        } else {
            // console.log('event: ', event)
            ref.current.rawValue = event.target.value ?? ''
            ref.current.value = format ? formatValue(ref.current.rawValue) : ref.current.rawValue
            setState(id, ref.current.rawValue, { owner, scope })
        }
        // console.log(`StatefulTextField.defaultOnBlur ${owner}, value set to: `, getRefValues(ref))
        setSx(getStyles(ref.current.value))
        setShrink(!isEmpty(ref.current.value))
        if(onBlur) {
            onBlur(event)
        }
    }

    const defaultOnFocus = (event) => {
        let unitsType = getState('unitsType') ?? 'metric'
        ref.current.value = isMeasurement() ? ref.current[`${unitsType}Value`] : ref.current.rawValue
        setShrink(true)
    }
    
    const formatValue = (value) => {
        // let formattedValue = df.format(value)
        // console.log('format = ', format)
        // console.log('formattedValue = ', formattedValue)
        // console.log('value = ', value)
        // console.log('is value empty? ', isEmpty(value))

        return isEmpty(value) ? '' : df.format(value)
    }

    const isInitialized = () => {
        return !isEmpty(ref.current.metricValue)
    }

    const _onChangeMeasurement = (event, unitsType = 'metric') => {
        ref.current[`${unitsType}Value`] = event.target.value ?? ''
        Object.keys(units).forEach(targetUnit => {
            // console.log('targetUnit: ', targetUnit)
            if(targetUnit !== unitsType) {

                //no need to convert if the unit you're working in is the same as the target unit
                // ref.current[`${unitsType}Value`] = ref.current.rawValue
                // console.log(`since the target unit (${targetUnit}) is different than the current unit (${unitsType}), convert...`)
                if(type === 'density') {
                    // console.log(`units[unitsType]?.weight.uom: `, units[unitsType]?.weight.uom)
                    // console.log(`units[targetUnit]?.weight?.uom: `, units[targetUnit]?.weight?.uom)
                    // console.log(`units[targetUnit]?.volume?.uom: `, units[targetUnit]?.volume?.uom)
                    // console.log(`units[${unitsType}]?.volume.uom: `, units[unitsType]?.volume.uom)
                    let weight = convert(ref.current[`${unitsType}Value`], units[unitsType]?.weight.uom, units[targetUnit]?.weight?.uom)
                    ref.current[`${targetUnit}Value`] = convert(weight, units[targetUnit]?.volume?.uom, units[unitsType]?.volume.uom)
                } else {
                    ref.current[`${targetUnit}Value`] = convert(ref.current[`${unitsType}Value`], units[unitsType]?.[type]?.uom, units[targetUnit]?.[type]?.uom)
                }    
            }
        })
        ref.current.value = format ? formatValue(ref.current[`${unitsType}Value`]) : ref.current[`${unitsType}Value`]
        // console.log(`StatefulTextField.defaultOnChange ${owner}, value set to: `, getRefValues(ref))
        setSx(getStyles(ref.current.value))
        setShrink(!isEmpty(ref.current.value))

    }

    //External onChange always deals in metric
    const defaultOnChange = (event) => { 
        if(event.owner !== owner) {
            // console.log(`StatefulTextField.defaultOnChange ${owner}, incoming event: `, event?.target?.value)
            if(isMeasurement()) {
                let unitsType = getState('unitsType') ?? 'metric'
                if(!isInitialized()) {
                    // console.log(`Initializing ${owner}`)
                    _onChangeMeasurement(event, 'metric')
                    if(unitsType !== 'metric') {
                        onChangeUnits({target: {value: unitsType}})
                    }
                } else {
                    _onChangeMeasurement(event, unitsType)
                }
            } else {
                ref.current.rawValue = event.target.value ?? ''
                ref.current.value = format ? formatValue(ref.current.rawValue) : ref.current.rawValue
                // console.log(`StatefulTextField.defaultOnChange ${owner}, value set to: `, getRefValues(ref))
                setSx(getStyles(ref.current.value))
                setShrink(!isEmpty(ref.current.value))
            }  
            if(onChange) {
                onChange(event)
            }  
        }
    }

    const onChangeUnits = event => {
        // console.log(`StatefulTextField.onChangeUnits ${owner}, incoming event: `, event)
        let unitsType = event?.target?.value
        let unit = units[unitsType]?.[type]
        if(!ref.current?.hasOwnProperty(`${unitsType}Value`)) {
            if(type === 'density') {
                // console.log(`units.metric.weight.uom: `, units.metric.weight.uom)
                // console.log(`units[unitsType]?.weight?.uom: `, units[unitsType]?.weight?.uom)
                // console.log(`units[unitsType]?.volume?.uom: `, units[unitsType]?.volume?.uom)
                // console.log(`units.metric.volume.uom: `, units.metric.volume.uom)
                let weight = convert(ref.current.metricValue, units.metric.weight.uom, units[unitsType]?.weight?.uom)
                ref.current[`${unitsType}Value`] = convert(weight, units[unitsType]?.volume?.uom, units.metric.volume.uom)
            } else {
                ref.current[`${unitsType}Value`] = convert(ref.current.metricValue, units.metric[type]?.uom, unit?.uom)
            }
        }
        setAdornment(unit)
        ref.current.value = format ? formatValue(ref.current[`${unitsType}Value`]) : ref.current[`${unitsType}Value`]
        // console.log(`StatefulTextField.onChangeUnits ${owner}, value set to: `, getRefValues(ref))
    }

    const convert = (value, fromUom, toUom) => {
        if(isEmpty(value)) {
            return ''
        } else {
            // console.log(`${owner} convert, value: `, value, 'fromUom: ', fromUom, 'toUom: ', toUom)
            try {
                value = parseFloat(value)
            } catch (e) {
                //ok
            }
            return (Math.round(_convert(value).from(fromUom).to(toUom) * 1000000000000) / 1000000000000)
        }
    }

    const setAdornment = (unit) => {
        if(unit?.label) {
            setInputProps({
                ...InputProps, 
                endAdornment: <InputAdornment position="end">
                        {/* <Tooltip arrow placement="top-start" title={unit?.title} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -7]} }] } }}> */}
                            {unit.label}&nbsp;&nbsp;<ScaleIcon fontSize="small"/>
                        {/* </Tooltip> */}
                    </InputAdornment>})
        }
    }

    useEffect(() => {
        if(isMounted) {
            let unitsType = getState('unitsType') ?? 'metric'
            let initialValue = defaultValue ?? getState(owner)
            if(initialValue) {
                defaultOnChange({target: {value: initialValue}})
            } else {
                defaultOnChange({target: {value: ''}})
            }
            
            // ref.current.rawValue = defaultValue ?? getState(owner) ?? ''
            if(isMeasurement()) {
                // ref.current.metricValue = ref.current.rawValue
                // let unitsType = getState('unitsType')
                // let unit = units?.[unitsType]?.[type]
                // let metricUom = units?.metric?.[type]?.uom
                // if(unitsType !== 'metric') {
                //     if(!ref.current?.hasOwnProperty(`${unitsType}Value`)) {
                //         ref.current[`${unitsType}Value`] = convert(ref.current.metricValue, metricUom, unit?.uom)
                //     }
                //     ref.current.rawValue = ref.current[`${unitsType}Value`]
                // }
                setAdornment(units?.[unitsType]?.[type])
                bus.on(`onChange_unitsType`, onChangeUnits)      
            } else {
                setInputProps(InputProps ?? {})
            }
            
            
            setSx(getStyles(ref?.current?.value))
            setShrink(!isEmpty(ref?.current?.value))
            bus.on(`${baseEventName}_${id}`, defaultOnChange)
        }
        return () => {
            if(isMeasurement()) {
                bus.removeListener('onChange_unitsType', onChangeUnits)
            }
            bus.removeListener(`${baseEventName}_${id}`, defaultOnChange)
        }
    }, [])



    const isMeasurement = () => {
        const t = type?.toLowerCase()
        return t === 'temperature' || t === 'weight' || t === 'volume' || t === 'density'    
    }
    
    let inputLabelProps = { shrink, ...InputLabelProps }


    let unitsType = getState('unitsType')

    return (
        <>
        <MuiTextField 
            className = { className }
            id = { id }
            type = 'text'
            fullWidth = { fullWidth === undefined ? true : fullWidth }
            label = { label }
            InputLabelProps = { inputLabelProps }
            defaultValue = { defaultValue ?? getState(id, scope) ?? '' }
            inputRef = { ref }
            onFocus = { defaultOnFocus }
            onBlur = { defaultOnBlur }
            error = { !!fieldErrors?.[id] }
            helperText = { fieldErrors?.[id] ? "This field is required" : false }  //@TODO: make the error message dynamic
            InputProps = { inputProps }
            sx = { sx }
            { ...otherProps }
        />
        {/* {isMeasurement() && unitsType &&
        <>
        <br/><span>ref.current.rawValue: {ref.current?.rawValue}</span><br/>
        <span>ref.current.metricValue: {ref.current?.metricValue}</span><br/>
        <span>ref.current.imperialValue: {ref.current?.imperialValue}</span><br/>
        <span>current measurement unit: {unitsType}</span>
        
        </>} */}
        </>
    )
}