import React, { useEffect, useReducer, useState } from 'react'
import { FormGroup, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material'
import bus from '../utils/EventBus'
import { getState, setState } from '../utils/State'
import { useIsMounted } from '../utils/ReactUtils'
/**
 * Uncontrolled Checkbox component
 * 
 * 
 * 
 * @param {} props 
  *      - refs: Pass in the props.refs object to attach to the DOM. The props.refs object should have a key -> ref pair for each field in the form.
 *
 * @returns 
 */

export default function StatefulCheckbox(props = {}) {
    const [sx, setSx] = useState({})
    const [, forceUpdate] = useReducer(x => x + 1, 0)
    const isMounted = useIsMounted()

    // const defaultOnBlur = () => {
    //     let value = getValue(refs?.[id])
    //     if(onError) {
    //         onError(value, id, validator)
    //     }
    //     setStyles(value)
    //     if(onBlur) {
    //         onBlur(event)
    //     }
    // }

    function defaultOnChange (event) {
        let owner = scope ? `${scope}.${id}` : id
        if(event.owner !== owner) {
            // console.log('defaultOnChange fired, event: ', event)
            let value = event.target?.checked ?? event.target?.value
            setState(id, value, {owner, scope, bubble: event.bubble ?? true})
            setChecked(value)
            if(onChange) {
                onChange(event)
            }
        }
    }

    let {
        onError,// = props.context?.onError,
        fieldErrors,// = props.context?.fieldErrors,
        unitsType = props.context?.unitsType,
        onBlur,
        onChange,
        fullWidth = true,
        className = 'tis-form-field',
        id, defaultValue, label, onFocus, validator, format, scope, type, InputProps, ...otherProps} = props
    
    const [checked, setChecked] = useState(defaultValue ?? getState(id, scope))

    useEffect(() => {
        let baseEventName = scope ? `onChange_${scope}` : `onChange`
        let value = getState(id, scope)

        if(isMounted) {
            if(defaultValue) {
                setState(id, defaultValue ?? '', {scope, bubble: false})
                forceUpdate()
            } else {
                console.log(`${id}, value: `, value)
                setChecked(value)
            }
            bus.on(`${baseEventName}_${id}`, defaultOnChange)
        }
        
        setStyles(value)
        return () => {
            bus.removeListener(`${baseEventName}_${id}`, defaultOnChange)
        }
    }, [])

    const setStyles = (value) => {
        const hasValue = value !== undefined && value !== null && value !== ''
        setSx({
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            "& .MuiInputBase-root": {
                // fontWeight: hasValue ? 700 : 400
            }
        })
    }
   
    return (
        <>
            <FormGroup id={id}>
                <FormControlLabel label={label} control={
                    <MuiCheckbox 
                        id={id}
                        className={className}
                        checked={checked} 
                        onChange={defaultOnChange}
                        sx={sx}
                        {...otherProps}
                    />
                    }
                />
            </FormGroup>
        </>
    )
}