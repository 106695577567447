import { memo } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import FormulaIngredientForm from './FormulaIngredientForm'
import LoadingButton from '@mui/lab/LoadingButton'
import LoadingIndicator from '../layout/LoadingIndicator'

export const FormulaIngredientFormDialog = memo((props = {}) => {  
  return (
    <Dialog sx={{ maxHeight: 'calc(100% - 50px)', top: '14px' }} maxWidth='lg' open={props.open} TransitionProps = {{ onEntered: () => props.onOpen() }}>
      <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Formula Ingredient</DialogTitle> 
      <DialogContent>
        <FormulaIngredientForm {...props}/>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <LoadingButton loading={props.isLoading} onClick={props.onClose}>Cancel</LoadingButton>
        <LoadingButton loading={props.isLoading} color="primary" onClick={props.onSave} variant="contained">Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
})