import { numberSerializer } from '../utils/Serializer'

export default class CosdMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            commercialization: item.commercialization?.id ?? '',
            countryOfSale: item.countryOfSale?.id ?? '',
            productName: item.productName ?? '',
            carbonationVolume: numberSerializer.toString(item.carbonationVolume)
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            commercialization: {id: numberSerializer.toObject(formModel.commercialization)},
            countryOfSale: {id: numberSerializer.toObject(formModel.countryOfSale)},
            productName: formModel.productName,
            carbonationVolume: numberSerializer.toObject(formModel.carbonationVolume)
        }
    }
}