import CharacteristicMapper from '../mapper/CharacteristicMapper'
import { CharacteristicFormDialog } from './CharacteristicFormDialog'
import ListView from '../layout/ListView'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListCharacteristics(props = {}) {
    const columns = [
      // {
      //   accessorKey: 'packageType', header: 'Package Type', filterVariant: 'multi-select', filterSelectOptions: ["PET", "Can", "Glass", "Can & Glass", "Other"], size: 160, editable: false, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell)
      //   })
      // },
      { accessorKey: 'packageType', header: 'Package Type', size: 100 },
      { accessorKey: 'carbonation.target', header: 'Carbonation', size: 100 },
      { accessorKey: 'nitrogenPsi', header: 'Nitrogen (psi)', size: 100 },
    ]

    return (     
        <ListView 
          // addItem = {{ formula: { id: props.context?.item?.id }}}

          columns = {columns}
          itemLabelProperty = "packageType"
          scope = {props.scope }
          dataMapper = { CharacteristicMapper }
          getData = { async ({ requestConfig }) => {
            const response = await axios.get(`${ApiGateway.formula}/${props.formulaId}/characteristics`, requestConfig)
            return { isSuccess: isSuccess(response), data: response.data?.data }
          }}
          onSave = { async ({item, requestConfig}) => {
            item.formula = { id: props.formulaId }
            if(item.packageType?.length > 0) {
              const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/characteristics`, item, requestConfig)
              return { isSuccess: isSuccess(response) }
            } else {
              return { isSuccess: false}
            }
          }}
          onDelete= { async ({item, requestConfig}) => {
            const response = await axios.delete(`${ApiGateway.formula}/${props.formulaId}/characteristics/${item.id}`, requestConfig)
            return { isSuccess: isSuccess(response) }
          }}
  
          dialog = { <CharacteristicFormDialog /> }
        />
    )
}