import { useContext, useState } from 'react'
import bus from '../utils/EventBus'
import Event from '../utils/EventNames'
import { handleFieldChange } from '../form/Form'
import { Box, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, FormControlLabel, InputAdornment, InputLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import InlineMessage from '../ui/InlineMessage'
import { checkRequiredError } from '../form/Form'
import { ApiGateway } from '../config/config'
import { UserContext } from '../App'
import axios, { isSuccess } from '../utils/AxiosClient'
import { useNavigate } from 'react-router-dom'
import LoadingIndicator from '../layout/LoadingIndicator'
import LoadingButton from '@mui/lab/LoadingButton'

export function CreateFormulaFormDialog(props = {}) {
    // console.log('IngredientFormDialog props: ', props)

    const [errors, setErrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const user = useContext(UserContext)
    let navigate = useNavigate()

    const initialFormulaState = {
        bbn: '',
        specNumber: '',
        operatingUnit: '',
        importFromPicasso: ''
    }
    const [form, setForm] = useState(initialFormulaState)
    const [formula, setFormula] = useState()
    const [formulas, setFormulas] = useState()
    const [specNumberOptions, setSpecNumberOptions] = useState()

    const getByBbnInPicasso = async (bbn) => {
        if (bbn?.trim().length) {
            setIsLoading(true)
            setSpecNumberOptions(undefined)

            let response = await axios.get(`${ApiGateway.picasso}/formulas/bbn/${encodeURIComponent(bbn)}`, { headers: { Authorization: user.idToken } })
            if (isSuccess(response)) {
                const result = response.data
                if (result?.data?.length) {
                    if (result.data.length > 1) {
                        //multiple versions of the formula were found
                        const formulas = result.data
                        const options = formulas.map(formula => {
                            return <MenuItem value={formula.specNumber}>{formula.specNumber} {formula.picassoStatus?.length ? `(${formula.picassoStatus})` : ''}</MenuItem>
                        })
                        setSpecNumberOptions(options)
                    } else {
                        setForm({ ...form, importFromPicasso: 'import' })
                        //1 version of the formula was found
                        const formula = result.data[0]
                        setFormula(formula)
                    }
                    setFormulas(result.data)
                } else {
                    //formula was not found
                    setFormula(form)
                    bus.emit(Event.ALERT, { text: `"${bbn}" was not found in Picasso...`, style: "warn", duration: 5000 })
                }
            }
            setIsLoading(false)
        }
    }

    const onCreate = async (event) => {
        let url = form.importFromPicasso === 'import' ? `${ApiGateway.formula}/ou/${encodeURIComponent(form.operatingUnit)}/bbn/${encodeURIComponent(form.bbn)}/spec-number/${encodeURIComponent(formula.specNumber)}/import` : ApiGateway.formula
        let response
        try {
            setIsSaving(true)
            response = await axios.post(url, formula, { headers: { Authorization: user.idToken } })
            setIsSaving(false)
            if (isSuccess(response)) {
                const result = response.data
                onClose()
                navigate(`/formulas/edit/${result?.insertId}`)

                //todo support page navigation with system message
                bus.emit(Event.ALERT, { text: `Formula ${form.importFromPicasso === 'import' ? 'imported' : 'created'} successfully!`, style: "success", duration: 5000 })
            } else {
                throw Error(response)
            }
        } catch (e) {
            bus.emit(Event.ALERT, { text: `There was an issue ${form.importFromPicasso === 'import' ? 'importing' : 'creating'} the formula... Please try again later.`, style: "error" })
        }
        setIsSaving(false)
    }

    // const onSave = async () => {
    //   setIsSaving(true)
    //   console.log('FormulaFormDialog.onSave, current state: ', props.item)
    //   if(props.item?.formula?.id) {
    //     let response = await axios.post(`${ApiGateway.formula}/${props.item.formula.id}`, props.item, { headers: { Authorization: user.idToken } })
    //     console.log('FormulaFormDialog.onSave, response: ', response)
    //     if(isSuccess(response)) {
    //       props.onClose()
    //       if(props.mode === 'add') {
    //         bus.emit(Event.ALERT, { text: 'Added Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
    //       } else {
    //         bus.emit(Event.ALERT, { text: 'Saved Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
    //       }
    //     } else {
    //       const text = 'There was an issue saving the formula.  Please try again later.'
    //       bus.emit(Event.ALERT, { text: text, style: 'error' })
    //     }
    //     setIsSaving(false)
    //     if(props.onSave) {
    //       props.onSave()
    //     }
    //   }
    // }
    const onChange = (event) => {
        let { name, value } = event.target
        handleFieldChange(name, value, form, setForm, () => { return value !== form[name] })
        if (name === 'specNumber' && value?.length) {
            setForm({ ...form, importFromPicasso: 'import' })
            setFormula(formulas.find(f => f.specNumber === form.specNumber))
        }
    }


    const onClose = () => {
        setErrors({})
        setForm(initialFormulaState)
        setFormulas(undefined)
        setSpecNumberOptions(undefined)
        props.onClose()
    }

    const setError = (value, id, errorChecker = checkRequiredError, errorObject = errors, errorSetter = setErrors) => {
        let error = errorChecker(value)
        errorObject[id] = error
        errorSetter({ ...errorObject }, { id: error })
    }

    const title = <DialogTitle textAlign="center">Add Formula</DialogTitle>

    return (
        <>{props.item &&
            <Dialog fullWidth maxWidth='sm' scroll='paper' open={props.open}>
                {title}
                <DialogContent>
                    <Box sx={{ mt: 0, minHeight: 200 }}>
                        <InlineMessage>When adding a formula, you can either import from Picasso or create from scratch.  If you'd like to import from Picasso, click on the search icon after supplying the bbn and follow the prompts.</InlineMessage>
                        <FormControl fullWidth className='tis-form-field'>
                            <InputLabel id="operatingUnit-label">Operating Unit</InputLabel>
                            <Select
                                id="operatingUnit"
                                name="operatingUnit"
                                labelId="operatingUnit-label"
                                label="Operating Unit"
                                value={form.operatingUnit}
                                onChange={onChange}
                            >
                                <MenuItem value="Africa OU">Africa</MenuItem>
                                <MenuItem value="Asean &amp; South Pacific OU">Asean &amp; South Pacific</MenuItem>
                                <MenuItem value="Eurasia &amp; Middle East OU">Eurasia &amp; Middle East</MenuItem>
                                <MenuItem value="Europe OU">Europe</MenuItem>
                                <MenuItem value="Greater China &amp; Mongolia OU">Greater China &amp; Mongolia</MenuItem>
                                <MenuItem value="India &amp; Southwest Asia OU">India &amp; Southwest Asia</MenuItem>
                                <MenuItem value="Japan &amp; South Korea OU">Japan &amp; South Korea</MenuItem>
                                <MenuItem value="Latin America OU">Latin America</MenuItem>
                                <MenuItem value="North America OU">North America</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField className='tis-form-field'
                            id="bbn"
                            name="bbn"
                            defaultValue={form.bbn}
                            onBlur={onChange}
                            // onChange={(event) => handleTextChange(event, formula, setFormula, setDirty)}
                            // onBlur={()=>setError(formula.bbn, "bbn")}
                            label="BBN/L-Number"
                            // error={!!fieldErrors.bbn}
                            helperText={<span>Click the <SearchIcon color="primary" sx={{ fontSize: "12px", backgroundColor: '#EFEFEF' }} /> icon to search Picasso</span>}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton edge="end" color="primary" sx={{ backgroundColor: '#EFEFEF' }} onClick={() => { getByBbnInPicasso(form.bbn) }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {formulas && 
                            <>
                                {specNumberOptions &&
                                    <>
                                        <InlineMessage>"{formulas[0]?.formulaName}" was found in Picasso, but with multiple specifications.  Which specification would you like to use?</InlineMessage>
                                        <FormControl fullWidth className='tis-form-field'>
                                            <InputLabel id="ou-label">Picasso Specification Number</InputLabel>
                                            <Select
                                                id="specNumber"
                                                name="specNumber"
                                                labelId="ou-label"
                                                label="specNumber"
                                                value={form.specNumber}
                                                onChange={onChange}
                                            >
                                                {specNumberOptions}
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                                {!specNumberOptions &&
                                    <InlineMessage type="success">"{formulas[0]?.formulaName}" was found in Picasso.</InlineMessage>
                                }
                                {(!specNumberOptions || form.specNumber) &&
                                    <>
                                        <Typography sx={{ fontSize: '14px', marginBottom: '1em' }}>Would you like to import the Picasso formula or create a new (blank) record?</Typography>
                                        <FormControl>
                                            <RadioGroup row name="importFromPicasso" value={form.importFromPicasso} onChange={onChange}>
                                                <FormControlLabel value="import" control={<Radio />} label="Import from Picasso" />
                                                <FormControlLabel value="create" control={<Radio />} label="Create New" />
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                                }
                            </>
                        }
                        <LoadingIndicator isLoading={isLoading || isSaving} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <LoadingButton onClick={onClose}>Cancel</LoadingButton>
                    <LoadingButton loading={isSaving} color="primary" disabled={!formula} onClick={onCreate} variant="contained">{formula && form.importFromPicasso === 'import' ? 'Import' : 'Create'}</LoadingButton>
                </DialogActions>
            </Dialog>             
        }
        </>
    )
}