import { Box, MenuItem, Paper } from '@mui/material'
import Select from '../form/StatefulSelect'
import TextField from '../form/StatefulTextField'
import Checkbox from '../form/StatefulCheckbox'
// import LoadingButton from '@mui/lab/LoadingButton';
import IngredientFormModel from '../model/ingredient/IngredientFormModel'
import Grid from '@mui/material/Unstable_Grid2'
import DebugButton from '../utils/DebugButton'

import AuditInfoBox from '../user/AuditInfoBox'
import { getState } from '../utils/State'
const IngredientForm = (props = {}) => {

    // const getIngredient = async() => {
    //   console.log("getIngredient")
    //   let response = await axios.get(`${ApiGateway.ingredient}/${id}`, { headers: { Authorization: user.idToken } })

    //   if (isSuccess(response)) {
    //     let data = response.data.data
    //     console.log('IngredientForm.getIngredient, response: ', response)  
  
    //     data = Object.keys(data).reduce((data, key) => {
    //       data[key] = data[key] ?? ''
    //       return data
    //     }, data)
  
    //     let state = data
    //     let newState = {...ingredient, ...state}
    //     setIngredient(newState)

    //     setLoading(false)
    //     if(props.stopLoading) { props.stopLoading() }
    //   }
    //   else {
    //     console.log(response)
    //     setLoading(false)
    //     if(props.stopLoading) { props.stopLoading() }
    //   }
    // }

    // const saveForm = async() => {
    //   console.log("save form")
    //   if(props.startLoading) { props.startLoading() }

    //   if (currentUnitsType !==  "metric"){
    //     console.log("converting to metric before save")
    //     convertAllUnits("metric", volumeFields, weightFields, densityFields, temperatureFields, ingredient, setIngredient)
    //   }
    //   let errors=[]
    //   // check errors
    //   if (checkStrictRequiredError(ingredient?.name)) {
    //     errors.push("Valid Ingredient Name is required")
    //     setError(ingredient?.name, "name", checkStrictRequiredError)
    //   }
    //   //TODO check errors

    //   // raise alerts
    //   if (errors.length > 0) {
    //     console.log(errors.join("\n"))
    //     console.log("Errors ", errors.join("\n"))
    //     bus.emit(Event.ALERT, { "text": errors.join("\n"), "style": "warn" })
    //     if (props.stopLoading) { props.stopLoading() }
    //     return ("Fix errors")
    //   }

      //save data
      // let ing = {
      //   "id": id,
      //   "name": ingredient?.name,
      //   "alias": ingredient?.alias,
      //   "sCode": ingredient?.sCode,
      //   "specNumber": ingredient?.specNumber,
      //   "formOfIngredient": ingredient?.formOfIngredient,
      //   "status": ingredient?.status,
      //   "ccnaId": ingredient?.ccnaId,
      //   "sapMaterialNumber": ingredient?.sapMaterialNumber,
      //   "sapDescription": ingredient?.sapDescription,
      //   "concentrationWw": ingredient?.concentrationWw,
      //   "ingredientCheckTest": ingredient?.ingredientCheckTest,
      //   "refractometerBrix": ingredient?.refractometerBrix,
      //   "percentSolubleSolids": ingredient?.percentSolubleSolids,
      //   "densityGMl": ingredient?.densityGMl,
      //   "percentAcid": ingredient?.percentAcid,
      //   "pH": ingredient?.pH,
      //   "pulp20Mesh": ingredient?.pulp20Mesh,
      //   "essencePpm": ingredient?.essencePpm,
      //   "sulfitePpm": ingredient?.sulfitePpm,
      //   "appearance": ingredient?.appearance,
      //   "odor": ingredient?.odor,
      //   "partStorageConditionAndShelfLife": ingredient?.partStorageConditionAndShelfLife,
      //   "strength": ingredient?.strength,
      //   "vitaminCMg100Ml": ingredient?.vitaminCMg100Ml,
      //   "nleaSsJuiceBrix": ingredient?.nleaSsJuiceBrix,
      //   "oilRecoveryRateVv": ingredient?.oilRecoveryRateVv,
      //   "isWater": ingredient?.isWater,
      //   "isNutritiveSweetener": ingredient?.isNutritiveSweetener,
      //   // service adds lastModifiedBy and lastModifiedDate
      // }
      // console.log("saveForm, ing: ", ing)
      
      //call service
    //   let response = await axios.post(ApiGateway.ingredient, ing, { headers: { Authorization: user.idToken } })
    //   console.log('response: ', response)
    //   if (currentUnitsType !==  "metric"){
    //     console.log(`converting back to ${currentUnitsType} after save`)
    //     convertAllUnits(currentUnitsType, volumeFields, weightFields, densityFields, temperatureFields, ingredient, setIngredient)
    //   }

    //   if (response.status === 200 && !response.data.errorMessage) {
    //     bus.emit(Event.ALERT, { "text": "Saved Successfully", "style": "success", "duration": 2000 })
    //     if (props.stopLoading) { props.stopLoading() }
    //     setClean()
    //     return response
    //   } else {
    //     let text = "Not Saved\n" + response.statusText
    //     bus.emit(Event.ALERT, { "text": text, "style": "error" })
    //     if (props.stopLoading) { props.stopLoading() }
    //     return response
    //   }
    // }

   
    

    // if (currentUnitsType !== unitsType) {
    //   setCurrentUnitsType(convertAllUnits(unitsType, volumeFields, weightFields, densityFields, temperatureFields, ingredient, setIngredient))
    // }

  return (
    <Paper sx={{  p: '2em',   margin: '0 auto 1em'}}>
      <Grid container>
        <Grid xs={12} md={12}>
          <Box sx={{ mt: 0, minHeight: 200}}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
              <Grid xs={12} md={6}>
                <TextField id="id" scope="IngredientForm" type="hidden" />
                <TextField id="name" scope="IngredientForm" label="Name" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="alias" scope="IngredientForm" label="Alias" />            
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="sCode" scope="IngredientForm" label="S-Code" />            
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="specNumber" scope="IngredientForm" label="Spec Number" />
              </Grid>
              <Grid xs={12} md={6}>
                <Select id="formOfIngredient" scope="IngredientForm" label="Form of Ingredient" required>
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Powder">Powder</MenuItem>
                  <MenuItem value="Liquid">Liquid</MenuItem>
                  <MenuItem value="Leaves">Leaves</MenuItem>
                </Select>
              </Grid>
              <Grid xs={12} md={6}>
                <Select id="status" scope="IngredientForm" label="Status">
                  <MenuItem value="">‎</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="ccnaId" scope="IngredientForm" label="CCNA ID" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="sapMaterialNumber" scope="IngredientForm" label="SAP Material Number" />
              </Grid>
              <Grid xs={12} md={12}>
                <TextField id="sapDescription" scope="IngredientForm" label="SAP Description" multiline rows={2} />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="concentrationWw" scope="IngredientForm" label="Concentration" type="density" /> {/* %w/w */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="ingredientCheckTest" scope="IngredientForm" label="Ingredient Check Test" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="refractometerBrix" scope="IngredientForm" label="Refractometer Brix" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="percentSolubleSolids" scope="IngredientForm" label="Soluble Solids" type="number" /> {/* % */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="densityGMl" scope="IngredientForm" label="Density" type="density" /> {/* g/mL */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="percentAcid" scope="IngredientForm" label="Acid" type="number" /> {/* % */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="pH" scope="IngredientForm" label="pH" type="number" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="pulp20Mesh" scope="IngredientForm" label="Pulp 20 Mesh" type="number" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="essencePpm" scope="IngredientForm" label="Essence" type="number" /> {/* ppm */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="sulfitePpm" scope="IngredientForm" label="Sulfite" type="number" /> {/* ppm */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="appearance" scope="IngredientForm" label="Appearance" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="odor" scope="IngredientForm" label="Odor" />
              </Grid>
              <Grid xs={12} md={12}>
                <TextField id="partStorageConditionAndShelfLife" scope="IngredientForm" label="Part Storage Condition and Shelf Life" multiline rows={2} />                      
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="strength" scope="IngredientForm" label="Strength" type="number" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="vitaminCMg100Ml" scope="IngredientForm" label="Vitamin C" /> {/* mg/100mL */}
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="nleaSsJuiceBrix" scope="IngredientForm" label="NLEA SS Juice Brix" />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField id="oilRecoveryRateVv" scope="IngredientForm" label="Oil Recovery Rate" />
              </Grid>
              <Grid xs={12} md={6}>
                <Checkbox id="isWater" scope="IngredientForm" label="Water" />
              </Grid>
              <Grid xs={12} md={6}>
                <Checkbox id="isNutritiveSweetener" scope="IngredientForm" label="Nutritive Sweetener" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <AuditInfoBox item={getState('IngredientForm')} />
      <DebugButton name="Ingredient" log={() => getState('IngredientForm')}/>
    </Paper>
  )
}

export default IngredientForm

{/* <Grid xs={12} md={6}>
  <FormControl fullWidth >
    <InputLabel id="units-label">Units</InputLabel>
    <Select 
      id="units" 
      labelId="units-label"
      label="Units"
      value={unitsType} 
      onChange={(event) => setUnitsType(event.target.value)} // TODO convert values?
    >
      <MenuItem value="metric">Metric (L, kg, °C)</MenuItem>
      <MenuItem value="imperial">Imperial (gal, lb, °F)</MenuItem>
    </Select>
    <FormHelperText>Applied to entire form</FormHelperText>
  </FormControl>
</Grid> */}
{/* <Grid xs={12} md={6}>
  <Button size="large" variant="outlined" onClick={() => {
    console.log('INGREDIENT = ', ingredient)
  }}>console log ingredient</Button>
</Grid> */}