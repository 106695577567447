import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import moment from 'moment'

export default function AuditInfoBox(props = {}) {

    const { item } = props

    let createdDate = moment(item?.createdDate)
    createdDate = createdDate.isValid() ? createdDate.format('MM/DD/yyyy') : ''

    let lastModifiedDate = moment(item?.lastModifiedDate)
    lastModifiedDate = lastModifiedDate.isValid() ? lastModifiedDate.format('MM/DD/yyyy') : ''

    return (
        <>
        {item?.createdDate && 
            <Box className="audit-info-box" sx={{ mt: 0 }}>
                <Grid container xs={12}>
                    <Grid xs={12} md={4}>
                        <Typography className="label">Created By:</Typography>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Typography className="value">{item?.createdBy}</Typography>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Typography className="label">Date Created:</Typography>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Typography className="value">{createdDate}</Typography>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Typography className="label">Last Modified By:</Typography>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Typography className="value">{item?.lastModifiedBy}</Typography>
                    </Grid>
                    <Grid xs={12} md={4} className="label">
                        <Typography>Date Last Modified:</Typography>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Typography className="value">{lastModifiedDate}</Typography>
                    </Grid>
                </Grid>
            </Box>
        }
        </>
    )
}