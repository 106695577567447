import { useEffect } from 'react'
import { Button, MenuItem } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import ScaleIcon from '@mui/icons-material/Scale'
import Select from '../form/StatefulSelect'
import {setState} from '../utils/State'

const FormViewCommandBar = (props = {}) => {
  
  const toggleHelp = () => {
    console.log('help...')
  }

  // useEffect(() => {
  //   setState('unitsType')
  // }, [])

  return (
    <div className="tis-commandbar">
      <Select id="unitsType" defaultValue="metric" fullWidth={false} labelSx={{ backgroundColor: 'rgba(236, 240, 245, .95)'}} label={<><ScaleIcon fontSize='small' sx={{ backgroundColor: 'rgba(236, 240, 245, .95)'}}/>&nbsp;Units</>}>
        <MenuItem value="metric">Metric (L, kg, °C)</MenuItem>
        <MenuItem value="imperial">Imperial (gal, lb, °F)</MenuItem>
      </Select>
      {props.showHelp && <HelpIcon onClick={toggleHelp}/>}
      {(props.showSave === undefined || props.showSave) && <Button variant="contained" onClick={props.onSave} endIcon={<SaveIcon />}>{props.saveButtonLabel ?? 'Save'}</Button>}
      {(props.showCancel === undefined || props.showCancel) && <Button variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Cancel</Button>}
          
        {/* <Button onClick={() => {
          console.log('unitsType: ', getState('unitsType'))
        }}>read</Button>
        <Button onClick={() => {
          setState('unitsType', 'metric')
        }}>write</Button> */}
    </div>
  )
}
export default FormViewCommandBar