export default class FactorsAndStandardsMapper {
    toFormModel(item) {
        return {
            syrupType: item.syrupType ?? '',
            typeOfFormulation: item.typeOfFormulation ?? '',
            syrupPhosphateStandard: item.syrupPhosphateStandard ?? '',
            bevPhosphateStandard: item.bevPhosphateStandard ?? '',
            syrupDilution: item.syrupDilution ?? '',
            syrupMlAliquot: item.syrupMlAliquot ?? '',
            bevMlAliquot: item.bevMlAliquot ?? ''
        }
    }
    
    toObject (formModel) {
        return {
            syrupType: formModel.syrupType,
            typeOfFormulation: formModel.typeOfFormulation,
            syrupPhosphateStandard: formModel.syrupPhosphateStandard,
            bevPhosphateStandard: formModel.bevPhosphateStandard,
            syrupDilution: formModel.syrupDilution,
            syrupMlAliquot: formModel.syrupMlAliquot,
            bevMlAliquot: formModel.bevMlAliquot
        }
    }
}