import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/material'
import TextField from '../form/StatefulTextField'
import DebugButton from '../utils/DebugButton'
import { getState } from '../utils/State'

export default function FactorsAndStandardsForm(props = {}) {
    const { scope } = props
    
    return (
        <Box sx={{ mt: 0, minHeight: 200 }}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={6}>
                    <TextField id="syrupType" label="Syrup Type" scope={scope} />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="typeOfFormulation" label="Type of Formulation" scope={scope} />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="syrupPhosphateStandard" label="Syrup Phosphate Standard" scope={scope} />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="bevPhosphateStandard" label="Beverage Phosphate Standard" scope={scope} />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="syrupDilution" label="Syrup Dilution" scope={scope} />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="syrupMlAliquot" label="Syrup mL Aliquot" scope={scope} type="volume" />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="bevMlAliquot" label="Beverage mL Aliquot" scope={scope} type="volume" />
                </Grid>
                {/* TODO create dynamic fields */}
            </Grid>
            <DebugButton name="FSInfo" log = {() => getState(scope)} />
        </Box>
     )
}