import { useState } from 'react'
import SquareFootIcon from '@mui/icons-material/SquareFoot';
//import FormulaCommandBar from '../commandBar/FormulaCommandBar'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import SpecificationForm from './SpecificationForm'
import { Typography } from '@mui/material'

export default function SpecificationFormPage (props = {}) {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false) 

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  // setTimeout(() => {
  //   console.log("setting to false")
  //   setIsLoading(false)
  // }, 2000)
  const breadcrumb = <span><SquareFootIcon/>Specification<Typography className="tis-form-text"> * - Required</Typography></span>

  const onSave = () => {
    console.log("SpecificationFormPage onSave")
    setSaveClicked(true)
    setTimeout(() => {
      setSaveClicked(false)
    }, 1000)
  }

  const confirmCancel = () => {
    console.log("Cancel")
    if (isDirty) { showDialog() }
    else { navigate('/formulas/') }
  }

  const handleCancel = () => {
    navigate('/specifications/')
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [unitsType, setUnitsType] = useState("metric")

  const changeUnits = (u) => {
    setUnitsType(u)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty} unitsType={unitsType} changeUnits={changeUnits}/>
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} onClose={hideDialog} onClickYes={handleCancel} yesLabel="Discard" noLabel="Stay on Page"/>
      <SpecificationForm saveClicked={saveClicked} startLoading={startLoading} stopLoading={stopLoading} setDirty={setDirty} setClean={setClean} unitsType={unitsType}/> 
    </Page>
  )
}