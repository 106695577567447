import AbstractFormModel, { getValues } from '../AbstractFormModel'
export default class ProductFormulaDetailFormModel extends AbstractFormModel {
    id = ''
    formulaName = ''
    bbn = ''
    specNumber = ''
    operatingUnit = ''
    formulaStates = ''
    picassoStatus = ''
    status = ''
    isCarbonated = ''
    volume = ''
    countriesOfProduction = []
    countriesOfSale = []

    constructor(formula = {}) {
        super(formula)
        Object.assign(this, this.init(formula))
    }

    toObject(refs) {
        const values = getValues(refs)
        return {
            id: this.numberSerializer.toObject(values.id),
            formulaName: values.formulaName,
            bbn: values.bbn,
            specNumber: values.specNumber,
            operatingUnit: values.operatingUnit,
            formulaStates: values.formulaStates,
            picassoStatus: values.picassoStatus,
            status: values.status,
            isCarbonated: this.booleanSerializer.toObject(values.isCarbonated),
            volume: this.numberSerializer.toObject(values.volume),
            countriesOfProduction: values.countriesOfProduction,
            countriesOfSale: values.countriesOfSale
        }
    }
}