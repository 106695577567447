import { AzureAuthenticator } from 'tis-azure-auth'
import { Azure } from '../config/config'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

let Authenticator = undefined
export const getAuthenticator = () => {
  if ( !Authenticator ) {
    Authenticator = new AzureAuthenticator(Azure)
  }
  return Authenticator
}

export const getToken = async () => {
  const auth = getAuthenticator()
  let token
  try {
    token = await auth.authenticate()
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      console.error(`InteractionRequiredAuthError caught and clearing session for retry`)
      sessionStorage.clear()
      return getToken()
    } else throw (e)
  }
  return token
}

export const getRequestConfig = async () => {
  const token = await getToken()
  return {
    headers: {        
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.idToken}`
    }
  }
}

export function getHostUrl() {
  return `${[window.location.protocol,'//',
  window.location.hostname,
  window.location.hostname === 'localhost' ? [':',window.location.port].join('') : ''].join('')}`
}

export function logout() {
  getAuthenticator().logout()
}
