import bus from './EventBus'
import Event from './EventNames'
import { ApiGateway } from '../config/config'
import axios from 'axios'
const axiosConfig = {
    baseURL: ApiGateway.domain,
    headers: {
        'x-api-key': ApiGateway.apiKey,
        'Content-Type': 'application/json'
    }
}

export const isSuccess = (response) => response && response.data && response.status >= 200 && response.status < 300

let myAxios = axios.create(axiosConfig)
const _get = myAxios.get
myAxios.get = async (url, config, setter) => {
    let response = {}
    try {
        response = await _get(url, config)
        if(isSuccess(response)) {
            if(setter) {
                // console.log(`get(${url}).response: `, response)
                let data = response.data?.data
                // console.log('get.data: ', data)
                setter(data)
            } 
        } else {
            console.log('bad response from url: ', url, response)
            bus.emit(Event.ALERT, {style: 'error', text: 'Error loading data', duration: 5000})
        }
    } catch (e) {
        console.log('Error loading url: ', url, e)
        bus.emit(Event.ALERT, {style: 'error', text: 'Error loading data', duration: 5000})
    }
    return response
}
export default myAxios
