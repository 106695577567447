import { useRef } from 'react'

export function findInput (node) {
    if(node) {
        if(node.current) {
            node = node.current
        }
        // console.log('node: ', node)
        if (node.hasOwnProperty('_value') || node._value || node.hasOwnProperty('value') || node.value || node.hasOwnProperty('checked')) {
            return node
        } else if (node.node) {
            return findInput(node.node)
        } else {
            try {
                let inputs = node.querySelectorAll('input')
                return inputs[0]
            } catch (e) {
                console.log(e)
            }
            return node
        }
    }
}

export function getValues (refs) {
    return Object.keys(refs).reduce((values, key) => {
        
        let ref = refs[key].current
        if(ref) {
            let inputRef = findInput(ref)
            let value
            if(inputRef.type === 'checkbox') {
                value = inputRef._value ?? inputRef.checked
            } else {
                value = inputRef._value ?? inputRef.value
                try {
                    value = JSON.parse(value)
                } catch (e) { 
                    //ok
                }
            }

            values[key] = value
        }

        // console.log('values: ', values)

        return values
    }, {})
}

export function getValue (ref, options = {}) {
    options = {deserialize: false, ...options}
    let value
    if(ref) {
        let inputRef = findInput(ref.current)
        if(inputRef?.type === 'checkbox') {
            return inputRef._value ?? inputRef.checked
        } else {
            value = inputRef?._value ?? inputRef?.value
        }
        // if(options.deserialize) {
            try {
                value = JSON.parse(value)
            } catch (e) { 
                //ok
            }
        // }
    }
    return value
}

export function setValue (ref, value, options = {}) {
    options = {internalOnly: false, serialize: false, ...options}
    let inputRef = findInput(ref?.current)
    // if(options.serialize) {
        value = JSON.stringify(value)
    // }
    if(inputRef) {
        if(inputRef.type === 'checkbox') {
            inputRef._value = value
        } else {
            inputRef.value = inputRef._value = value
        }
    }
    // console.log('setValue: ref: ', ref, 'value: ', value)
}


export default /*abstract*/ class AbstractFormModel {

    init (obj = {}) {
        let newObj = {}
        Object.keys(this).forEach(key => {
            newObj[key] = obj[key] ?? this[key] ?? null
        })
        return newObj
    }

    constructor(_obj = {}) {
        this.booleanSerializer = {
            toObject(str) {
                str = ('' + str).toLowerCase().trim()
                return (str === 'undefined' || str === 'null' || str === '') ? undefined : !(str === 'false' || str === '0' || str === 'f' || str === 'n' || str === 'no' || str === 'off' || str === '' || str === 'undefined'|| str === 'null')
            }
        }
        this.numberSerializer = {
            toObject(str) {
                str = ('' + str).toLowerCase().trim()
                return (str === 'undefined' || str === 'null' || str === '') ? undefined : (new Number(str)).valueOf()
            }
        }
    }

    generateRefs () {
        return Object.keys(this).reduce((refs, key) => {
            refs[key] = useRef(null)
            return refs
        }, {})  
    }
}