import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/material'
import TextField from '../form/TextField'

export default function SraInfoForm(props = {}) {
    const {scope} = props
    return (
        <Box sx={{ mt: 0, minHeight: 200 }}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={6}>
                    <TextField id="ingredientStatement" scope={scope} label="Ingredient Statement" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="statementOfIdentity" scope={scope} label="Statement Of Identity" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="presenceOfGluten" scope={scope} label="Presence of Gluten" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="glutenLabelClaim" scope={scope} label="Gluten Label Claim" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="glutenLabelClaimFreeForm" scope={scope} label="Gluten Label Claim Free Form" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="containsCaffeine" scope={scope} label="Contains Caffeine" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="caffeineLabelClaim" scope={scope} label="Caffeine Label Claim" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="caffeineLabelClaimFreeForm" scope={scope} label="Caffeine Label Claim Free Form" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="organicSra" scope={scope} label="Organic" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="organicLabelClaim" scope={scope} label="Organic Label Claim" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="organicLabelClaimFreeForm" scope={scope} label="Organic Label Claim Free Form" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="precautionsWarnings" scope={scope} label="Precautions/Warnings" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="precautionsWarningsFreeForm" scope={scope} label="Precautions/Warnings Free Form" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="kosherSra" scope={scope} label="Kosher" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="kosherFreeForm" scope={scope} label="Kosher Free Form" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="juiceContentSra" scope={scope} label="Juice Content" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="juiceContentFinalValue" scope={scope} label="Juice Content Final Value" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="countryOfOriginStatement" scope={scope} label="Country of Origin Statement" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="allergenCategory" scope={scope} label="Allergen Category" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="containsAllergenSra" scope={scope} label="Does this contain an allergen?" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="allergenIngredientSra" scope={scope} label="What is the allergen ingredient?" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="allergenLabelStatement" scope={scope} label="Allergen Label Statement" disabled/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="allergenStatementFreeForm" scope={scope} label="Allergen Statement Free Form" disabled/>
                </Grid>
            </Grid>
        </Box>
     )
}