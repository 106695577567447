import { memo, useState } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import OutputDocumentForm from './OutputDocumentForm.js'
import LoadingButton from '@mui/lab/LoadingButton'
import LoadingIndicator from '../layout/LoadingIndicator'

export const OutputDocumentFormDialog = memo((props = {}) => {  
  const [refresh, setRefresh] = useState(0)
  

  const onSave = async (e) => {
    await props.onSave(e)
    setRefresh(refresh + 1)
  }

  return (
    <>
      {props.context?.item && 
        <Dialog sx={{ maxHeight: 'calc(100% - 50px)', top: '14px' }} maxWidth='xl' open={props.open}>
          <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Output Document</DialogTitle> 
          <DialogContent>
            <OutputDocumentForm refresh={refresh} context={{...props.context, commercializationId: props.context?.item?.id }}/>
            <LoadingIndicator isLoading={props.isLoading} />
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <LoadingButton loading={props.isLoading} onClick={props.onClose}>Close</LoadingButton>
            <LoadingButton loading={props.isLoading} color="primary" onClick={onSave} variant="contained">Save</LoadingButton>
          </DialogActions>
        </Dialog>
      } 
    </>
  )
})
