import React from "react";

  function Pi(props) {
    return (
      <svg viewBox="0 0 24 24" {...props}>
        <path d="M7 8H17" stroke="currentColor" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"/>
        <path d="M9 8L9 16" stroke="currentColor" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"/>
        <path d="M15 8L15 14.03C15 15.118 15.882 16 16.97 16L17 16" stroke="currentColor" stroke-width="2" stroke-linejoin="round" fill="none" stroke-linecap="round"/>
      </svg>
    );
  }
  export default Pi;