export default class DynaFormModel {

    toFormModel(fields, defaultValues) {
        let model = {}
        if(fields && defaultValues) {
            model = {...fields}            
            Object.keys(defaultValues).forEach(key => {
                model[key] = defaultValues[key] ?? model[key] ?? null
            })
        }
        return model
    }
}