import IngredientCheckMapper from '../mapper/IngredientCheckMapper'
import { IngredientCheckFormDialog } from './IngredientCheckFormDialog'
import ListView from '../layout/ListView'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListIngredientChecks(props = {}) {
    const columns = [
      // { accessorKey: 'id', header: 'Id', size: 100 },
      { accessorKey: 'ingredientCheckName', header: 'Ingredient Check', size: 100 },
      { accessorKey: 'oneUnitKgPlusAnyPart', header: 'Weight of Ingredient', size: 100 },
      { accessorKey: 'stdConc', header: 'Standard Concentration', size: 100 },
    ]

    return (
      <ListView 
        columns = {columns}
        data = { props.data  } 
        scope = { props.scope }
        formulaId = { props.formulaId }
        dataMapper = { IngredientCheckMapper }
        itemLabelFn = {ingredientCheck => ingredientCheck.ingredientCheckName}

        getData = { async ({ requestConfig }) => {
          const response = await axios.get(`${ApiGateway.formula}/${props.formulaId}/ingredientCheck`, requestConfig)
          return { isSuccess: isSuccess(response), data: response.data?.data }
        }}
        onSave = { async ({item, requestConfig}) => {
          item.formula = { id: props.formulaId }
          if(item.packageType?.length > 0) {
            const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/ingredientCheck`, item, requestConfig)
            return { isSuccess: isSuccess(response) }
          } else {
            return { isSuccess: false}
          }
        }}
        onDelete= { async ({item, requestConfig}) => {
          const response = await axios.delete(`${ApiGateway.formula}/${props.formulaId}/ingredientCheck/${item.id}`, requestConfig)
          return { isSuccess: isSuccess(response) }
        }}
        dialog = { <IngredientCheckFormDialog /> }

      />
    )
}