import { useContext, useEffect, useReducer, useState, memo } from 'react'
import { Science as ScienceIcon } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useNavigate, useParams } from 'react-router-dom'
import { checkRequiredError, checkStrictRequiredError } from '../form/Form'
import IngredientForm from './IngredientForm'
// import IngredientFormModel from '../model/ingredient/IngredientFormModel'
import IngredientMapper from '../mapper/IngredientMapper'
import { ApiGateway } from '../config/config'

import { UserContext } from '../App'
import axios, { isSuccess } from '../utils/AxiosClient'
import bus from '../utils/EventBus'
import Event from '../utils/EventNames'
import { dispatch, getState, setState } from '../utils/State'

const IngredientFormPage = memo((props = {}) => {
  let navigate = useNavigate();
  let params = useParams()
  const id = params.ingredientId
  const [isLoading, setIsLoading] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({})
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const ingredientMapper = new IngredientMapper()
  // const [context, setContext] = useState()
  const user = useContext(UserContext)
  // const refs = formModel.generateRefs()

  useEffect(() => {
    getIngredient()
  }, [])

  const setError = (value, id, errorChecker = checkRequiredError, errorObject = fieldErrors, errorSetter = setFieldErrors) => {
    if(errorChecker && typeof errorChecker === 'function') {
      let error = errorChecker(value)
      errorObject[id] = error
      errorSetter({ ...errorObject }, { id: error })
    }
  }

  const getIngredient = async () => {
    setIsLoading(true)
    let response = await axios.get(`${ApiGateway.ingredient}/${id}`, { headers: { Authorization: user.idToken } })
    
    let model = ingredientMapper.toFormModel(response?.data?.data)
    setState('IngredientForm', model)
    dispatch('IngredientForm', model)
    setIsLoading(false)
    forceUpdate()
  }

  const onSave = async () => {
    setIsLoading(true)

    // if (currentUnitsType !==  "metric"){
    //   convertAllUnits("metric", volumeFields, weightFields, densityFields, temperatureFields, formula, setFormula)
    //   convertAllUnits("metric", syrupConvFields.volumeFields, syrupConvFields.weightFields, syrupConvFields.densityFields, syrupConvFields.temperatureFields, syrupSpec, setSyrupSpec)
    //   convertAllUnits("metric", bevConvFields.volumeFields, bevConvFields.weightFields, bevConvFields.densityFields, bevConvFields.temperatureFields, bevSpec, setBevSpec)
    // }

    let errors = []
    // // check errors
    // if (checkStrictRequiredError(getValue(refs.name))) {
    //   errors.push("Valid Ingredient Name is required")
    //   setError(getValue(refs.name), "name", checkStrictRequiredError)
    // }
   
    // raise alerts
    if (errors.length > 0) {
      bus.emit(Event.ALERT, { text: errors.join("\n"), style: 'warn' })
      setIsLoading(false)
      return ("Fix errors")
    }

    //call service
    // const item = formModel.toObject(refs)

    const ingredient = ingredientMapper.toObject(getState('IngredientForm'))
    let response = await axios.post(ApiGateway.ingredient, ingredient, { headers: { Authorization: user.idToken } })
    
    // // console.log('response: ', response)
    // if (currentUnitsType !==  "metric"){
    //   convertAllUnits(currentUnitsType, volumeFields, weightFields, densityFields, temperatureFields, formula, onChangeItem)
    //   convertAllUnits(currentUnitsType, syrupConvFields.volumeFields, syrupConvFields.weightFields, syrupConvFields.densityFields, syrupConvFields.temperatureFields, syrupSpec, setSyrupSpec)
    //   convertAllUnits(currentUnitsType, bevConvFields.volumeFields, bevConvFields.weightFields, bevConvFields.densityFields, bevConvFields.temperatureFields, bevSpec, setBevSpec)
    // }

    if (isSuccess(response)) {
      bus.emit(Event.ALERT, { "text": "Saved Successfully", "style": "success", "duration": 2000 })
      setIsLoading(false)
      return response
    } else {
      let text = "Not Saved\n" + response.statusText
      bus.emit(Event.ALERT, { "text": text, "style": "error" })
      setIsLoading(false)
      return response
    }
  }

  const confirmCancel = () => {
    navigate('/ingredients/') 
  }

  const breadcrumb = <span><ScienceIcon/>Ingredient</span>
  return (
    <>{
      <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={<FormViewCommandBar onSave={onSave} onCancel={confirmCancel} sx={{minHeight: '500px'}}/>}>
        <IngredientForm /> 
      </Page>
    }</>
  )
})
export default IngredientFormPage