import { default as React, useState } from 'react'
import { Button } from '@mui/material'
import CalculateIcon from '@mui/icons-material/Calculate';
import ListConversions from './ListConversions'
import bus from '../utils/EventBus.js';
import Event from '../utils/EventNames.js';
import { Link, useNavigate } from 'react-router-dom';

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'

export default function ListConversionsPage (props = {}) {
  let navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(true)
  // setTimeout(() => {
  //   console.log("setting to false")
  //   setIsLoading(false)
  // }, 2000)

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }
  
  const breadcrumb = <span><CalculateIcon/> Conversions</span>

  // const sendMessage = () => {
  //   console.log('sending alert')
  //   bus.emit(Event.ALERT, { text: 'The form was saved successfully!' })
  // }
  
  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAddRecord, setShowAddRecord] = useState(false)
  const addRecord = () => {
    navigate('/conversion/create')
    //setShowAddRecord(true)
  }
  const hideAddRecord = () => {
    setShowAddRecord(false)
  }
  
  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={<ListViewCommandBar  toggleFilters={toggleFilters} addRecord={addRecord} listView={{ getAddItemUrl: () => {return "/conversion/create"}}}
    />}>
      {/* <Button variant="contained" onClick={sendMessage}>Alert</Button> */}
      <ListConversions showFilters={showFilters} showAddRecord={showAddRecord} startLoading={startLoading} stopLoading={stopLoading} hideAddRecord={hideAddRecord}/>
    </Page>
  )
}
