import { memo } from 'react'
import Body from "./Body"
import Footer from "./Footer"
import Header from "./Header"
import Sidebar from "./Sidebar"
import SidebarMenu from "./SidebarMenu.js"


const Layout = memo((props = {}) => {
  return (
    <div className="fixed">
      <Header
        userFullName={props.userFullName}
        appName={<div style={{
          height: '46px',
          padding: '5px'
          }}>
            <span className="logo-lg"><b>TI&amp;S</b> Komix</span>
          </div>}
        />
      <Sidebar
        menu={SidebarMenu}
      />
      <Body/>
      <Footer version = "1.0.0" />
    </div>
  )
})

export default Layout