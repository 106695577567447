import { default as React, useState } from 'react'
import { Button } from '@mui/material'
// import ExportCsv from '../Utils/ExportCsv.js';
import FilterListIcon from '@mui/icons-material/FilterList'
import AddIcon from '@mui/icons-material/Add'
import HelpIcon from '@mui/icons-material/Help'
import RestoreIcon from '@mui/icons-material/Restore'
import { Link } from 'react-router-dom';

export default function ListViewCommandBar (props = {}) {
  
  const [filterButtonLabel, setFilterButtonLabel] = useState('Show Filters')

  const toggleFilters = () => {
    props.toggleFilters()
    //props.listView.toggleFilters()
    setFilterButtonLabel(filterButtonLabel === 'Show Filters' ? 'Hide Filters' : 'Show Filters')
  }

  const toggleHelp = () => {

  }

  return (
    <div className="tis-commandbar tis-commandbar-listview">
      {props.showHelp && <HelpIcon onClick={toggleHelp}/>}
      {/* {(typeof this.props.showExport === 'undefined' || this.props.showExport) && <Button bsSize="small" onClick={() => {
        this.state.listView.exportData();
      }}>Export&nbsp;&nbsp;<Glyphicon glyph="download" bsSize="small"/></Button>} */}
      {(props.showFilters === undefined || props.showFilters) && <Button variant="contained" onClick={toggleFilters}>{filterButtonLabel}&nbsp;&nbsp;<FilterListIcon/></Button>}
      {/* {(props.showAdd === undefined || props.showAdd) && props.listView && <Link to={props.listView.getAddItemUrl()}>
        <Button variant="outlined">Add&nbsp;&nbsp;<AddIcon/></Button>
      </Link>} */}
      {(props.showAdd === undefined || props.showAdd) && <Button variant="outlined" onClick={props.addRecord}>Add&nbsp;&nbsp;<AddIcon/></Button>}
      {(props.showResetList) && <Button variant="contained" onClick={props.onClickReset}>Reset Table&nbsp;&nbsp;<RestoreIcon/></Button>}
    </div>
  )
}
