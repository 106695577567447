import React, { useEffect, useState } from 'react'
import { FormGroup, TextField as MuiTextField, InputAdornment } from '@mui/material'
import ScaleIcon from '@mui/icons-material/Scale'
import convert from 'convert-units'
import units from '../utils/units'
import DecimalFormat from '@sei-atl/decimal-format'
import { getValue } from '../model/AbstractFormModel'
/**
 * Uncontrolled TextField component
 * 
 * 
 * 
 * @param {} props 
  *      - refs: Pass in the props.refs object to attach to the DOM. The props.refs object should have a key -> ref pair for each field in the form.
 *
 * @returns 
 */

export default function TextField(props = {}) {
    const defaultOnBlurMin = () => {
        // let value = getValue(refs?.[minId])
        // if(onError) {
        //     onError(value, id, validator)
        // }
        _setMinStyles()
    }

    const defaultOnBlurTarget = () => {
        let value = getValue(refs?.[id])
        if(onError) {
            onError(value, id, validator)
        }    
        _setTargetStyles()
    }

    const defaultOnBlurMax = () => {
        // let value = getValue(refs?.[maxId])
        // if(onError) {
        //     onError(value, id, validator)
        // }
        _setMaxStyles()
    }

    const defaultOnBlur = () => {
        let value = getValue(refs?.[id])
        // console.log(`value on blur of ${id} is: `, getValue(refs?.[id]))
        if(onError) {
            onError(value, id, validator)
        }
        _setStyles()
    }

    let {
        item = props.context?.item,
        refs = props.context?.refs,
        onError = props.context?.onError,
        fieldErrors = props.context?.fieldErrors,
        unitsType = props.context?.unitsType,
        onBlur = defaultOnBlur,
        fullWidth = true,
        className = 'tis-form-field',
        includeTolerances = false,
        id, context, label, onFocus, validator, format, type, InputProps, ...otherProps
    } = props

    const minId = `${id}Min`
    const maxId = `${id}Max`

    const [minStyles, setMinStyles] = useState({})
    const [targetStyles, setTargetStyles] = useState({})
    const [maxStyles, setMaxStyles] = useState({})
    const [styles, setStyles] = useState({})

    const formatValue = () => {
        if(format) {
            const df = new DecimalFormat(format)
            refs[id].current._value = refs[id].current.value
            refs[id].current.value = df.format(refs[id].current.value)
        }
    }

    const unformatValue = () => {
        if(format) {
            refs[id].current.value = refs[id].current._value
        }
    }

    useEffect(() => {
        if(format) {
            formatValue()   
        }
        if(includeTolerances) {
            _setMinStyles()
            _setTargetStyles()
            _setMaxStyles()
        } else {
            _setStyles()
        }
    }, [])

    const _setMinStyles = () => {
        const value = getValue(refs?.[minId])
        const hasValue = value !== undefined && value !== null && value !== ''
        const targetValue = getValue(refs?.[id])
        setMinStyles({
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: '4px 0 0 4px',
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: (hasValue && !targetValue) ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            "& .MuiInputBase-root": {
                fontWeight: hasValue ? 700 : 400
            },
            width: '25%', 
            px: '2px 0 2px 2px'
        })
    }

    const _setTargetStyles = () => {
        const value = getValue(refs?.[id])
        const hasValue = value !== undefined && value !== null && value !== ''
        setTargetStyles({
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: 0,
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            "& .MuiInputBase-root": {
                fontWeight: hasValue ? 700 : 400
            },
            width: '50%', 
            px: '2px 0'
        })
    }

    const _setMaxStyles = () => {
        const value = getValue(refs?.[maxId])
        const hasValue = value !== undefined && value !== null && value !== ''
        const targetValue = getValue(refs?.[id])
        setMaxStyles({
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderRadius: '4px 0 0 4px',
                borderLeft: (hasValue && !targetValue) ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            "& .MuiInputBase-root": {
                fontWeight: hasValue ? 700 : 400
            },
            width: '25%', 
            px: '2px 2px 2px 0'
        })
    }

    const _setStyles = () => {
        const value = getValue(refs?.[id])
        const hasValue = value !== undefined && value !== null && value !== ''
        setStyles({
            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": { 
                borderLeft: hasValue ? '.33em solid #77CC99' : '', 
                borderRight: hasValue ? '.33em solid #77CC99' : ''
            },
            borderRadius: '4px',
            "& .MuiInputBase-root fieldset": {
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': ''
            },
            "& .MuiInputBase-root": {
                fontWeight: hasValue ? 700 : 400
            }
        })
    }

    const isMeasurement = () => {
        const t = type?.toLowerCase()
        return unitsType && t === 'temperature' || t === 'weight' || t === 'volume' || t === 'density'    
    }

    const inputProps = isMeasurement() ? 
        {...InputProps, ...{ endAdornment: <InputAdornment position="end">{units[unitsType][type]}&nbsp;&nbsp;<ScaleIcon fontSize="small"/></InputAdornment> }} : 
        InputProps

    const convertUnits = () => {
        let _type = type?.toLowerCase()
        let sourceMeasure
        let value
        if(unitsType === 'metric') {
            if(_type === 'temperature') {
                sourceMeasure = parseFloat(refs[id].current?.value)
                value = convert(sourceMeasure).from("F").to("C")
                refs[id].current.value = value
            }
        } else if (unitsType === 'imperial') {
            if(_type === 'temperature') {
                sourceMeasure = parseFloat(refs[id].current?.value)
                value = convert(sourceMeasure).from("C").to("F")
                refs[id].current.value = value
            }
        }
    }


    return (
        <>
        {includeTolerances && 
            <FormGroup row={true}>
                <MuiTextField 
                    className={className}
                    id={minId}
                    type="text"
                    label="Min"
                    defaultValue={item?.[minId] ?? ''} 
                    inputRef={refs?.[minId]}
                    onFocus={(event) => { unformatValue(event); if(onFocus) {onFocus(event)}}}
                    onBlur={(event) => { formatValue(event); defaultOnBlurMin(event)}}
                    error={!!fieldErrors?.[minId]}
                    helperText={fieldErrors?.[minId] ? "This field is required" : false}  //@TODO: make the error message dynamic
                    InputProps={inputProps}
                    sx={ minStyles }
                    {...otherProps}
                />
                <MuiTextField 
                    className={className}
                    id={id}
                    type="text"
                    label={label}
                    defaultValue={item?.[id] ?? ''} 
                    inputRef={refs?.[id]}
                    onFocus={(event) => { unformatValue(event); if(onFocus) {onFocus(event)}}}
                    onBlur={(event) => { formatValue(event); defaultOnBlurTarget(event)}}
                    error={!!fieldErrors?.[id]}
                    helperText={fieldErrors?.[id] ? "This field is required" : false}  //@TODO: make the error message dynamic
                    InputProps={inputProps}
                    sx={ targetStyles }
                    {...otherProps}
                />
                <MuiTextField 
                    className={className}
                    id={maxId}
                    type="text"
                    label="Max"
                    defaultValue={item?.[maxId] ?? ''} 
                    inputRef={refs?.[maxId]}
                    onFocus={(event) => { unformatValue(event); if(onFocus) {onFocus(event)}}}
                    onBlur={(event) => { formatValue(event); defaultOnBlurMax(event) }}
                    error={!!fieldErrors?.[maxId]}
                    helperText={fieldErrors?.[maxId] ? "This field is required" : false}  //@TODO: make the error message dynamic
                    InputProps={inputProps}
                    sx={ maxStyles }
                    {...otherProps}
                />
            </FormGroup>
        }
        {!includeTolerances &&
            <MuiTextField 
                className={className}
                id={id}
                type="text"
                fullWidth={fullWidth === undefined ? true : fullWidth}
                label={label}
                defaultValue={item?.[id] ?? ''} 
                inputRef={refs?.[id]}
                onFocus={(event) => { unformatValue(event); if(onFocus) {onFocus(event)}}}
                onBlur={(event) => { formatValue(event); onBlur ? onBlur(event) : defaultOnBlur(event)}}
                error={!!fieldErrors?.[id]}
                helperText={fieldErrors?.[id] ? "This field is required" : false}  //@TODO: make the error message dynamic
                InputProps={inputProps}
                sx={ styles }
                {...otherProps}
            />
        }
        </>
    )
}