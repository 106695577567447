import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../App'
import { MenuItem, Paper } from '@mui/material'
import Autocomplete from '../form/StatefulAutocomplete'
import TextField from '../form/StatefulTextField'
import Select from '../form/StatefulSelect'
import Grid from '@mui/material/Unstable_Grid2'

import LoadingIndicator from '../layout/LoadingIndicator'
import DebugButton from '../utils/DebugButton'
import {getState} from '../utils/State'

export default function OutputDocumentForm(props = {}) {
    const user = useContext(UserContext)
    const [templateOptions, setTemplateOptions] = useState([])

    const getTemplateOptions = async () => {
        setTemplateOptions([{ label: "Standard Mixing Instructions", value: "StandardMixingInstructions" }])
    }

    useEffect(() => {
        getTemplateOptions()
    }, [])

    return (
        <Paper sx={{ p: 0, border: 'none', boxShadow: 'none' }}>
            <Grid container rowSpacing={2}>
                <TextField id="id" scope="OutputDocumentForm" type="hidden" />
                <Grid xs={12}>
                    <Autocomplete id="template" label="Template" options={templateOptions} scope="OutputDocumentForm" />
                </Grid>
                <Grid xs={12}>
                    <Select id="status" label="Status" scope="OutputDocumentForm">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="Informational Only">Informational Only</MenuItem>
                        <MenuItem value="Alpha">Alpha</MenuItem>
                        <MenuItem value="Draft">Draft</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Final">Final</MenuItem>
                        <MenuItem value="Issued">Issued</MenuItem>
                    </Select>
                </Grid>      
                <Grid xs={12}>
                    <TextField id="issuedDate" label="Date Issued" scope="OutputDocumentForm" />
                </Grid>
                <Grid xs={12}>
                    <TextField id="name" label="Name" scope="OutputDocumentForm" />
                </Grid>          
                <DebugButton name="Output Doc" log={() => getState('OutputDocumentForm')}/>
            </Grid>
            <LoadingIndicator isLoading={props.isLoading} />
        </Paper>
    )
}