import FormulaIngredientMapper from '../mapper/FormulaIngredientMapper'
import { Tooltip } from '@mui/material'
import TextField from '../form/StatefulTextField'
import { FormulaIngredientFormDialog } from '../formulaIngredient/FormulaIngredientFormDialog'
import ListView from '../layout/ListView.js'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListFormulaIngredients(props = {}) {
    const columns = [
      { accessorKey: 'ingredient.name', header: 'Ingredient Name', size: 100 },
      { accessorKey: 'alias', header: 'Alias', size: 100 },
      { accessorKey: 'ingredient.specNumber', header: 'Spec Number', size: 100 },
      { accessorKey: 'ingredient.sCode', header: 'S-Code', size: 100 },
      { 
        accessorKey: 'weightKg',
        Cell: ({cell}) => { 
          const value = cell.getValue() && !isNaN(cell.getValue()) ? Number(cell.getValue()).toFixed(4) : ''
          return <div style={{ width: '60px', textAlign: 'right' }}>{ value }</div>
        },
        header: 'Weight (Kg)', 
        size: 100
      },
      { accessorKey: 'formOfIngredient', header: 'Form of Ingredient', size: 100 }
    ]

    const getScaledVolume = () => {
      let scaledVolume = <></>
      if(props.showScaledVolume) {
        const field = <TextField
          disabled={props.disabled}
          type="volume"
          sx={{ width: "10em", "& .MuiFormLabel-root": { fontSize: '12px'}, "& .MuiInputBase-root": { m: "4px 0", height: "1.8em" } }}
          size="small"
          id="scaledVolume"
          scope={props.scope}
          // value={props.item?.scaledVolume}
          // onChange={(event) => handleTextChange(event, props.item, props.onChangeItem)}
          label="Scaled Volume"
        /> 
        if(props.disabled) {
          scaledVolume = <Tooltip title="Save to enable!" arrow placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [7, -14], }, }, ], }}}>
            <span>
              {field}
            </span>
          </Tooltip>
        } else {
          scaledVolume = field
        }
      }
      return scaledVolume
    }

    return (
      <ListView 
        label = { props.label }
        itemLabelFn = { formulaIngredient => formulaIngredient?.alias }
        
        columns = { columns }
        disabled = { props.disabled }
        scope = "FormulaIngredientForm"
        dataMapper = { FormulaIngredientMapper }

        getData = { async ({ requestConfig }) => {
          const url = props.commercializationId ? `${ApiGateway.formula}/${props.formulaId}/commercializations/${props.commercializationId}/ingredients` : `${ApiGateway.formula}/${props.formulaId}/ingredients/master`
          const response = await axios.get(url, requestConfig)
          return { isSuccess: isSuccess(response), data: response.data?.data }
        }}

        onSave = { async ({item, requestConfig}) => {
          console.log('onSave, formulaId: ', props.formulaId)
          item.formula = { id: props.formulaId }
          item.commercialization = { id: props.commercializationId }
          const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/ingredients`, item, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}
        
        onDelete= { async ({item, requestConfig}) => {
          const response = await axios.delete(`${ApiGateway.formula}/${props.formulaId}/ingredients/${item.id}`, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}

        topToolbarStart = { getScaledVolume() }
        dialog = { <FormulaIngredientFormDialog /> }
      />
    )
}