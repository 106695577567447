import operatingUnits from '../staticData/OperatingUnits'
import { checkEmptyArrayError } from '../form/Form'
import Grid from '@mui/material/Unstable_Grid2'
import { Box, MenuItem } from '@mui/material'
import DebugButton from '../utils/DebugButton'
import Autocomplete from '../form/StatefulAutocomplete'
import Select from '../form/StatefulSelect'
import TextField from '../form/StatefulTextField'
import { getState } from '../utils/State'

export default function ProductFormulaDetailForm(props = {}) {
    const {scope} = props

    return (
        <Box sx={{ mt: 0, minHeight: 200 }}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={6}>
                    <TextField id="formulaName" scope={scope} label="Formula Name" required/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="bbn" scope={scope} label="BBN/L-Number" required/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="specNumber" scope={scope} label="Spec Number"/>
                </Grid>
                <Grid xs={12} md={6}>
                    <Autocomplete id="operatingUnit" scope={scope} label="Operating Unit" options={operatingUnits} validator={checkEmptyArrayError} />
                </Grid>
                {/* <Grid xs={12} md={6}>
                    <Select id="recordType" scope={scope} label="Record Type" required>
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="Global">Global</MenuItem>
                        <MenuItem value="Retail">Retail</MenuItem>
                    </Select>
                </Grid> */}
                <Grid xs={12} md={6}>
                    {props.countryOptions &&
                        <Autocomplete multiple id="countriesOfProduction" scope={scope} label="Countries of Production" options={props.countryOptions} validator={checkEmptyArrayError} />
                    }
                    </Grid>
                <Grid xs={12} md={6}>
                    {props.countryOptions &&
                        <Autocomplete multiple id="countriesOfSale" scope={scope} label="Countries of Sale" options={props.countryOptions} validator={checkEmptyArrayError} />
                    }
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="formulaStates" scope={scope} label="Formula State(s)" required>
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value={"Syrup"}>Syrup</MenuItem>
                        <MenuItem value={"Beverage"}>Beverage</MenuItem>
                        <MenuItem value={"Syrup & Beverage"}>Syrup & Beverage</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="picassoStatus" scope={scope} label="Picasso Status">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="Informational Only">Informational Only</MenuItem>
                        <MenuItem value="Alpha">Alpha</MenuItem>
                        <MenuItem value="Draft">Draft</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Final">Final</MenuItem>
                        <MenuItem value="Issued">Issued</MenuItem> 
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="status" scope={scope} label="Komix Status">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="Informational Only">Informational Only</MenuItem>
                        <MenuItem value="Alpha">Alpha</MenuItem>
                        <MenuItem value="Draft">Draft</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Final">Final</MenuItem>
                        <MenuItem value="Issued">Issued</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="isCarbonated" scope={scope} label="Carbonated/Noncarbonated">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Carbonated</MenuItem>
                        <MenuItem value="false">Noncarbonated</MenuItem>
                    </Select>
                </Grid>
                {/* <Grid xs={12} md={6}>
                    <TextField id="subtype" scope={scope} label="Subtype"/>
                </Grid> */}
                <Grid xs={12} md={6}>
                    <TextField id="volume" scope={scope} label="Formula Volume" type="volume" format="#,##0.#####"/>
                </Grid>
                {/* <Grid xs={12} md={6}>
                    <TextField id="secondChoiceUnitSize" scope={scope} label="Second Choice Unit Size" />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="thirdChoiceUnitSize" scope={scope} label="Third Choice Unit Size" />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="sweetenerNature" scope={scope} label="Sweetener Nature" disabled />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="sweetenerType" scope={scope} label="Sweetener Type" disabled />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="sweetenerName" scope={scope} label="Sweetener Name" disabled />
                </Grid> */}
            </Grid>
            <DebugButton name="PFD" log={() => getState(scope)}/>
        </Box>
    )
}