import { CircularProgress } from '@mui/material'

export default function LoadingIndicator(props = {}) {
    return (
        <>
         { props.isLoading &&
            <div className='loading'>
                <div className='loading-inner'>
                {/* <img src={LoadingGif} alt="Loading"/> Loading ... */}
                    <CircularProgress color="inherit" />
                </div>
            </div>}
        </>
    )
}