import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import IngredientCheckForm from './IngredientCheckForm'
import LoadingButton from '@mui/lab/LoadingButton'

export function IngredientCheckFormDialog (props = {}) {  
    // const user = useContext(UserContext)

    
    // const onSave = async () => {
    //   setIsSaving(true)
    //   console.log('IngredientCheckFormDialog.onSave, current state: ', props.item)
    //   let formulaId = props.item?.formulaId || props.item?.formula?.id //props.item.formula.id
    //   if(formulaId) {
    //     let saveItem = {
    //       formula: { id: formulaId },
    //       ingredientCheckName: props.item?.ingredientCheckName,
    //       oneUnitKgPlusAnyPart: props.item?.oneUnitKgPlusAnyPart,
    //       stdConc: props.item?.stdConc,
    //       factorOverride: props.item?.factorOverride,
    //       weightOverride: props.item?.weightOverride,
    //       createdBy: props.item?.createdBy,
    //       createdDate: props.item?.createdDate,
    //       lastModifiedBy: props.item?.lastModifiedBy,
    //       lastModifiedDate: props.item?.lastModifiedDate
    //     }
    //     if (props.item?.id){
    //       saveItem.id = props.item.id
    //     }
    //     let response = await axios.post(`${ApiGateway.formula}/${formulaId}/ingredientCheck/`, saveItem, { headers: { Authorization: user.idToken } })
    //     console.log('IngredientCheckFormDialog.onSave, response: ', response)
    //     if(isSuccess(response)) {
    //       props.onClose()
    //       if(props.mode === 'add') {
    //         bus.emit(Event.ALERT, { text: 'Added Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
    //       } else {
    //         bus.emit(Event.ALERT, { text: 'Saved Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
    //       }
    //     } else {
    //       const text = 'There was an issue saving the ingredientCheck.  Please try again later.'
    //       bus.emit(Event.ALERT, { text: text, style: 'error' })
    //     }
    //     setClean()
    //     setIsSaving(false)
    //     if(props.onSave) {
    //       props.onSave()
    //     }
    //   } else {
    //     const text = 'There was an issue saving the ingredientCheck.  Please try again later.'
    //     bus.emit(Event.ALERT, { text: text, style: 'error' })
    //   }
    // }

    return (
      <Dialog sx={{ maxHeight: 'calc(100% - 2em)' }} fullWidth maxWidth='lg' scroll='paper' open={props.open}>
        <DialogTitle textAlign="center">{props.mode === 'edit' ? 'Edit' : 'Add'} Ingredient Check</DialogTitle> 
        <DialogContent>
          <IngredientCheckForm formulaId={props.formulaId} scope={props.scope} />
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <LoadingButton loading={props.isLoading} onClick={props.onClose}>Cancel</LoadingButton>
          <LoadingButton loading={props.isLoading} color="primary" onClick={props.onSave} variant="contained">Save</LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
