import { cloneElement, useContext, useEffect, useReducer, useState } from 'react'
import { UserContext } from '../App.js'
import { dispatch, getState, setState } from '../utils/State.js'
import MaterialReactTable from 'material-react-table'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import FilterListIcon from '@mui/icons-material/FilterList'
import Grid from '@mui/material/Unstable_Grid2'
import ConfirmDialog from '../form/ConfirmDialog.js'
import bus from '../utils/EventBus.js'
import Event from '../utils/EventNames.js'
import { MESSAGE_TYPE } from './AnimatedAlert.js'

export default function ListView(props = {}) {
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [unitsType, setUnitsType] = useState()

  let { 
    columns = [],
    context = {},
    showAdd = true,
    showEdit = true,
    showDelete = true,
    showFilters = true,   
    closeOnSave = true,
    confirmDelete = true,
    disabled = false, 
    addLabel = 'Add',
    scope,
    label,
    dialog,
    itemLabelFn,
    itemLabelProperty,
    dataMapper,
    showDownload,
    onAddDialogOpen,
    renderTopToolbar,
    topToolbarStart,
    topToolbarEnd,
    onEditDialogOpen,
    onDownload,
    onSave,
    onDelete,
    onClose,
    getData
  } = props

  

  const _getData = async() => {
    if(!disabled && getData) {
      setIsLoading(true)
      try {
        const result = await getData({ requestConfig: { headers:  { Authorization: user.idToken }}})  
        if(result.isSuccess) {
          setData(result.data)
        } else {
          bus.emit(Event.ALERT, { text: 'There was a problem retrieving data...', style: MESSAGE_TYPE.WARN, duration: 3000 })
        }
      } catch (e) {
        bus.emit(Event.ALERT, { text: `There was a problem retrieving data: ${e.getMessage()}`, style: MESSAGE_TYPE.ERROR, duration: 3000 })
      }
      setIsLoading(false)
    }
  }


  const _setUnitsType = (event) => {
    setUnitsType(event.target.value)
  }

  useEffect(() => {
    _getData()
    bus.on('onChange_unitsType', _setUnitsType)
    return () => {
      bus.removeListener('onChange_unitsType', _setUnitsType)
    }
  }, [props.disabled])
  //props.refresh, props.context
 
  const user = useContext(UserContext)
  
  let w = 0
  if(showEdit) {
    w += 40
  }
  if(showDownload) {
    w += 40
  }
  if(showDelete) {
    w += 40
  }
  columns.push({ header: '', id: 'actions', width: 100,
      Cell: ({row}) => {
        return (
          <Box sx={{ display: 'flex', gap: '0rem', width: `${w}px`, marginLeft: 'auto' }}>
            {(showEdit) && <Tooltip arrow placement="top" title="Edit" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14]} }] } }}>
              <IconButton onClick={() => {
                let mapper = dataMapper ? new dataMapper() : null
                let item = mapper ? mapper.toFormModel(row.original) : row.original
                setState(scope, item)
                
                let _context = {...context, item}
                if(onEditDialogOpen) {
                  onEditDialogOpen(item)
                } else if(dialog) {
                  setDialogMode('edit')
                  setDialogItem(item)
                  setDialogContext(_context)
                  setDialogOpen(true)
                }
                // forceUpdate()
                
                // setTimeout(() => { dispatch(scope, item)}, 2000)
              }}>
                <EditIcon />
              </IconButton>
            </Tooltip>}
            {showDownload && <Tooltip arrow placement="top" title="Download" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14]} }] } }}>
              <IconButton onClick={() => {
                let mapper = dataMapper ? new dataMapper() : null
                let item = mapper ? mapper.toFormModel(row.original) : row.original
                onDownload({item, context, mode: dialogMode, requestConfig: { headers:  { Authorization: user.idToken }}}) }
              }>
                <DownloadIcon />
              </IconButton>
            </Tooltip>}
            {showDelete && 
            <Tooltip arrow placement="top" title="Delete" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14]} }] } }}>
              <IconButton color="error" onClick={() => {
                  if(confirmDelete) {
                    setDialogMode('delete')
                    setDialogItem(row.original)
                    setDialogOpen(true)                   
                  }
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
          </Box>
        )
      }
  })
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()
  const [isDialogLoading, setIsDialogLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMode, setDialogMode] = useState("")
  const [dialogItem, setDialogItem] = useState({})
  const [dialogContext, setDialogContext] = useState({})
  const [isDirty, setIsDirty] = useState(false) 
  const [enableFilters, setEnableFilters] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})



  const closeDialog = () => {
    setDialogOpen(false)
    setIsDialogLoading(false)
    if(onClose) {
      onClose()
    }
  }

  const _onSave = async () => {
    if (onSave) {                
      try {
        setIsDialogLoading(true)
        let mapper = dataMapper ? new dataMapper() : null
        let state = getState(scope)
        let item = mapper ? mapper.toObject(state) : state

        // let item = mapper ? mapper.toObject(dialogItem) : dialogItem
        const result = await onSave({item, context, mode: dialogMode, requestConfig: { headers:  { Authorization: user.idToken }}})
        setIsDialogLoading(false)
        if(result.isSuccess) {
          if(dialogMode === 'add') {
            let newItem = {...dialogItem, id: result.data?.insertId}
            setDialogItem(newItem)
            // forceUpdate()
            dispatch(scope, newItem)
            let newDialogContext = {...dialogContext, item: newItem}
            setDialogContext(newDialogContext)
            bus.emit(Event.ALERT, { text: 'Added Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
          } else {
            dispatch(scope, item)
            // forceUpdate()
            bus.emit(Event.ALERT, { text: 'Saved Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
          }
          if(closeOnSave) {
            closeDialog()
          } else {
            setDialogMode('edit')
          }
          _getData()
        } else {
          bus.emit(Event.ALERT, { text: 'There was an issue saving the record.  Please try again later.', style: MESSAGE_TYPE.ERROR })
        }
        return result
      } catch (e) {
        console.log('Could not save record: ', e)
        bus.emit(Event.ALERT, {style:'warn', text: `"${getItemLabel()}" could not be saved at this time.  Please try again later.`})
      }
    }
  }

  const _onDelete = async () => {
    if (onDelete) {     
      try {
        setIsLoading(true)
        const result = await onDelete({item: dialogItem, context, mode: dialogMode, requestConfig: { headers:  { Authorization: user.idToken }}})
        
        if(result.isSuccess) {
          bus.emit(Event.ALERT, {style:'success', text: `"${getItemLabel()}" was deleted successfully`, duration: 5000})
        } else {
          bus.emit(Event.ALERT, {style:'warn', text: `"${getItemLabel()}" could not be deleted.  Please try again later.`})
        }
      } catch (e) {
        console.log('Could not delete record: ', e)
        bus.emit(Event.ALERT, {style:'warn', text: `"${getItemLabel()}" could not be deleted.  Please try again later.`})
      }
      await _getData()
      setIsLoading(false)
    }
  }

  let _dialog
  if(dialog) {
    _dialog = cloneElement(dialog, { ...props,
      onChangeItem: (item) => {
        setIsDirty(true)
        let mapper = dataMapper ? new dataMapper() : null
        let newItem = mapper ? mapper.toFormModel(item) : item
        setDialogItem(newItem)
      },
      setDirty: () => { 
        setIsDirty(true)
      },
      isLoading: isDialogLoading,
      item: dialogItem,
      context: dialogContext,
      scope: scope,
      mode: dialogMode,
      unitsType: unitsType,
      open: dialogOpen && (dialogMode === 'add' || dialogMode === 'edit'),
      onOpen: () => { },//dispatch(scope, dialogItem) },
      scroll: 'paper',
      onClose: closeDialog,
      getData: _getData,
      onAdd: _onSave,
      onEdit: _onSave,
      onSave: _onSave,
      onDelete: _onDelete,
      
    })
  }

  const getItemLabel = () => {
    let itemLabel = 'Record'
    if(itemLabelFn) {
      itemLabel = itemLabelFn(dialogItem)
    } else if (itemLabelProperty) {
      itemLabel = dialogItem[itemLabelProperty]
    } else if(dialogItem.name) {
      itemLabel = dialogItem.name
    } else if (dialogItem.id) {
      itemLabel = dialogItem.id
    }
    return itemLabel
  }

  let _confirmDeleteDialog =  <ConfirmDialog 
    isLoading={isDialogLoading}
    title={`Are you sure you want to delete "${getItemLabel()}"?`} 
    open={dialogOpen && dialogMode === "delete"} 
    onClickNo={()=>{
      setDialogOpen(false)
    }}
    onClickYes={async()=>{
      setIsDialogLoading(true)
      if(onDelete) {
        _onDelete()
      }
      closeDialog()
    }}
    yesLabel="Delete" 
    noLabel="Keep"
  />
  
  const toggleFilters = () => {
    setEnableFilters(!enableFilters)
  }

  const buttonStyle = { m: ".6em 1em 1em", height: "2.2em"}

  let tableRenderTopToolbar = ({ _table }) => {
    if(renderTopToolbar) {
      return renderTopToolbar({_table})
    } else {
      return (
        <Grid xs={12} container justifyContent="flex-end">
          {topToolbarStart}
          {showFilters && <Button disabled={disabled} variant={enableFilters ? 'contained' : 'outlined'} sx={buttonStyle} size="small" onClick={toggleFilters}><FilterListIcon/></Button>}
          {showAdd && disabled && <Tooltip title="Save to enable!" arrow placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [7, -14], }, }, ], }}}><span><Button disabled={disabled} variant="outlined" sx={{...buttonStyle, marginLeft: 0}} size="small" endIcon={<AddIcon />}>{addLabel}</Button></span></Tooltip>}
          {showAdd && !disabled && <Button variant="outlined" sx={buttonStyle} size="small" endIcon={<AddIcon />} onClick={() => {
            if(onAddDialogOpen) {
              onAddDialogOpen()
            } else if(dialog) {
              let mapper = dataMapper ? new dataMapper() : null
              let item = mapper ? mapper.toFormModel({}) : {}
              setState(scope, item)
              const dialogContext = {...context, item}
              setDialogMode('add')
              setDialogItem(item)
              setDialogContext(dialogContext)
              setDialogOpen(true)
            } else {
              let mapper = dataMapper ? new dataMapper() : null
              let item = mapper ? mapper.toFormModel({}) : {}
              _onSave({item, context, requestConfig: { headers:  { Authorization: user.idToken }}})
            }
          }}>
            {addLabel}
          </Button>}
          {topToolbarEnd}
        </Grid>
      )
    }
  }

  const listData = data ?? []

  return (      
    <>
      <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
        <Grid xs={12} md={12}>
          <Box>
            {label &&
              <h3 sx={{ padding: 0, margin: 0, fontSize: '14px', width:'fit-content' }}>{label}</h3>
            }
            <MaterialReactTable
              columns={columns}
              data={listData}
              state={{isLoading: props.isLoading || isLoading}}
              enableColumnActions={false}
              enableColumnFilters={ !disabled && enableFilters }
              initialState={{ showColumnFilters: !disabled }}
              enablePagination={listData.length > 10}
              enableBottomToolbar={listData.length > 10}
              enableSorting={!disabled}
              enableRowOrdering={false}
              muiTableContainerProps={{ sx: { maxHeight: 1000 } }}
              renderTopToolbar={ tableRenderTopToolbar }
            />
            {_dialog}
            {_confirmDeleteDialog}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}