import React, { useEffect, useReducer, useState } from 'react'
import { InputLabel, FormControl, MenuItem, Select as MuiSelect } from '@mui/material'
import bus from '../utils/EventBus'
import { getState, setState } from '../utils/State'
import { useIsMounted } from '../utils/ReactUtils'

export default function StatefulSelect(props = {}) {
    const [sx, setSx] = useState({})
    const [value, setValue] = useState('')
    const [, forceUpdate] = useReducer(x => x + 1, 0)
    const isMounted = useIsMounted()
    
    let { 
        onError,// = props.context?.onError,
        fieldErrors,// = props.context?.fieldErrors,
        onClose,
        onChange,
        fullWidth = true,
        className ='tis-form-field', 
        scope,
        id, context, label, labelSx, children, defaultValue, validator, inputProps, ...otherProps} = props  
    
    let owner = scope ? `${scope}.${id}` : id
    // const [value, setValue] = useState(getState(id, scope) ?? '')

    function defaultOnChange(event) {
        if(event.owner !== owner) { //only react to events you did not create
            let v = event?.target?.value
            setValue(v)
            // console.log(`[StatefulSelect] heard event "${scope ? `onChange_${scope}_${id}` : `onChange_${id}`}", setting state into "${id}" field: "${v}"`)
            
            setState(id, v, {owner, scope, bubble: event.bubble ?? true})
            setStyles(v)
            if(onChange) {
                onChange(event)
            }
        }
    }

    function defaultOnClose (event) {
        if(onError) {
            onError(getState(id, scope), id)
        }
        if(onClose) {
            onClose(event)
        }
    }

    const isEmpty = (value) => {
        return value === undefined || value === null || ('' + value).trim() === ''
    }

    useEffect(() => {
        // console.log(`[StatefulSelect ${owner}] useEffect called`)
        let eventName = scope ? `onChange_${scope}_${id}` : `onChange_${id}`
        if(isMounted) {
            // console.log(`[StatefulSelect ${owner}] is mounted`)
            let initialValue = defaultValue ?? getState(owner)
            if(initialValue) {
                // console.log('[StatefulSelect] setting default state: ', scope, id, initialValue)
                defaultOnChange({target: {value: initialValue}})
                // setState(id, defaultValue, {scope, bubble: false})
                // setValue(defaultValue)
                forceUpdate()
            } else {
                if(children && children.length) {
                    let defaultOption = children.find(o => o.props?.value === '')
                    if(defaultOption) {
                        setValue(defaultOption.props?.value) 
                    } else {
                        setValue(children[0].props?.value)
                    } 
                }   
            }
            bus.on(eventName, defaultOnChange)
        }
        setStyles(getState(id, scope), { target: true })
        return () => {
            bus.removeListener(eventName, defaultOnChange)
        }
    }, [])

    const setStyles = (value, options = {}) => {
        // console.log('select setStyles, value: ', value)
        const hasValue = !isEmpty(value)
        setSx({
            "& .MuiSelect-outlined": { 
                borderRadius: '4px',
                borderLeft: hasValue ? '.33em solid #77CC99' : '.33em solid transparent', 
                borderRight: hasValue ? '.33em solid #77CC99' : '.33em solid transparent', 
                backgroundColor: hasValue ? 'rgba(200, 255, 236, .4)': 'rgba(0,0,0,0)',
                // fontWeight: hasValue ? 700 : 400,
                color: 'rgba(0, 0, 0, 0.9)' 
            } 
        })
    }



    return (
        <>
            {children &&
            <FormControl fullWidth={fullWidth} className={className} error={fieldErrors?.[id]}>
                <InputLabel id={`${id}-label`} sx={labelSx} shrink={!isEmpty(getState(id, scope))}>{label}</InputLabel>
                <MuiSelect 
                    id = { id }
                    labelId = { `${id}-label` }
                    value = { value }
                    inputProps={ { ...inputProps } }
                    onChange={ defaultOnChange }
                    onClose={ defaultOnClose }
                    sx = { sx }
                    MenuProps={{
                        disableScrollLock: true
                    }}
                    { ...otherProps }
                >
                    {children}
                </MuiSelect>
            </FormControl>
            }
        </>
    )
}