import Grid from '@mui/material/Unstable_Grid2'
import { Box, MenuItem } from '@mui/material'
import DebugButton from '../utils/DebugButton'
import Select from '../form/StatefulSelect'
import TextField from '../form/StatefulTextField'
import { getState } from '../utils/State'
/**
 * ProductCategoryInfoForm
 * 
 * @param {*} props 
 * item: productCategoryInfoFormModel
 * onChangeItem:
 * 
 * @returns 
 **/
export default function ProductCategoryInfoForm(props = {}) {
    const {scope} = props

    return (
        <Box sx={{ mt: 0, minHeight: 200 }}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid xs={12} md={6}>
                    <TextField id="appearance" scope={scope} label="Appearance"/>
                </Grid>
                {/* <Grid xs={12} md={6}>
                    <TextField id="syrupAppearance" scope={scope} label="Syrup Appearance"/>
                </Grid> */}
                <Grid xs={12} md={6}>
                    <TextField id="odor" scope={scope} label="Odor"/>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="shelfLife" scope={scope} label="Shelf Life" type="number"/>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="shelfLifeUom" scope={scope} label="Shelf Life UOM">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="hours">Hours</MenuItem>
                        <MenuItem value="days">Days</MenuItem>
                        <MenuItem value="weeks">Weeks</MenuItem>
                        <MenuItem value="months">Months</MenuItem>
                        <MenuItem value="years">Years</MenuItem>
                    </Select>
                </Grid>
                
                <Grid xs={12} md={6}>
                    <Select id="isSensitiveWash" scope={scope} label="Sensitive/Wash">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="isMicrobiologicallySensitive" scope={scope} label="Microbiologically Sensitive">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="isPungent" scope={scope} label="Pungent">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsAllergens" scope={scope} label="Contains Allergens">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsJuice" scope={scope} label="Contains Juice">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsPulp" scope={scope} label="Contains Pulp">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsCaffeine" scope={scope} label="Contains Caffeine">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="isKosher" scope={scope} label="Kosher">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="isOrganic" scope={scope} label="Organic">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsGluten" scope={scope} label="Contains Gluten">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <Select id="containsPreservatives" scope={scope} label="Contains Preservatives">
                        <MenuItem value="">‎</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField id="storageTemperatureCelcius" type="temperature" scope={scope} format="0" label="Storage Temperature" />
                </Grid>
                {/* <Grid xs={12} md={6}>                                                        
                    <TextField id="ratioWater" scope={scope} label="Ratio Water (weight)"type="number" />
                </Grid> */}
            </Grid>
            <DebugButton name="CatInfo" log={() => getState(scope) } />
        </Box>
     )
}