import React from "react";

  function Divide(props) {
    return (
      <svg viewBox="0 0 24 24" {...props}>
        <path d="M6.34277 12L17.6565 12" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
        <circle cx="12" cy="16.9498" r="1.5" transform="rotate(45 12 16.9498)" fill="currentColor"/>
        <circle cx="12" cy="7.05024" r="1.5" transform="rotate(45 12 7.05024)" fill="currentColor"/>
      </svg>
    );
  }
  export default Divide;