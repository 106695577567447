import { useState, useContext } from 'react'
import { UserContext } from '../App'
import { IngredientFormDialog } from './IngredientFormDialog'
import ListView from '../layout/ListView'
import IngredientMapper from '../mapper/IngredientMapper'
import { ApiGateway } from '../config/config'
import moment from 'moment'
import axios, { isSuccess } from '../utils/AxiosClient'
import { useNavigate } from 'react-router-dom'

export default function ListFormulas(props = {}) {
    const user = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    // const getData = async() => {
    //   let rows = []
    //   setIsLoading(true)
    //   let response = await axios.get(ApiGateway.ingredient, {headers: {Authorization: user.idToken}})
    //   if(isSuccess(response)) {
    //     rows = response.data?.data
    //   }
    //   setRowData(rows)
    //   setIsLoading(false)
    // }

    const columnsData = [
      { accessorKey: 'id', header: 'ID', size: 50 },
      { accessorKey: 'name', header: 'Name', size: 100 },
      { accessorKey: 'sCode', header: 'S-Code', size: 50 },
      { accessorKey: 'specNumber', header: 'Spec Number', size: 50 },
      { accessorKey: 'formOfIngredient', header: 'Form of Ingredient', filterVariant: 'multi-select', filterSelectOptions: ["Liquid", "Powder", "Leaves"], size: 160 },
      { accessorFn: (row) => row.lastModifiedBy, header: 'Last Modified By', size: 50 },
      { accessorFn: (row) => moment(row.lastModifiedDate).format('MM/DD/yyyy'), header: 'Date Last Modified', size: 50 }
    ]
    // const [rowData, setRowData] = useState([])

    // useEffect(() => {
    //   getData()
    // }, [])

    return (
      <ListView 
          columns = {columnsData}
          isLoading = {isLoading}

          getData = { async ({ requestConfig }) => {
            const response = await axios.get(ApiGateway.ingredient, requestConfig)
            return { isSuccess: isSuccess(response), data: response.data?.data }
          }}
          
          dataMapper = { IngredientMapper }
          onEditDialogOpen = {(ingredient) => navigate(`/ingredients/edit/${ingredient.id}`)}
          
          onDelete= { async ({item, requestConfig}) => {
            const response = await axios.delete(`${ApiGateway.ingredient}/${item.id}`, requestConfig) 
            return { isSuccess: isSuccess(response) }
          }}

          dialog = {<IngredientFormDialog />}
      />
    )
}