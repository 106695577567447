import MenuIcon from '@mui/icons-material/Menu'
import { UserContext } from '../App'
import { useContext } from 'react'

export default function Header(props = {}) {
    const user = useContext(UserContext)
    const pushMenu = () => {
        var body = document.body;
        if(body.clientWidth > 768){
            if(body.className.indexOf('sidebar-collapse') === -1){
                body.className += ' sidebar-collapse';
            } else {
                body.className = body.className.replace(' sidebar-collapse', '');
            }
        }else{
            if (body.className.indexOf('sidebar-open') === -1) {
                body.className += ' sidebar-open';
            } else {
                body.className = body.className.replace(' sidebar-open', '');
            }
        }
    }

  
    return (
        <header className="main-header">
            <a href="/" className="logo">
            {props.appName}
            </a>
            <nav className="navbar navbar-static-top" role="navigation">
                <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={pushMenu}>
                    <MenuIcon/><span className="sr-only"> Toggle navigation</span>
                </a>
                <span style={{float:"right", color:"white", paddingRight: "2em"}}>Welcome {`${user.firstName} ${user.lastName}`}!</span>
                {/*<div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li>
                            <a href="#" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a>
                        </li>
                    </ul>
                </div>*/}
            </nav>
        </header>
    )
}