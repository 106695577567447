import { default as React } from 'react'
import IngredientIcon from '@mui/icons-material/LocalDrink';
import ListIngredients from './ListIngredients'
import Page from '../layout/Page'

export default function ListIngredientsPage (props = {}) {
  const breadcrumb = <span><IngredientIcon/> Ingredients</span>

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={false}>
      <ListIngredients />
    </Page>
  )
}
