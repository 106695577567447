import AbstractFormModel, { getValues } from '../AbstractFormModel'
import ProductFormulaDetailFormModel from './ProductFormulaDetailFormModel'
import ProductCategoryInfoFormModel from './ProductCategoryInfoFormModel'

export default class FormulaFormModel extends AbstractFormModel {
    id = ''
    commercializations = []

    constructor(formula = {}) {
        super()
        Object.assign(this, new ProductFormulaDetailFormModel(formula), new ProductCategoryInfoFormModel(formula), formula)
    }

    toObject(refs) {
        const values = getValues(refs)
        const productFormulaDetailFormModel = new ProductFormulaDetailFormModel()
        const productCategoryInfoFormModel = new ProductCategoryInfoFormModel()
        return {
            id: this.numberSerializer.toObject(values.id),
            commercializations: values.commercializations,
            ...productFormulaDetailFormModel.toObject(refs),
            ...productCategoryInfoFormModel.toObject(refs)
        }
    }
}