import { booleanSerializer, numberSerializer } from '../utils/Serializer'

export default class ProductCategoryInfoMapper {
    toFormModel(item) {
        return {
            // id: numberSerializer.toString(item.id) ?? '',
            appearance: item.appearance ?? '',
            odor: item.odor ?? '',
            shelfLife: item.shelfLife ?? '',
            shelfLifeUom: item.shelfLifeUom ?? '',
            isSensitiveWash: booleanSerializer.toString(item.isSensitiveWash),
            isMicrobiologicallySensitive: booleanSerializer.toString(item.isMicrobiologicallySensitive),
            isPungent: booleanSerializer.toString(item.isPungent),
            containsAllergens: booleanSerializer.toString(item.containsAllergens),
            containsJuice: booleanSerializer.toString(item.containsJuice),
            containsPulp: booleanSerializer.toString(item.containsPulp),
            containsCaffeine: booleanSerializer.toString(item.containsCaffeine),
            isKosher: booleanSerializer.toString(item.isKosher),
            isOrganic: booleanSerializer.toString(item.isOrganic),
            containsGluten: booleanSerializer.toString(item.containsGluten),
            containsPreservatives: booleanSerializer.toString(item.containsPreservatives),
            storageTemperatureCelcius: numberSerializer.toString(item.storageTemperatureCelcius)
        }
    }
    
    toObject (formModel) {
        return {
            // id: numberSerializer.toObject(formModel.id),
            appearance: formModel.appearance,
            odor: formModel.odor,
            shelfLife: formModel.shelfLife,
            shelfLifeUom: formModel.shelfLifeUom,
            isSensitiveWash: booleanSerializer.toObject(formModel.isSensitiveWash),
            isMicrobiologicallySensitive: booleanSerializer.toObject(formModel.isMicrobiologicallySensitive),
            isPungent: booleanSerializer.toObject(formModel.isPungent),
            containsAllergens: booleanSerializer.toObject(formModel.containsAllergens),
            containsJuice: booleanSerializer.toObject(formModel.containsJuice),
            containsPulp: booleanSerializer.toObject(formModel.containsPulp),
            containsCaffeine: booleanSerializer.toObject(formModel.containsCaffeine),
            isKosher: booleanSerializer.toObject(formModel.isKosher),
            isOrganic: booleanSerializer.toObject(formModel.isOrganic),
            containsGluten: booleanSerializer.toObject(formModel.containsGluten),
            containsPreservatives: booleanSerializer.toObject(formModel.containsPreservatives),
            storageTemperatureCelcius: numberSerializer.toObject(formModel.storageTemperatureCelcius)
        }
    }
}