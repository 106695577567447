import { numberSerializer } from '../utils/Serializer'

export default class OutputDocumentMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            locale: item.locale ?? 'en-US',
            template: item.template ?? '',
            issuedDate: item.issuedDate ?? '',
            name: item.name ?? ''
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            locale: formModel.locale,
            template: formModel.template,
            issuedDate: formModel.issuedDate,
            name: formModel.name
        }
    }
}