export const SyrupFields = [
    //Inputs
    {label:'Acesulfame K',id:'Syrup_acesulfameKMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Acidity (as Citric Acid)',id:'Syrup_acidityAsCitricAcid',uom:'',category:'SyrupSpecs'},
    {label:'Apparent density at 20 deg C',includeTolerances: true, type:'density', id:'Syrup_apparentDensityAt20DegC',uom:'kg/L',category:'SyrupSpecs',format:'#,###.#####'},
    {label:'Apparent density at 20 deg C - Confirmed',id:'Syrup_apparentDensityAt20DegCConfirmed',uom:'$DEN$',category:'SyrupSpecs',type:"density"},
    {label:'Appearance',id:'Syrup_appearance',uom:'',category:'SyrupSpecs'},
    {label:'ASPARTAME_MG_L',id:'Syrup_aspartameMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'BENZOIC_ACID_MG_KG',id:'Syrup_benzoicAcidMgKg',uom:'mg/kg',category:'SyrupSpecs'},
    {label:'BENZOIC_ACID_MG_L',id:'Syrup_benzoicAcidMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Brix, Apparent Fresh',id:'Syrup_brixApparentFresh',uom:'',category:'SyrupSpecs'},
    {label:'Brix, Apparent Invert',id:'Syrup_brixApparentInvert',uom:'',category:'SyrupSpecs'},
    {label:'Brix, Refractometer',id:'Syrup_brixRefractometer',uom:'',category:'SyrupSpecs'},
    {label:'Brix, Refractometer-Corr, for acid',id:'Syrup_brixRefractometerCorrForAcid',uom:'',category:'SyrupSpecs'},
    {label:'CAFFEINE_MG_L',id:'Syrup_caffeineMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Calories, Australia kcal/100mL',id:'Syrup_caloriesAustraliaKcal100ml',uom:'',category:'SyrupSpecs'},
    {label:'Calories, Codex kcal/100mL',id:'Syrup_caloriesCodexKcal100ml',uom:'',category:'SyrupSpecs'},
    {label:'Calories, EU kcal/100mL',id:'Syrup_caloriesEuKcal100ml',uom:'',category:'SyrupSpecs'},
    {label:'Calories, South Africa kcal/100mL',id:'Syrup_caloriesSouthAfricaKcal100ml',uom:'',category:'SyrupSpecs'},
    {label:'Color',id:'Syrup_color',uom:'',category:'SyrupSpecs'},
    {label:'Corrosivity Aluminum Value',id:'Syrup_corrosivityAluminumValue',uom:'',category:'SyrupSpecs'},
    {label:'Corrosivity Steel Value',id:'Syrup_corrosivitySteelValue',uom:'',category:'SyrupSpecs'},
    {label:'CYCLAMIC_ACID_MG_L',id:'Syrup_cyclamicAcidMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Energy, kJ Australia kJ/100mL',id:'Syrup_energyKjAustraliaKj100ml',uom:'',category:'SyrupSpecs'},
    {label:'Energy, kJ Codex kJ/100mL',id:'Syrup_energyKjCodexKj100ml',uom:'',category:'SyrupSpecs'},
    {label:'Energy, kJ EU kJ/100mL',id:'Syrup_energyKjEuKj100ml',uom:'',category:'SyrupSpecs'},
    {label:'Energy, kJ kJ/100mL',id:'Syrup_energyKjKj100ml',uom:'',category:'SyrupSpecs'},
    {label:'Energy, kJ South Africa kJ/100mL',id:'Syrup_energyKjSouthAfricaKj100ml',uom:'',category:'SyrupSpecs'},
    {label:'Flashpoint',id:'Syrup_flashpoint',uom:'',category:'SyrupSpecs'},
    {label:'General Notes',id:'Syrup_generalNotes',uom:'',category:'SyrupSpecs'},
    {label:'Glycerin %w/w',id:'Syrup_glycerinPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Isopropyl alcohol %w/w',id:'Syrup_isopropylAlcoholPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    // {label:'Juice - Acai Solids',id:'Syrup_juiceAcaiSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Acerola Solids',id:'Syrup_juiceAcerolaSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Apple Solids',id:'Syrup_juiceAppleSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Apple Solids (NFC)',id:'Syrup_juiceAppleSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Apricot Solids',id:'Syrup_juiceApricotSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Aronia Solids',id:'Syrup_juiceAroniaSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Banana Solids',id:'Syrup_juiceBananaSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Banana Solids (NFC)',id:'Syrup_juiceBananaSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Bilberry Solids',id:'Syrup_juiceBilberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Blackberry Solids',id:'Syrup_juiceBlackberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Blueberry Solids',id:'Syrup_juiceBlueberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Blueberry Solids (NFC)',id:'Syrup_juiceBlueberrySolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cabbage Solids',id:'Syrup_juiceCabbageSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cantalopmelon Solids',id:'Syrup_juiceCantalopmelonSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Carrot Solids',id:'Syrup_juiceCarrotSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cashew Solids',id:'Syrup_juiceCashewSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Celery Solids',id:'Syrup_juiceCelerySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cherry Dark Sweet Solids',id:'Syrup_juiceCherryDarkSweetSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cherry Red Sour Solids',id:'Syrup_juiceCherryRedSourSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Coconut Solids',id:'Syrup_juiceCoconutSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Coconut Solids (NFC)',id:'Syrup_juiceCoconutSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Cranberry Solids',id:'Syrup_juiceCranberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Currant Black Solids',id:'Syrup_juiceCurrantBlackSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Currant Red Solids',id:'Syrup_juiceCurrantRedSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Dragon Fruit Solids (NFC)',id:'Syrup_juiceDragonFruitSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Elderberry Solids',id:'Syrup_juiceElderberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Feijoa Solids (NFC)',id:'Syrup_juiceFeijoaSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Ginger Solids',id:'Syrup_juiceGingerSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Grape Solids',id:'Syrup_juiceGrapeSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Grapefruit Solids',id:'Syrup_juiceGrapefruitSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Green Pepper Solids',id:'Syrup_juiceGreenPepperSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Guava Solids',id:'Syrup_juiceGuavaSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Guava Solids (NFC)',id:'Syrup_juiceGuavaSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Japan Apr Solids',id:'Syrup_juiceJapanAprSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Kiwi Solids',id:'Syrup_juiceKiwiSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lemon Solids',id:'Syrup_juiceLemonSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lemon Solids (NFC)',id:'Syrup_juiceLemonSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lettuce Solids',id:'Syrup_juiceLettuceSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lime Solids',id:'Syrup_juiceLimeSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lime Solids (NFC)',id:'Syrup_juiceLimeSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lingonberry Solids',id:'Syrup_juiceLingonberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Lychee Solids',id:'Syrup_juiceLycheeSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Mandarin Solids',id:'Syrup_juiceMandarinSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Mango Solids',id:'Syrup_juiceMangoSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Mango Solids (NFC)',id:'Syrup_juiceMangoSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Mexican Hawthorn Solids',id:'Syrup_juiceMexicanHawthornSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Orange Solids',id:'Syrup_juiceOrangeSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Papaya Solids',id:'Syrup_juicePapayaSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Parsely Solids',id:'Syrup_juiceParselySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Passionfruit Solids',id:'Syrup_juicePassionfruitSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Passionfruit Solids (NFC)',id:'Syrup_juicePassionfruitSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Peach Fermented Solids',id:'Syrup_juicePeachFermentedSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Peach Solids',id:'Syrup_juicePeachSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Peach Solids (NFC)',id:'Syrup_juicePeachSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Pear Solids',id:'Syrup_juicePearSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Pear Solids (NFC)',id:'Syrup_juicePearSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Pineapple Solids',id:'Syrup_juicePineappleSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Plum Solids',id:'Syrup_juicePlumSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Plum Solids (NFC)',id:'Syrup_juicePlumSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Pomegranate Solids',id:'Syrup_juicePomegranateSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Prune Solids',id:'Syrup_juicePruneSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Raspberry Red Solids',id:'Syrup_juiceRaspberryRedSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Raspberry Red Solids (NFC)',id:'Syrup_juiceRaspberryRedSolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Red Beet Solids',id:'Syrup_juiceRedBeetSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Rhubarb Solids',id:'Syrup_juiceRhubarbSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Spinach Solids',id:'Syrup_juiceSpinachSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Strawberry Solids',id:'Syrup_juiceStrawberrySolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Strawberry Solids (NFC)',id:'Syrup_juiceStrawberrySolidsNfc',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Tangerine Solids',id:'Syrup_juiceTangerineSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Tomato Solids',id:'Syrup_juiceTomatoSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Watermelon Solids',id:'Syrup_juiceWatermelonSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - WESOS Solids',id:'Syrup_juiceWesosSolids',uom:'',category:'SyrupSpecs'},
    // {label:'Juice - Yuzu Solids',id:'Syrup_juiceYuzuSolids',uom:'',category:'SyrupSpecs'},
    {label:'Odor',id:'Syrup_odor',uom:'',category:'SyrupSpecs'},
    {label:'Packaging / Processing Notes',id:'Syrup_packagingProcessingNotes',uom:'',category:'SyrupSpecs'},
    {label:'pH',id:'Syrup_ph',uom:'',category:'SyrupSpecs'},
    {label:'pH before Sterilization',id:'Syrup_phBeforeSterilization',uom:'',category:'SyrupSpecs'},
    {label:'PHOSPHORIC_ACID_MG_L',id:'Syrup_phosphoricAcidMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Physical Characteristics',id:'Syrup_physicalCharacteristics',uom:'',category:'SyrupSpecs'},
    {label:'Preferred Shelf life',id:'Syrup_preferredShelfLife',uom:'',category:'SyrupSpecs'},
    {label:'Preferred Storage Conditions',id:'Syrup_preferredStorageConditions',uom:'',category:'SyrupSpecs'},
    {label:'Propylene glycol %w/w',id:'Syrup_propyleneGlycolPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Refractometer Brix reading at 20 °C',id:'Syrup_refractometerBrixReadingAt20C',uom:'',category:'SyrupSpecs',type:"density"},
    {label:'SACCHARIN_IMIDE_MG_L',id:'Syrup_saccharinImideMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'Solids (SLDS)',id:'Syrup_solidsSlds',uom:'',category:'SyrupSpecs'},
    {label:'SORBIC_ACID_MG_KG',id:'Syrup_sorbicAcidMgKg',uom:'',category:'SyrupSpecs'},
    {label:'SORBIC_ACID_MG_L',id:'Syrup_sorbicAcidMgL',uom:'mg/kg',category:'SyrupSpecs'},
    {label:'STEVIOLEQUIV_MAX_MG_L',id:'Syrup_steviolequivMaxMgL',uom:'mg/L',category:'SyrupSpecs'},
    {label:'SUCRALOSE_MG_L',id:'Syrup_sucraloseMgL',uom:'',category:'SyrupSpecs'},
    {label:'SULFUR_DIOXIDE_MG_KG',id:'Syrup_sulfurDioxideMgKg',uom:'mg/kg',category:'SyrupSpecs'},
    {label:'Sust Combust Test Method',id:'Syrup_sustCombustTestMethod',uom:'',category:'SyrupSpecs'},
    {label:'Sust Combust Value',id:'Syrup_sustCombustValue',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Acidity (as Citric Acid) %w/w (HFSS-42)',id:'Syrup_syrupAcidityAsCitricAcidPercentWWHfss42',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Syrup Acidity (as Citric Acid) %w/w (HFSS-55)',id:'Syrup_syrupAcidityAsCitricAcidPercentWWHfss55',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Syrup Additional Instructions (HFSS-42)',id:'Syrup_syrupAdditionalInstructionsHfss42',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Additional Instructions (HFSS-55)',id:'Syrup_syrupAdditionalInstructionsHfss55',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Apparent Fresh (HFSS-42)',id:'Syrup_syrupBrixApparentFreshHfss42',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Apparent Fresh (HFSS-55)',id:'Syrup_syrupBrixApparentFreshHfss55',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Refractometer (HFSS-42)',id:'Syrup_syrupBrixRefractometerHfss42',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Refractometer (HFSS-55)',id:'Syrup_syrupBrixRefractometerHfss55',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Refractometer Corrected (HFSS-42)',id:'Syrup_syrupBrixRefractometerCorrectedHfss42',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Brix, Refractometer Corrected (HFSS-55)',id:'Syrup_syrupBrixRefractometerCorrectedHfss55',uom:'',category:'SyrupSpecs'},
    {label:'Syrup Density (HFSS-42)',id:'Syrup_syrupDensityHfss42',uom:'$DEN$',category:'SyrupSpecs',type:"density"},
    {label:'Syrup Density (HFSS-55)',id:'Syrup_syrupDensityHfss55',uom:'$DEN$',category:'SyrupSpecs',type:"density"},
    {label:'Syrup Sweetener Dry Weight (HFSS-42)',id:'Syrup_syrupSweetenerDryWeightHfss42',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Syrup Sweetener Dry Weight (HFSS-55)',id:'Syrup_syrupSweetenerDryWeightHfss55',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Syrup Syrup Weight (HFSS-42)',id:'Syrup_syrupSyrupWeightHfss42',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Syrup Syrup Weight (HFSS-55)',id:'Syrup_syrupSyrupWeightHfss55',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Syrup Volume (HFSS-42)',id:'Syrup_syrupVolumeHfss42',uom:'$VOL$',category:'SyrupSpecs',type:"volume"},
    {label:'Syrup Volume (HFSS-55)',id:'Syrup_syrupVolumeHfss55',uom:'$VOL$',category:'SyrupSpecs',type:"volume"},
    {label:'Syrup Water Weight (HFSS-42)',id:'Syrup_syrupWaterWeightHfss42',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Syrup Water Weight (HFSS-55)',id:'Syrup_syrupWaterWeightHfss55',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Tariff Code – Harmonized',id:'Syrup_tariffCodeHarmonized',uom:'',category:'SyrupSpecs'},
    {label:'Tea Solids (%w/w)',id:'Syrup_teaSolidsPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Titr. Acidity (as Citric) %w/v',id:'Syrup_titrAcidityAsCitricPercentWV',uom:'%w/v',category:'SyrupSpecs'},
    {label:'Titr. Acidity (as Citric) %w/w',id:'Syrup_titrAcidityAsCitricPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Total Volume',id:'Syrup_totalVolume',uom:'$VOL$',category:'SyrupSpecs',type:"volume"},
    {label:'Total Weight',id:'Syrup_totalWeight',uom:'$WGT$',category:'SyrupSpecs',type:"weight"},
    {label:'Transportation Class - Flammable Flag',id:'Syrup_transportationClassFlammableFlag',uom:'',category:'SyrupSpecs'},
    {label:'Transportation Class - Non Hazard Flag',id:'Syrup_transportationClassNonHazardFlag',uom:'',category:'SyrupSpecs'},
    {label:'Transportation UN Class',id:'Syrup_transportationUnClass',uom:'',category:'SyrupSpecs'},
    {label:'Triacetin %w/w',id:'Syrup_triacetinPercentWW',uom:'%w/w',category:'SyrupSpecs'},
    {label:'Water / Syrup Ratio (/1.0) %v/v',id:'Syrup_waterSyrupRatio10PercentVV',uom:'To 1',category:'SyrupSpecs'},
    


]

export const BeverageFields = [
    //Inputs
    {label:'Acesulfame K',id:'Beverage_acesulfameKMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'Acidity (as Citric Acid)',id:'Beverage_acidityAsCitricAcid',uom:'',category:'BeverageSpecs'},
    {label:'Apparent Density @ 20° C (kg/L)',id:'Beverage_apparentDensityAt20CKgL',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Apparent density at 20 deg C',id:'Beverage_apparentDensityAt20DegC',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Apparent density at 20 deg C - Confirmed',id:'Beverage_apparentDensityAt20DegCConfirmed',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Appearance',id:'Beverage_appearance',uom:'',category:'BeverageSpecs'},
    {label:'ASPARTAME_MG_L',id:'Beverage_aspartameMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'BENZOIC_ACID_MG_KG',id:'Beverage_benzoicAcidMgKg',uom:'mg/kg',category:'BeverageSpecs'},
    {label:'BENZOIC_ACID_MG_L',id:'Beverage_benzoicAcidMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'Beverage Acidity (as Citric Acid) %w/v (HFSS-42)',id:'Beverage_beverageAcidityAsCitricAcidPercentWVHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Acidity (as Citric Acid) %w/v (HFSS-55)',id:'Beverage_beverageAcidityAsCitricAcidPercentWVHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Acidity (as Citric Acid) %w/w (HFSS-42)',id:'Beverage_beverageAcidityAsCitricAcidPercentWWHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Acidity (as Citric Acid) %w/w (HFSS-55)',id:'Beverage_beverageAcidityAsCitricAcidPercentWWHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Additional Instructions (HFSS-42)',id:'Beverage_beverageAdditionalInstructionsHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Additional Instructions (HFSS-55)',id:'Beverage_beverageAdditionalInstructionsHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Apparent Fresh (HFSS-42)',id:'Beverage_beverageBrixApparentFreshHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Apparent Fresh (HFSS-55)',id:'Beverage_beverageBrixApparentFreshHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Refractometer (HFSS-42)',id:'Beverage_beverageBrixRefractometerHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Refractometer (HFSS-55)',id:'Beverage_beverageBrixRefractometerHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Refractometer Corrected (HFSS-42)',id:'Beverage_beverageBrixRefractometerCorrectedHfss42',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Brix, Refractometer Corrected (HFSS-55)',id:'Beverage_beverageBrixRefractometerCorrectedHfss55',uom:'',category:'BeverageSpecs'},
    {label:'Beverage Density (HFSS-42)',id:'Beverage_beverageDensityHfss42',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Beverage Density (HFSS-55)',id:'Beverage_beverageDensityHfss55',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Beverage Density kg/L',id:'Beverage_beverageDensityKgL',uom:'$DEN$',category:'BeverageSpecs',type:"density"},
    {label:'Beverage Sweetener Dry Weight (HFSS-42)',id:'Beverage_beverageSweetenerDryWeightHfss42',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Sweetener Dry Weight (HFSS-55)',id:'Beverage_beverageSweetenerDryWeightHfss55',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Syrup Weight (HFSS-42)',id:'Beverage_beverageSyrupWeightHfss42',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Syrup Weight (HFSS-55)',id:'Beverage_beverageSyrupWeightHfss55',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Volume - Desired L',id:'Beverage_beverageVolumeDesiredL',uom:'$VOL$',category:'BeverageSpecs',type:"volume"},
    {label:'Beverage Volume (HFSS-42)',id:'Beverage_beverageVolumeHfss42',uom:'$VOL$',category:'BeverageSpecs',type:"volume"},
    {label:'Beverage Volume (HFSS-55)',id:'Beverage_beverageVolumeHfss55',uom:'$VOL$',category:'BeverageSpecs',type:"volume"},
    {label:'Beverage Water Weight (HFSS-42)',id:'Beverage_beverageWaterWeightHfss42',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Water Weight (HFSS-55)',id:'Beverage_beverageWaterWeightHfss55',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Water Weight Difference - Theoretical KG',id:'Beverage_beverageWaterWeightDifferenceTheoreticalKg',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Weight (HFSS-42)',id:'Beverage_beverageWeightHfss42',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Beverage Weight (HFSS-55)',id:'Beverage_beverageWeightHfss55',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Brix, Apparent Fresh',id:'Beverage_brixApparentFresh',uom:'',category:'BeverageSpecs'},
    {label:'Brix, Apparent Invert',id:'Beverage_brixApparentInvert',uom:'',category:'BeverageSpecs'},
    {label:'Brix, Refractometer',id:'Beverage_brixRefractometer',uom:'',category:'BeverageSpecs'},
    {label:'Brix, Refractometer-Corr, for acid',id:'Beverage_brixRefractometerCorrForAcid',uom:'',category:'BeverageSpecs'},
    {label:'CAFFEINE_MG_L',id:'Beverage_caffeineMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'Calories, Australia kcal/100mL',id:'Beverage_caloriesAustraliaKcal100ml',uom:'',category:'BeverageSpecs'},
    {label:'Calories, Codex kcal/100mL',id:'Beverage_caloriesCodexKcal100ml',uom:'',category:'BeverageSpecs'},
    {label:'Calories, EU kcal/100mL',id:'Beverage_caloriesEuKcal100ml',uom:'',category:'BeverageSpecs'},
    {label:'Calories, South Africa kcal/100mL',id:'Beverage_caloriesSouthAfricaKcal100ml',uom:'',category:'BeverageSpecs'},
    {label:'Carbonation',id:'Beverage_carbonation',uom:'',category:'BeverageSpecs'},
    {label:'Color',id:'Beverage_color',uom:'',category:'BeverageSpecs'},
    {label:'Compliance Requirements',id:'Beverage_complianceRequirements',uom:'',category:'BeverageSpecs'},
    {label:'Corrosivity Aluminum Value',id:'Beverage_corrosivityAluminumValue',uom:'',category:'BeverageSpecs'},
    {label:'Corrosivity Steel Value',id:'Beverage_corrosivitySteelValue',uom:'',category:'BeverageSpecs'},
    {label:'COSD Notes',id:'Beverage_cosdNotes',uom:'',category:'BeverageSpecs'},
    {label:'CYCLAMIC_ACID_MG_L',id:'Beverage_cyclamicAcidMgL',uom:'',category:'BeverageSpecs'},
    {label:'Energy, kJ Australia kJ/100mL',id:'Beverage_energyKjAustraliaKj100ml',uom:'',category:'BeverageSpecs'},
    {label:'Energy, kJ Codex kJ/100mL',id:'Beverage_energyKjCodexKj100ml',uom:'',category:'BeverageSpecs'},
    {label:'Energy, kJ EU kJ/100mL',id:'Beverage_energyKjEuKj100ml',uom:'',category:'BeverageSpecs'},
    {label:'Energy, kJ kJ/100mL',id:'Beverage_energyKjKj100ml',uom:'',category:'BeverageSpecs'},
    {label:'Energy, kJ South Africa kJ/100mL',id:'Beverage_energyKjSouthAfricaKj100ml',uom:'',category:'BeverageSpecs'},
    {label:'Flashpoint',id:'Beverage_flashpoint',uom:'',category:'BeverageSpecs'},
    {label:'General Notes',id:'Beverage_generalNotes',uom:'',category:'BeverageSpecs'},
    {label:'Glycerin %w/w',id:'Beverage_glycerinPercentWW',uom:'',category:'BeverageSpecs'},
    {label:'Isopropyl alcohol %w/w',id:'Beverage_isopropylAlcoholPercentWW',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Acai Solids',id:'Beverage_juiceAcaiSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Acerola Solids',id:'Beverage_juiceAcerolaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Acerola Solids (NFC)',id:'Beverage_juiceAcerolaSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Aloe Solids',id:'Beverage_juiceAloeSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Apple Solids',id:'Beverage_juiceAppleSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Apple Solids (NFC)',id:'Beverage_juiceAppleSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Apricot Solids',id:'Beverage_juiceApricotSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Aronia Solids',id:'Beverage_juiceAroniaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Banana Solids',id:'Beverage_juiceBananaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Banana Solids (NFC)',id:'Beverage_juiceBananaSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Bilberry Solids',id:'Beverage_juiceBilberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Blackberry Solids',id:'Beverage_juiceBlackberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Blueberry Solids',id:'Beverage_juiceBlueberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Blueberry Solids (NFC)',id:'Beverage_juiceBlueberrySolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cabbage Solids',id:'Beverage_juiceCabbageSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cantalopmelon Solids',id:'Beverage_juiceCantalopmelonSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Carrot Solids',id:'Beverage_juiceCarrotSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cashew Solids',id:'Beverage_juiceCashewSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Celery Solids',id:'Beverage_juiceCelerySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cherry Dark Sweet Solids',id:'Beverage_juiceCherryDarkSweetSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cherry Dark Sweet Solids (NFC)',id:'Beverage_juiceCherryDarkSweetSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cherry Red Sour Solids',id:'Beverage_juiceCherryRedSourSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cherry Red Sour Solids (NFC)',id:'Beverage_juiceCherryRedSourSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Coconut Solids',id:'Beverage_juiceCoconutSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Coconut Solids (NFC)',id:'Beverage_juiceCoconutSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cranberry Solids',id:'Beverage_juiceCranberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cranberry Solids (NFC)',id:'Beverage_juiceCranberrySolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Cucumber Solids (NFC)',id:'Beverage_juiceCucumberSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Currant Black Solids',id:'Beverage_juiceCurrantBlackSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Currant Black Solids (NFC)',id:'Beverage_juiceCurrantBlackSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Currant Red Solids',id:'Beverage_juiceCurrantRedSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Date Solids',id:'Beverage_juiceDateSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Dragon Fruit Solids (NFC)',id:'Beverage_juiceDragonFruitSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Elderberry Solids',id:'Beverage_juiceElderberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Feijoa Solids (NFC)',id:'Beverage_juiceFeijoaSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Ginger Solids',id:'Beverage_juiceGingerSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Grape Solids',id:'Beverage_juiceGrapeSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Grapefruit Solids',id:'Beverage_juiceGrapefruitSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Grapefruit Solids (NFC)',id:'Beverage_juiceGrapefruitSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Green Pepper Solids',id:'Beverage_juiceGreenPepperSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Guanabana Solids',id:'Beverage_juiceGuanabanaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Guava Solids',id:'Beverage_juiceGuavaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Guava Solids (NFC)',id:'Beverage_juiceGuavaSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Japan Apr Solids',id:'Beverage_juiceJapanAprSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Kale Solids (NFC)',id:'Beverage_juiceKaleSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Kiwi Solids',id:'Beverage_juiceKiwiSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lemon Solids',id:'Beverage_juiceLemonSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lemon Solids (NFC)',id:'Beverage_juiceLemonSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lettuce Solids',id:'Beverage_juiceLettuceSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lime Solids',id:'Beverage_juiceLimeSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lime Solids (NFC)',id:'Beverage_juiceLimeSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lingonberry Solids',id:'Beverage_juiceLingonberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lychee Solids',id:'Beverage_juiceLycheeSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Lychee Solids (NFC)',id:'Beverage_juiceLycheeSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Mandarin Solids',id:'Beverage_juiceMandarinSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Mango Solids',id:'Beverage_juiceMangoSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Mango Solids (NFC)',id:'Beverage_juiceMangoSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Mexican Hawthorn Solids',id:'Beverage_juiceMexicanHawthornSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Mosambi Solids',id:'Beverage_juiceMosambiSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Orange Solids',id:'Beverage_juiceOrangeSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Orange Solids (NFC)',id:'Beverage_juiceOrangeSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Papaya Solids',id:'Beverage_juicePapayaSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Parsely Solids',id:'Beverage_juiceParselySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Passionfruit Solids',id:'Beverage_juicePassionfruitSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Passionfruit Solids (NFC)',id:'Beverage_juicePassionfruitSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Peach Fermented Solids',id:'Beverage_juicePeachFermentedSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Peach Solids',id:'Beverage_juicePeachSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Peach Solids (NFC)',id:'Beverage_juicePeachSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Pear Solids',id:'Beverage_juicePearSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Pear Solids (NFC)',id:'Beverage_juicePearSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Pineapple Solids',id:'Beverage_juicePineappleSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Pineapple Solids (NFC)',id:'Beverage_juicePineappleSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Plum Solids',id:'Beverage_juicePlumSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Plum Solids (NFC)',id:'Beverage_juicePlumSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Pomegranate Solids',id:'Beverage_juicePomegranateSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Prune Solids',id:'Beverage_juicePruneSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Raspberry Red Solids',id:'Beverage_juiceRaspberryRedSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Raspberry Red Solids (NFC)',id:'Beverage_juiceRaspberryRedSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Red Beet Solids',id:'Beverage_juiceRedBeetSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Rhubarb Solids',id:'Beverage_juiceRhubarbSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Rhubarb Solids (NFC)',id:'Beverage_juiceRhubarbSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Spinach Solids',id:'Beverage_juiceSpinachSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Strawberry Solids',id:'Beverage_juiceStrawberrySolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Strawberry Solids (NFC)',id:'Beverage_juiceStrawberrySolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Sugar Cane Solids',id:'Beverage_juiceSugarCaneSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Tangerine Solids',id:'Beverage_juiceTangerineSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Tomato Solids',id:'Beverage_juiceTomatoSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Watermelon Solids',id:'Beverage_juiceWatermelonSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Watermelon Solids (NFC)',id:'Beverage_juiceWatermelonSolidsNfc',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - WESOS Solids',id:'Beverage_juiceWesosSolids',uom:'',category:'BeverageSpecs'},
    // {label:'Juice - Yuzu Solids',id:'Beverage_juiceYuzuSolids',uom:'',category:'BeverageSpecs'},
    {label:'Odor',id:'Beverage_odor',uom:'',category:'BeverageSpecs'},
    {label:'Packaging / Processing Notes',id:'Beverage_packagingProcessingNotes',uom:'',category:'BeverageSpecs'},
    {label:'Packaging Compatibility',id:'Beverage_packagingCompatibility',uom:'',category:'BeverageSpecs'},
    {label:'pH',id:'Beverage_ph',uom:'',category:'BeverageSpecs'},
    {label:'pH before Sterilization',id:'Beverage_phBeforeSterilization',uom:'',category:'BeverageSpecs'},
    {label:'PHOSPHORIC_ACID_MG_L',id:'Beverage_phosphoricAcidMgL',uom:'',category:'BeverageSpecs'},
    {label:'Physical Characteristics',id:'Beverage_physicalCharacteristics',uom:'',category:'BeverageSpecs'},
    {label:'Preferred Shelf life',id:'Beverage_preferredShelfLife',uom:'',category:'BeverageSpecs'},
    {label:'Product Flavor',id:'Beverage_productFlavor',uom:'',category:'BeverageSpecs'},
    {label:'Propylene glycol %w/w',id:'Beverage_propyleneGlycolPercentWW',uom:'%w/w',category:'BeverageSpecs'},
    {label:'REBAUDIOSIDE_A_MG_L',id:'Beverage_rebaudiosideAMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'SACCHARIN_IMIDE_MG_L',id:'Beverage_saccharinImideMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'Solids (SLDS)',id:'Beverage_solidsSlds',uom:'',category:'BeverageSpecs'},
    {label:'SORBIC_ACID_MG_KG',id:'Beverage_sorbicAcidMgKg',uom:'mg/kg',category:'BeverageSpecs'},
    {label:'SORBIC_ACID_MG_L',id:'Beverage_sorbicAcidMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'STEVIOL_GLYCOSIDES_MG_L',id:'Beverage_steviolGlycosidesMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'STEVIOLEQUIV_MAX_MG_L',id:'Beverage_steviolequivMaxMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'SUCRALOSE_MG_L',id:'Beverage_sucraloseMgL',uom:'mg/L',category:'BeverageSpecs'},
    {label:'SULFUR_DIOXIDE_MG_KG',id:'Beverage_sulfurDioxideMgKg',uom:'mg/kg',category:'BeverageSpecs'},
    {label:'Sust Combust Test Method',id:'Beverage_sustCombustTestMethod',uom:'',category:'BeverageSpecs'},
    {label:'Sust Combust Value',id:'Beverage_sustCombustValue',uom:'',category:'BeverageSpecs'},
    {label:'Syrup Water Weight (HFSS-42)',id:'Beverage_syrupWaterWeightHfss42',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Syrup Water Weight (HFSS-55)',id:'Beverage_syrupWaterWeightHfss55',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'Tariff Code – Harmonized',id:'Beverage_tariffCodeHarmonized',uom:'',category:'BeverageSpecs'},
    {label:'Tea Solids (%w/w)',id:'Beverage_teaSolidsPercentWW',uom:'%w/w',category:'BeverageSpecs'},
    {label:'Titr. Acidity (as Citric) %w/v',id:'Beverage_titrAcidityAsCitricPercentWV',uom:'%w/v',category:'BeverageSpecs'},
    {label:'Titr. Acidity (as Citric) %w/w',id:'Beverage_titrAcidityAsCitricPercentWW',uom:'%w/w',category:'BeverageSpecs'},
    {label:'Total Volume',id:'Beverage_totalVolume',uom:'$VOL$',category:'BeverageSpecs',type:"volume"},
    {label:'Total Weight',id:'Beverage_totalWeight',uom:'$WGT$',category:'BeverageSpecs',type:"weight"},
    {label:'TOTAL_DRY_MATTER_PCT_KO_CREATE_CALCULATED',id:'Beverage_totalDryMatterPctKoCreateCalculated',uom:'',category:'BeverageSpecs'},
    {label:'TOTAL_DRY_MATTER_PCT_MEASURED',id:'Beverage_totalDryMatterPctMeasured',uom:'',category:'BeverageSpecs'},
    {label:'Transportation Class - Flammable Flag',id:'Beverage_transportationClassFlammableFlag',uom:'',category:'BeverageSpecs'},
    {label:'Transportation Class - Non Hazard Flag',id:'Beverage_transportationClassNonHazardFlag',uom:'',category:'BeverageSpecs'},
    {label:'Transportation UN Class',id:'Beverage_transportationUnClass',uom:'',category:'BeverageSpecs'},
    {label:'Triacetin %w/w',id:'Beverage_triacetinPercentWW',uom:'%w/w',category:'BeverageSpecs'},
    {label:'Water / Syrup Ratio (/1.0) %v/v',id:'Beverage_waterSyrupRatio10PercentVV',uom:'To 1',category:'BeverageSpecs'},

]