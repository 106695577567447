export const booleanSerializer = {
    toString(b) {
        return b === undefined || b === null ? '' : '' + b
    },

    toObject(str) {
        str = ('' + str).toLowerCase().trim()
        return (str === 'undefined' || str === 'null' || str === '') ? undefined : !(str === 'false' || str === '0' || str === 'f' || str === 'n' || str === 'no' || str === 'off')
    }
}

export const numberSerializer = {
    toString(n) {
        return String(n ?? '')
    },

    toObject(str) {
        str = ('' + str).toLowerCase().trim()
        return (str === 'undefined' || str === 'null' || str === '') ? undefined : (new Number(str)).valueOf()
    }
}