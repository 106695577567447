import CommercializationMapper from '../mapper/CommercializationMapper'
import { CommercializationFormDialog } from './CommercializationFormDialog'
import ListView from '../layout/ListView.js'
import { ApiGateway } from '../config/config'
import axios, { isSuccess } from '../utils/AxiosClient.js'

export default function ListCommercializations(props = {}) {

    const columns = [
      { accessorFn: (row) =>  row.countriesOfProduction?.map(countryOfProduction => countryOfProduction.name).join('; '), header: 'Producers', size: 50 },
      { accessorKey: 'status', header: 'Status', filterVariant: 'multi-select', filterSelectOptions: ["", "Informational Only", "Alpha", "Draft", "Completed", "Final", "Issued"], size: 160 }
    ]

    return (
      <ListView 
        columns = { columns }
        isLoading = { props.isLoading }
        itemLabelFn = { commercialization => `Commercialization${commercialization.countriesOfProduction ? ` for ${commercialization.countriesOfProduction.map(copd => copd.name).join(', ')}` : ''}` }
        dataMapper = { CommercializationMapper }
        formulaId = { props.formulaId }
        closeOnSave = { false }
        scope = "CommercializationForm"
        getData = { async ({ requestConfig }) => {
          const response = await axios.get(`${ApiGateway.formula}/${props.formulaId}/commercializations`, requestConfig)
          return { isSuccess: isSuccess(response), data: response.data?.data }
        }}
        onSave = { async ({item, mode, requestConfig}) => {
          if(mode === 'add') {
            item.id = null
          }
          item.formula = { id: props.formulaId }
          const response = await axios.post(`${ApiGateway.formula}/${props.formulaId}/commercializations`, item, requestConfig) 
          return { isSuccess: isSuccess(response), data: response.data }
        }}
        onDelete= { async ({item, requestConfig}) => {
          const response = await axios.delete(`${ApiGateway.formula}/${props.formulaId}/commercializations/${item.id}`, requestConfig) 
          return { isSuccess: isSuccess(response) }
        }}
        dialog = { <CommercializationFormDialog /> }
      />
    )
}