import { useState } from 'react'
import CalculateIcon from '@mui/icons-material/Calculate';
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import CreateConversion from './CreateConversion'

export default function CreateConversionPage (props = {}) {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false) 

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  // setTimeout(() => {
  //   console.log("setting to false")
  //   setIsLoading(false)
  // }, 2000)
  const breadcrumb = <span><CalculateIcon/>Create Conversion Expression</span>

  const onSave = () => {
    console.log("CreateConversionPage onSave")
    setSaveClicked(true)
    setTimeout(() => {
      setSaveClicked(false)
    }, 1000)
  }

  const confirmCancel = () => {
    console.log("Cancel")
    if (isDirty) { showDialog() }
    else { navigate('/conversion/') }
  }

  const handleCancel = () => {
    navigate('/conversion/')
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={<FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty}/>}>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} onClose={hideDialog} onClickYes={handleCancel} yesLabel="Discard" noLabel="Stay on Page"/>
      <CreateConversion saveClicked={saveClicked} startLoading={startLoading} stopLoading={stopLoading} setDirty={setDirty} setClean={setClean}/> 
    </Page>
  )
}