import { numberSerializer } from '../utils/Serializer'

export default class CommercializationMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            formula: { id: numberSerializer.toString(item.formula?.id) },
            locale: item.locale ?? '',
            name: item.name ?? '',
            formulaStates: item.formulaStates ?? '',
            guidelines: item.guidelines ?? '',
            status: item.status ?? '',
            scaledVolume: numberSerializer.toString(item.scaledVolume),
            blendingInstructions: item.blendingInstructions ?? '',
            endUse: item.endUse ?? '',
            packageType: item.packageType ?? {},
            countriesOfProduction: item.countriesOfProduction ?? [],
            ingredients: item.ingredients ?? [],
            cosd: item.cosd ?? [],
            outputDocuments: item.outputDocuments ?? []
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            formula: formModel.formula,
            locale: formModel.locale,
            name: formModel.name,
            formulaStates: formModel.formulaStates,
            guidelines: formModel.guidelines,
            status: formModel.status,
            scaledVolume: numberSerializer.toObject(formModel.scaledVolume),
            blendingInstructions: formModel.blendingInstructions,
            endUse: formModel.endUse,
            packageType: formModel.packageType,
            countriesOfProduction: formModel.countriesOfProduction,
            ingredients: formModel.ingredients,
            cosd: formModel.cosd,
            outputDocuments: formModel.outputDocuments
        }
    }
}