import { booleanSerializer, numberSerializer } from '../utils/Serializer'

export default class ProductFormulaDetailMapper {
    toFormModel(item) {
        return {
            // id: numberSerializer.toString(item.id) ?? '',
            formulaName: item.formulaName ?? '',
            bbn: item.bbn ?? '',
            specNumber: item.specNumber ?? '',
            operatingUnit: item.operatingUnit ?? '',
            formulaStates: item.formulaStates ?? '',
            picassoStatus: item.picassoStatus ?? '',
            status: item.status ?? '',
            isCarbonated: booleanSerializer.toString(item.isCarbonated),
            volume: numberSerializer.toString(item.volume),
            countriesOfProduction: item.countriesOfProduction ?? [],
            countriesOfSale: item.countriesOfSale ?? []
        }
    }
    
    toObject (formModel) {
        return {
            // id: numberSerializer.toObject(formModel.id),
            formulaName: formModel.formulaName,
            bbn: formModel.bbn,
            specNumber: formModel.specNumber,
            operatingUnit: formModel.operatingUnit,
            formulaStates: formModel.formulaStates,
            picassoStatus: formModel.picassoStatus,
            status: formModel.status,
            isCarbonated: booleanSerializer.toObject(formModel.isCarbonated),
            volume: numberSerializer.toObject(formModel.volume),
            countriesOfProduction: formModel.countriesOfProduction,
            countriesOfSale: formModel.countriesOfSale
        }
    }
}