import { numberSerializer } from '../utils/Serializer'

export default class CharacteristicMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            formula: { id: numberSerializer.toString(item.formula?.id) ?? ''},
            packageType: item.packageType ?? '',
            nitrogenPsi: item.nitrogenPsi ?? '',
            carbonation: item.carbonation?.target ?? '',
            carbonationMax: item.carbonation?.max ?? '',
            carbonationMin: item.carbonation?.min ?? '',
            createdBy: item.createdBy ?? '',
            createdDate: item.createdDate ?? '',
            lastModifiedBy: item.lastModifiedBy ?? '',
            lastModifiedDate: item.lastModifiedDate ?? ''
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            formula: { id: numberSerializer.toObject(formModel.formula?.id) },
            packageType: formModel.packageType,
            nitrogenPsi: formModel.nitrogenPsi,
            carbonation: { 
                min: formModel.carbonationMin,
                target: formModel.carbonation,
                max: formModel.carbonationMax,
                uom: 'L'
            }
        }
    }
}