import { useContext, useState } from 'react'
import bus from '../utils/EventBus.js'
import Event from '../utils/EventNames.js'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { checkRequiredError } from '../form/Form.js'
import { MESSAGE_TYPE } from '../layout/AnimatedAlert.js'
import { ApiGateway } from '../config/config.js'
import { UserContext } from '../App.js'
import axios from '../utils/AxiosClient.js'
import LoadingButton from '@mui/lab/LoadingButton';
import IngredientForm from './IngredientForm'

export function IngredientFormDialog (props = {}) {  
    // console.log('IngredientFormDialog props: ', props)
    
    const [errors, setErrors] = useState({})
    const [isDirty, setIsDirty] = useState(false) 
    const [isSaving, setIsSaving] = useState(false)
    const user = useContext(UserContext)

    const isSuccess = (response) => response && response.data && response.status >= 200 && response.status < 300

    const setDirty = () => {
      setIsDirty(true)
    }

    const setClean = () => {
      setIsDirty(false)
    }
    
    const onSave = async () => {
      setIsSaving(true)
      console.log('IngredientFormDialog.onSave, current state: ', props.item)
      if(props.item) {
        let response = await axios.post(`${ApiGateway.ingredient}`, props.item, { headers: { Authorization: user.idToken } })
        console.log('IngredientFormDialog.onSave, response: ', response)
        if(isSuccess(response)) {
          props.onClose()
          if(props.mode === 'add') {
            bus.emit(Event.ALERT, { text: 'Added Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
          } else {
            bus.emit(Event.ALERT, { text: 'Saved Successfully!', style: MESSAGE_TYPE.SUCCESS, duration: 3000 })
          }
        } else {
          const text = 'There was an issue saving the ingredient.  Please try again later.'
          bus.emit(Event.ALERT, { text: text, style: 'error' })
        }
        setIsSaving(false)
        if(props.onSave) {
          props.onSave()
        }
      }
      else {
        const text = 'There was an issue saving the ingredient.  Please try again later.'
        bus.emit(Event.ALERT, { text: text, style: 'error' })
        setIsSaving(false)
      }
    }

    const onClose = () => {
      setErrors({})
      props.onClose()
    }

    const setError = (value, id, errorChecker=checkRequiredError, errorObject=errors, errorSetter=setErrors) => {
      let error = errorChecker(value)
      errorObject[id]=error
      errorSetter({...errorObject}, {id: error})
    }

    const title = props.item?.ingredient?.id ? 
      <DialogTitle textAlign="center">Edit Ingredient</DialogTitle> : 
      <DialogTitle textAlign="center">Add Ingredient</DialogTitle>

    return (
      <>
        {props.item &&
          <>
            <Dialog fullWidth maxWidth='lg' scroll='paper' open={props.open}>
            {title}
            <DialogContent>
              <IngredientForm context={props.context}/>
            </DialogContent>
              <DialogActions sx={{ p: '1.25rem' }}>
                <LoadingButton loading={isSaving} onClick={onClose}>Cancel</LoadingButton>
                <LoadingButton loading={isSaving} color="primary" disabled={!isDirty} onClick={onSave} variant="contained">Save</LoadingButton>
              </DialogActions>
            </Dialog>
          </>       
        } 
      </>
    )
  }
