import AbstractFormModel, { getValues } from '../AbstractFormModel'
export default class ProductCategoryInfoFormModel extends AbstractFormModel {
    id = ''
    appearance = ''
    odor = ''
    shelfLife = ''
    shelfLifeUom = ''
    isSensitiveWash = ''
    isMicrobiologicallySensitive = ''
    isPungent = ''
    containsAllergens = ''
    containsJuice = ''
    containsPulp = ''
    containsCaffeine = ''
    isKosher = ''
    isOrganic = ''
    containsGluten = ''
    containsPreservatives = ''
    storageTemperatureCelcius = ''

    constructor(formula) {
        super(formula)
        if(formula) {
            Object.assign(this, this.init(formula))
        }
    }

    toObject(refs) {
        const values = getValues(refs)
        return {
            id: this.numberSerializer.toObject(values.id),
            appearance: values.appearance,
            odor: values.odor,
            shelfLife: values.shelfLife,
            shelfLifeUom: values.shelfLifeUom,
            isSensitiveWash: this.booleanSerializer.toObject(values.isSensitiveWash),
            isMicrobiologicallySensitive: this.booleanSerializer.toObject(values.isMicrobiologicallySensitive),
            isPungent: this.booleanSerializer.toObject(values.isPungent),
            containsAllergens: this.booleanSerializer.toObject(values.containsAllergens),
            containsJuice: this.booleanSerializer.toObject(values.containsJuice),
            containsPulp: this.booleanSerializer.toObject(values.containsPulp),
            containsCaffeine: this.booleanSerializer.toObject(values.containsCaffeine),
            isKosher: this.booleanSerializer.toObject(values.isKosher),
            isOrganic: this.booleanSerializer.toObject(values.isOrganic),
            containsGluten: this.booleanSerializer.toObject(values.containsGluten),
            containsPreservatives: this.booleanSerializer.toObject(values.containsPreservatives),
            storageTemperatureCelcius: this.numberSerializer.toObject(values.storageTemperatureCelcius)
        }
    }
}