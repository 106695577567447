import { numberSerializer } from '../utils/Serializer'

export default class IngredientCheckMapper {
    toFormModel(item) {
        return {
            id: numberSerializer.toString(item.id) ?? '',
            formula: { id: numberSerializer.toString(item.formula?.id) ?? ''},
            ingredientCheckName: item.ingredientCheckName ?? '',
            oneUnitKgPlusAnyPart: item.oneUnitKgPlusAnyPart ?? '',
            stdConc: item.stdConc ?? '',
            factorOverride: item.factorOverride ?? '',
            weightOverride: item.weightOverride ?? '',
            ingredientIds: item.ingredientIds ?? [],
            createdBy: item.createdBy ?? '',
            createdDate: item.createdDate ?? '',
            lastModifiedBy: item.lastModifiedBy ?? '',
            lastModifiedDate: item.lastModifiedDate ?? ''
        }
    }
    
    toObject (formModel) {
        return {
            id: numberSerializer.toObject(formModel.id),
            formula: { id: numberSerializer.toObject(formModel.formula?.id) },
            ingredientCheckName: formModel.ingredientCheckName,
            oneUnitKgPlusAnyPart: formModel.oneUnitKgPlusAnyPart,
            stdConc: formModel.stdConc,
            factorOverride: formModel.factorOverride,
            weightOverride: formModel.weightOverride,
            ingredientIds: formModel.ingredientIds
        }
    }
}