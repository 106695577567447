//from https://www.coca-cola.com/country-selector
//TODO verify all operating units
export const OperatingUnits = [
    "Global OU",
    "Africa OU",
    "Latin America OU",
    "North America OU",
    "Japan & South Korea OU",
    "Asia Pacific OU",
    "Europe OU",
    "Eurasia & Middle East OU"
]

export default OperatingUnits