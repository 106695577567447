import { useContext } from 'react'
import { Button } from '@mui/material'
import moment from 'moment'
import { AppModeContext } from '../App'

export default function DebugButton(props = {}) {
    const mode = useContext(AppModeContext)
    return (
        <>
            {false && mode === 'debug' &&
                <Button variant="outlined" sx={{position: 'absolute', top: 0, right: 0, zIndex: 99999}} onClick={() => {
                    console.log(`[${moment().format('HH:mm:ss')}] ${props.name}:`, props.log())
                }}>Debug {props.name}</Button>
            }
        </>
    )
}