import { Route, Routes } from 'react-router-dom'
import ListFormulasPage from '../formula/ListFormulasPage'
import FormulaFormPage from '../formula/FormulaFormPage'
import SpecificationFormPage from '../specification/SpecificationFormPage'
import ListIngredientsPage from '../ingredient/ListIngredientsPage'
import IngredientFormPage from '../ingredient/IngredientFormPage'
import CreateConversionPage from '../conversion/CreateConversionPage'
import ListConversionsPage from '../conversion/ListConversionsPage'

export default function Body (props = {}) {
  

  return (
    <div className="content-wrapper">
      <section className="content body">
         <Routes>
          <Route exact={true} path="/formulas" element ={ <ListFormulasPage /> } />          
          <Route exact={true} path="/specifications/edit/:specificationId" element ={ <SpecificationFormPage /> } />
          <Route exact={true} path="/conversions/" element ={ <ListConversionsPage /> } />
          <Route exact={true} path="/conversions/create" element ={ <CreateConversionPage /> } />
          <Route exact={true} path="/formulas/edit/:formulaId" element ={ <FormulaFormPage /> } />
          <Route exact={true} path="/ingredients/edit/:ingredientId" element ={ <IngredientFormPage /> } />
          <Route exact={true} path="/ingredients" element ={ <ListIngredientsPage /> } />
        </Routes>
      </section>
    </div>
  )
}
