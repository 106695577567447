export default {
    metric: {
        volume: { uom: 'l', label: 'L', title: 'Liters'},
        weight: { uom: 'kg', label: 'kg', title: 'Kilograms' },
        density: { uom: 'kg/l', label: 'kg/L', title: 'Kilograms per Liter' },
        temperature: { uom: 'C', label: '°C', title: 'Degrees Celcius' }
    },
    imperial: {
        volume: { uom: 'gal', label: 'gal', title: 'Gallons' },
        weight: { uom: 'lb', label: 'lb', title: 'Pounds' },
        density: { uom: 'lb/gal', label: 'lb/gal', title: 'Pounds per Gallon' },
        temperature: { uom: 'F', label: '°F', title: 'Degrees Fahrenheit' }
    }
}